import {useCallback, useEffect, useState} from "react";
import ResponsiveHelper from "../lib/ResponsiveHelper";


export const useDraggable = (draggableRef, options = {}) => {

  const {
    onDrag = () => {},
    constrain = (dx, dy) => ({dx, dy}),
  } = options;

  const [type, setType] = useState(null);
  const [start, setStart] = useState(null);
  const [current, setCurrent] = useState(null);
  const [bounds, setBounds] = useState(null);

  const handleDragStart = useCallback((e) => {
    if (type == null
      && e.buttons === 1
      && !(e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)) {
      setType("mousedown");
      setStart({x: e.x, y: e.y});
      setCurrent({x: e.x, y: e.y});
      e.stopPropagation();
      e.preventDefault();
    }
  }, [type]);

  const handleDrag = useCallback((e) => {
    if (type === "mousedown") {
      if (e.x !== start.x || e.y !== start.y) {
        setType("start");
        setBounds(draggableRef.current.getBoundingClientRect());
      }
      e.stopPropagation();
      e.preventDefault();
    }
    else if (type === "start" || type === "drag") {
      if (e.x !== start.x || e.y !== start.y) {
        setType("drag");
        setCurrent({x: e.x, y: e.y});
      }
      e.stopPropagation();
      e.preventDefault();
    }
  }, [draggableRef.current, type, start, bounds]);

  const handleDragEnd = useCallback((e) => {
    if (type === "mousedown") {
      setType(null);
      setStart(null);
      setCurrent(null);
    }
    else if (type === "drag") {
      setType("end");
      setCurrent({x: e.x, y: e.y});
      e.stopPropagation();
      e.preventDefault();
    }
  }, [type]);

  useEffect(() => {
    if (draggableRef.current) {
      const draggable = draggableRef.current;
      draggable.addEventListener("mousedown", handleDragStart);
      return () => {
        draggable.removeEventListener("mousedown", handleDragStart);
      };
    }
  }, [draggableRef.current, handleDragStart]);

  useEffect(() => {
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mouseup", handleDragEnd);
    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [handleDrag, handleDragEnd]);

  useEffect(() => {
    if (type != null && type !== "mousedown" && start != null) {
      const {dx, dy} = constrain(current.x - start.x, current.y - start.y);
      onDrag({type, dx, dy});
      if (type === "end") {
        setType(null);
        setStart(null);
        setCurrent(null);
        setBounds(null);
      }
    }
  }, [type, start, current, onDrag]);
};

export default useDraggable;
