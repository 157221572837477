import { MDExternalLinks } from "../../components/results-grid/grid-row/ExternalLinks";

export const getOverrides = () => {
  return {
    Links: {
      component: MDExternalLinks
    },
  };
};

export const overrideMapSample = getOverrides();
