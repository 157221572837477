import React, {useState} from 'react'

import {fetchWikiImage, NOT_FOUND, PENDING} from "../lib/Wikipedia";

export const USE_LOGOS = false;


const Logo = ({title}) => {
  const [url, setURL] = useState(null);
  if (url == null && title && USE_LOGOS) {
    fetchWikiImage(title).then((url) => {
      // TODO: use useEffect to cancel request
      setURL(url);
    });
  }
  return !url || url === NOT_FOUND || url === PENDING
    ? (<div className={`org-logo`} />)
    : (<img src={url} alt={title}
            className={`org-logo`}
            onError={e => setURL(NOT_FOUND)}
       />)
};

export default Logo;
