import {useEffect, useMemo, useState} from "react";

export const useModifierKeys = (onModifierChange) => {
  const [shift, setShift] = useState(false);
  const [alt, setAlt] = useState(false);
  const modifierState = useMemo(() => ({shift, alt}), [shift, alt]);
  useEffect(() => {
    onModifierChange && onModifierChange(modifierState);
  }, [modifierState]);
  return [
    modifierState, useMemo(() => ({
      onKeyDown: (e) => {
        if (e.key === 'Shift') setShift(true);
        else if (e.key === 'Alt') setAlt(true);
      },
      onKeyUp: (e) => {
        if (e.key === 'Shift') setShift(false);
        else if (e.key === 'Alt') setAlt(false);
      },
    }), [setShift, setAlt])
  ];
};
