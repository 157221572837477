import {useNavigate} from "react-router";
import {inject, observer} from "mobx-react";

import {withAuthentication} from "../components/AuthenticateUser";

const Admin = ({userStore}) => {
  const {isAdmin} = userStore;
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/", {replace:true});
  }
  document.title = "Plex User Management";
  return (
    <div className="admin-app">
      <h1>Plex User Management</h1>
    </div>
  )
};

export default withAuthentication(inject("userStore")(observer(Admin)));
