import React from "react";
import {compiler} from "markdown-to-jsx";
import {escapeMD, interpolate, interpolateProps, IPROP_PREFIX} from "./interpolation";
import {deriveTemplate} from "./index";
import {SafeRenderer} from "../components/SafeRenderer";
import OutboundLink from "../components/OutboundLink";

const isReactComponent = (componentType, templateOverrides = {}) => {
  if (componentType == null) {
    return false;
  }
  if (templateOverrides[componentType]) {
    componentType = templateOverrides[componentType];
  }
  return typeof(componentType) !== 'string' && typeof(componentType.type) !== 'string';
}

export const createMarkdownElement = (
  template,
  templateType: "no-template-type",
  overrides = {},
  entity: {} = {},
  errorMessage = null) => {

  const componentProps = {
    ...(overrides.props || {}),
    entity
  };
  const {markText = null, forceBlock = true} = componentProps;

  // Maintain a list of all interpolation values to enable uniqueness checks
  const uniqueValues = new Set();
  // Use a custom createElement when compiling markdown so that we can inject
  // additional properties (entity and text marking) into React components generated from the markdown
  // See also https://github.com/quantizor/markdown-to-jsx/issues/479 w/r/t table => thead/tbody handling
  const createElement = (type, props, ...children) => {
    // Force blocks on all nested markdown
    if (type === "p" && forceBlock) {
      type = "div";
    }
    children.forEach((child, index, array) => {
      if (child && typeof(child) !== 'string') {
        if (child.forEach) {
          child.forEach((subChild, subIndex, subArray) => {
            if (subChild && typeof(subChild) !== 'string') {
              const resolvedProps = interpolateChildProps(subChild.type, subChild.props, entity,
                                                          uniqueValues, componentProps, markText, overrides);
              subArray[subIndex] = React.cloneElement(subChild, resolvedProps, subChild.props?.children);
            }
          });
        }
        else {
          const resolvedProps = interpolateChildProps(child.type, child.props, entity,
                                                      uniqueValues, componentProps, markText, overrides);
          array[index] = React.cloneElement(child, resolvedProps, child.props?.children);
        }
      }
    });
    const resolvedProps = interpolateChildProps(type, props, entity,
                                                         uniqueValues, componentProps, markText, overrides);
    return React.createElement(type, resolvedProps, ...children);
  };
  const interpolated = interpolate(template,
                                   entity,
                                   componentProps,
                                   markText,
                                   uniqueValues,
                                   overrides,
                                   escapeMD);

  // The following markdown compiler options seem to have no effect
  //forceBlock: true, forceWrapper: true, wrapper: 'markdown-wrapper',

  // Any markdown compiler override properties supersede anything in the actual markup,
  // so remove them, since interpolation has already set up placeholders and backing properties
  delete overrides.props;
  Object.entries(overrides).forEach(([key, value]) => {
    delete value.props;
  });
  const linkOverride = {
    a: ({href, ...props}) =>
      OutboundLink({href: href, ...props})
  };
  const compiled = compiler(interpolated, {overrides: {...linkOverride, ...overrides}, createElement});
  if (compiled == null) {
    // Nothing to render
    return null;
  }
  // Make all links open in a new tab
  const classNames = ["markdown", templateType];
  if (compiled.props.className) {
    classNames.push(compiled.props.className);
  }
  const className = classNames.join(" ");
  const element = React.cloneElement(compiled, {...compiled.props, ...(isReactComponent(compiled.type) ? componentProps : {}), className});
  return (
    <SafeRenderer
      className="markdown"
      errorMessage={errorMessage || "Markdown render error"}
      onError={(error, info) => console.error(`Markdown render error "${error}"`, {...entity, ...componentProps}, template)}
    >
      {element}
    </SafeRenderer>
  );
};

const interpolateChildProps = (type, props, entity, uniqueValues, componentProps, markText = null, templateOverrides = {}) => {
  if (!props) {
    props = {};
  }
  Object.entries(props).forEach(([key, value]) => {
    if (typeof(value) === "string" && value.startsWith(IPROP_PREFIX)) {
      props[key] = componentProps[value];
    }
  });
  const isDOMElement = !isReactComponent(type);
  const interpolated = interpolateProps(props, entity, componentProps, uniqueValues, markText, templateOverrides);
  return Object.entries({...props, ...interpolated}).reduce((result, [key, value]) => {
    if (!key.startsWith(IPROP_PREFIX)) {
      result[key] = value;
    }
    return result;
  }, isDOMElement ? {} : {entity, markText});
};

export const renderMarkdownWithInterpolation = (
  templateType,
  templates,
  templateOverrides = {},
  entity,
  fallback = "") => {
  const template = deriveTemplate(templates, templateType, entity.category, entity.baseCategory, null, fallback);
  return createMarkdownElement(template, templateType, templateOverrides, entity);
};

export const renderMarkdown = ({markdown = "", entity = {}, markText = null, overrides = {}, errorMessage = null}) => {
  try {
    return createMarkdownElement(markdown, "explicit-template",
                                 {...overrides, props: {...(overrides.props || {}), markText}},
                                 entity, errorMessage);
  } catch (error) {
    console.error(error);
    return <span className="error-message error">{error.message}</span>;
  }
};
