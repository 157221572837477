import {useEffect} from "react";

export const useSSEListener = ({url, onData, eventType = null, onError = null}) => {
  useEffect(() => {
    if (url) {
      const eventSource = new EventSource(url);
      if (eventType) {
        eventSource.addEventListener(eventType, onData);
      } else {
        eventSource.onmessage = onData;
      }
      eventSource.onerror = e => {
        eventSource.close();
        onError && onError(e);
      }
      return () => {
        eventSource.close();
      };
    }
  }, [url, eventType, onData, onError]);
}
