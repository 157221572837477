import React, {useEffect, useState} from "react";
import Tab from "./Tab";
import { inject, observer } from "mobx-react";

const generateProgressStyle = (currentCount = 0, totalCount = 0) => {
  if (currentCount === totalCount) {
    return null;
  } else {
    const ratio = totalCount > 0 ? currentCount / totalCount : 1;
    return {
      background: `linear-gradient(270deg, #BCBABA ${Math.floor(
        100 - ratio * 100
      )}%, #676161 ${Math.floor(ratio)}%)`
    };
  }
};

const TabBar = ({searchSessionStore}) => {
  const {
    tabs,
    awaitingInitialResults,
    downloadedResults,
    categoryTotals,
    currentCategory,
    firstNonemptyCategory,
  } = searchSessionStore;

  const onTabSelect = (key) => {
    if (key !== "graph" && !categoryTotals.get(key)) {
      key = firstNonemptyCategory;
    }
    if (key) {
      searchSessionStore.currentCategory = key;
    }
  }

  return (
      <ul className={`custom-nav-bar tab-bar${awaitingInitialResults ? ' loading' : ''}`}>
        {!awaitingInitialResults && tabs.map(tab => {
          const downloadedCount = downloadedResults.get(tab.key)?.length;
          const totalCount = categoryTotals.get(tab.key);
          return (
            <li key={tab.key}>
              <div className="tab-progress" style={generateProgressStyle(downloadedCount, totalCount)} />
              <Tab
                {...tab}
                downloadCount={downloadedCount}
                totalCount={totalCount}
                onClick={() => onTabSelect(tab.key)}
                isActive={tab.key === currentCategory}
                className={tab.className}
              />
            </li>
          )
        })}
      </ul>
  );
};

export default inject("searchSessionStore")(observer(TabBar));
