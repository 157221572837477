export const categories_yml_sample = `
#### Templates priority hierarchy

\`\`\`
datasets.yml
  category 'templates' subsection
categories.yml
  '_defaults' section
  category (target, compound etc.) 'templates' subsection
frontend predefined defaults
\`\`\`
<br/>
#### categories.yml file example
\`\`\`
_templates:                         # defaults section working as starting point for all categories
  as-top: "**{title}**"             # template for autosuggest top element
  as-list: "**{title}**"            # template for autosuggest regular element
  results-list: "**{title}**"       # template for search result item element
  query-details: "**{title}**"      # template for query details
  properties:                       # default property definitions
    label: "{title} => {description}"
target:
  name: 'Target'
  tab_name: 'Targets'
  description: 'Biological Macromolecules'
  templates:                        # template overrides for target category 
    properties:                     # category-specific property overrides
      label: "{title} {gene_symbol}"
    query-details: "__{title}__"    # query details target specific UI
compound:
  name: 'Compound'
  templates:                        # template overrides for compound category 
    as-top: "__{title}__"           # autosuggest top element compound specific UI
    as-list: "__{title}__"          # autosuggest regular element compound specific UI
    query-details: "__{title}__"    # query details compound specific UI
  tab_name: 'Compounds'
pathway:                            # Default templates will be used as long as 'templates' field is not presented 
  name: 'Pathway'
  tab_name: 'Pathways'
biomarker:                          # Default templates will be used as long as 'templates' field is not presented
  name: 'Biomarker'
  tab_name: 'Biomarkers'
  description: 'Systems biology collections ...'
\`\`\`
`;
