import {Dropdown} from "react-bootstrap";
import NumericInput from "../controls/NumericInput";
import Checkbox from "../controls/Checkbox";

export const Threshold = (props) => {
  const {
    className = "",
    onValueChange = () => {},
    onEnter = () => {},
    label = "Threshold",
    title = "",
    disabled = false,
    thresholdDisabled = false,
    children = [],
    // one of "checkbox", "radio", or null
    checkType = null,
    isSelected = false,
    onToggle = null,
    value = null,
    // Other threshold props
    ...childProps
  } = props;
  const handleClick = () => {
    onToggle && onToggle(!isSelected);
    if (checkType === "radio") {
      if (!isSelected) {
        onValueChange && onValueChange(value);
      }
    }
  }

  return (
    <Dropdown.Item
      className={`numeric${checkType ? " toggle-threshold" : ""}${checkType && isSelected ? " menu-item-checked" : ""}${className !== "" ? ' ' + className : ''}`}
      onClick={() => handleClick()}
      title={title}
      disabled={disabled}
    >
      {checkType && (
        <Checkbox
          checked={isSelected}
          key={`${label}-${checkType}`}
          checkType={checkType}
          title={title}
          disabled={disabled}
          label={label}
          onChange={() => handleClick()} />
      )}
      {!checkType && (
        <div key={`${label}`} title={title} >{label}</div>
      )}
      <NumericInput
        {...childProps}
        value={value}
        title={title}
        key={`${label}-value`}
        onClick={(e) => e.stopPropagation()}
        onChange={onValueChange}
        onKeyDown={e => e.key === 'Enter' && onEnter()}
        disabled={!disabled && thresholdDisabled}
      />
      {children}
    </Dropdown.Item>
  )
}
