export const TEMPLATE_CONSTANTS = {
  PROPERTIES: "properties",
  QUERY_DETAILS: "query-details",
  TOKEN_DETAILS: "token-details",
  SEARCH_RESULT: "search-result",
  AUTO_SUGGEST: {
    TOP: "as-top",
    ITEM: "as-list",
    GROUP_HEADER: "as-header",
    GROUP_PAGER: "as-group-pager",
    SELECT_GROUP: "as-select-group",
    EMPTY: "empty",
    ERROR: "error",
    LOADING: "loading",
  },
  EVIDENCE_CHAIN: {
    NODE: "evc-node",
    EDGE: "evc-edge"
  }
};

// Template priority:
// - explicit overrides
// - category entry
// - template default entry
export const deriveTemplate = (templates, type, category, baseCategory, overrideTemplate: * = null, fallbackTemplate: * = null) => {
  const template = (templates && templates[type]) || {};
  if (type === TEMPLATE_CONSTANTS.PROPERTIES) {
    return {
      ...fallbackTemplate,
      ...template['default'],
      ...(template[baseCategory] || {}),
      ...(template[category] || {}),
      ...(overrideTemplate || {})
    };
  }
  return overrideTemplate || template[category] || template[baseCategory] || template['default'] || fallbackTemplate;
};
