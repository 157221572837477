export const datasets_yml_sample = `
#### datasets.yml file example
\`\`\`
_default:                           # defaults section working as statring point for all categories
  name: meta
  version: 3.0
  categories:
    publication:
      title: Publication curation
      url: https://www.ncbi.nlm.nih.gov/pubmed/{id}
    patent:
      title: Patent curation
      url: https://patents.google.com/patent/{id}/en
chembl:
  name: ChEMBL
  description: >-
    ChEMBL is a manually curated chemical database of bioactive molecules with drug-like properties maintained by the 
    European Bioinformatics Institute (EBI).
  url: https://www.ebi.ac.uk/chembl/
  id_format: (CHEMBL\\d+)           # regex to extract the dataset-specific ID for URLs and green links
  edges: true                       
  # cpd<->tgt links
  edge_url: /assay_report_card/{id} # default edge URL; "id" is extracted from the edge source_id
  edge_properties:                  # define properties for specific edge endpoint category pairs
    compound-target:
      title: "{activity}"
  categories:                       # provide source URLs for categories referenced by this dataset
    target:
      url: /target_report_card/{id}
    compound:
      url: /compound_report_card/{id}
    publication:
      url: /document_report_card/{id}
\`\`\`
`;
