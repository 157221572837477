export const getObjectProjection = (obj = {}, fields = []) =>
  fields.length === 0
    ? obj
    : fields.reduce(
        (output, fieldName) =>
          Object.assign(output, { [fieldName]: obj[fieldName] }),
        {}
      );

export const createSample = (name, template, data) => ({
  name: name,
  template: template,
  dataObject: data
});

export const base64Helper = () => ({
  encode: array => btoa(array.map(x => x.replace(/,/g, "&comma;"))),
  decode: str =>
    atob(str)
      .split(",")
      .map(x => x.replace(/&comma;/g, ","))
      .filter(x => !!x)
});

export const flattenEntity = (entity) => {
  const flattened = {
    ...(entity.entity || {}),
    // Result metadata takes priority over original entity properties (e.g. "source")
    ...entity,
  };
  if (flattened.entity) {
    delete flattened.entity;
  }
  return flattened;
};

