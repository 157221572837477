import React, {useCallback, useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import GridViewport from "./GridViewport.js";
import EmptyResult from "./EmptyResult";
import PropTypes from "prop-types";
import {isEqual} from "lodash";
//import {withPropsChecker} from "../../lib/utils";


const SearchResultsGrid = ({
    className,
    includeQueryTerms,
    isFiltering,
    items,
    markText,
    onScroll,
    onShowEvidence,
    paddingTop,
    searchSessionStore,
    showLoading, /* results partially loaded */
  }) => {

  const {
    categoryResultsLength,
    categoryResultsTotal,
    currentCategory,
    filterByDirectConnection,
    filterByNeighborhood,
    queryEntities,
    searchIDs,
    searchResultAnnotation,
    templates,
    fetchGraphData,
  } = searchSessionStore;

  const viewportHeight = 250;
  const [savedQueryIDs, setSavedQueryIDs] = useState([]);
  const [savedCategory, setSavedCategory] = useState(null);
  const [itemsWithoutQueryTerms, setItemsWithoutQueryTerms] = useState([]);
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    if (currentCategory === "graph") {
      fetchGraphData().then(data => setGraphData(data));
    }
  }, [currentCategory]);

  const handleFilterSelection = useCallback((type, item) => {
    if (type === "dc") {
      filterByDirectConnection(item.id);
    }
    if (type === "nbh") {
      filterByNeighborhood(item.neighborhood);
    }
  }, [filterByDirectConnection, filterByNeighborhood]);

  useEffect(() => {
    const queryIDs = (queryEntities || searchIDs || []).map(x => x.id);
    if (!isEqual(savedQueryIDs, queryIDs)) {
      setSavedQueryIDs(queryIDs);
    }
    const qids = new Set(queryIDs);
    const withoutQueryTerms = items.filter(item => !qids.has(item.id));
    if (savedCategory !== currentCategory
        || withoutQueryTerms.length !== itemsWithoutQueryTerms.length) {
      setItemsWithoutQueryTerms(withoutQueryTerms);
      setSavedCategory(currentCategory);
    }
  }, [
    queryEntities,
    searchIDs,
    savedQueryIDs,
    items,
    itemsWithoutQueryTerms,
    savedCategory,
    currentCategory,
  ]);

  const noResultsText = searchResultAnnotation
    || (categoryResultsTotal === 0 ? "No relevant results" : null)
    || (categoryResultsLength === 0 && isFiltering ? "No matching results" : null);
  // Make sure there are actually term matches currently displayed to be hidden (sets may span multiple tabs)
  const queryIDs = new Set(searchIDs);
  const termMatchCount = items.reduce((acc, x) => acc + (queryIDs.has(x.id) ? 1 : 0), 0);
  const displayedItems = includeQueryTerms || termMatchCount === 0 ? items : itemsWithoutQueryTerms;

  return (
      <div className={`grid-view${className ? ' ' + className : ""}`}>
        {noResultsText
         ? (
           <EmptyResult content={noResultsText} />
         ) : (
          <GridViewport
            key={`${savedQueryIDs.join("|")}:${currentCategory}`}
            height={viewportHeight}
            queryIDs={savedQueryIDs}
            currentCategory={currentCategory}
            templates={templates}
            onScroll={onScroll}
            onShowEvidence={onShowEvidence}
            onFilterSelection={handleFilterSelection}
            items={displayedItems}
            itemsTotal={categoryResultsTotal}
            isLoading={showLoading}
            markText={markText}
            paddingTop={paddingTop}
          />
        )}
      </div>
  );
};

SearchResultsGrid.propTypes = {
  className: PropTypes.string,
  includeQueryTerms: PropTypes.bool,
  isFiltering: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  markText: PropTypes.func,
  onScroll: PropTypes.func,
  onShowEvidence: PropTypes.func,
  paddingTop: PropTypes.number,
  showLoading: PropTypes.bool,
};

//export default withPropsChecker(GridView);
export default inject("searchSessionStore")(observer(SearchResultsGrid));
