// Use animation frames to regularly execute the onTick function, until that function returns false
import {useCallback, useEffect, useRef} from "react";

export const useAnimation = (onTick) => {
  const onTickRef = useRef();
  useEffect(() => {
    onTickRef.current = onTick;
  }, [onTick]);
  return useCallback(singleFrame => {
    const tick = () => {
      if (onTickRef.current(singleFrame)) {
        window.requestAnimationFrame(tick);
      }
    }
    window.requestAnimationFrame(tick);
  }, [onTickRef]);
};
