import {useCallback, useEffect, useMemo, useState} from "react";
import {ControlsContainer, useLoadGraph, useSigma, useSigmaContext, useCamera} from "@react-sigma/core";
import {UndirectedGraph} from "graphology";
import {forceManyBody, forceCollide, forceSimulation, forceX, forceY} from "d3-force";
import {NODE_SIZE_DEFAULT} from "./EdgeGraph";
import {useAnimation} from "../hooks/useAnimation";

const MAX_TICKS = 150;

const MOUSE_EVENTS = new Set(['click', 'rightClick', 'doubleClick', 'wheel']);

export const useEventHandlers = ((handlers) => {
  const sigma = useSigma();
  useEffect(() => {
    Object.entries(handlers).forEach(([event, handler]) => {
      if (event.startsWith("touch")) {
        sigma.getTouchCaptor().on(event, handler);
      } else if (event.startsWith('mouse') || MOUSE_EVENTS.has(event)) {
        sigma.getMouseCaptor().on(event, handler);
      } else if (event === "updated") {
        sigma.camera.on(event, handler);
      } else {
        sigma.on(event, handler);
      }
    });
    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        if (event.startsWith("touch")) {
          sigma.getTouchCaptor().off(event, handler);
        } else if (event.startsWith('mouse') || MOUSE_EVENTS.has(event)) {
          sigma.getMouseCaptor().off(event, handler);
        } else if (event === "updated") {
          sigma.camera.off(event, handler);
        } else {
          sigma.off(event, handler);
        }
      });
    };
  }, [sigma, ...Object.keys(handlers).sort().map(k => handlers[k])]);
});

export const SigmaEdgeGraphLoader = ({
                                       nodes = [],
                                       adjacency = [],
                                       controlsPosition = null,
                                       onReset = null,
                                       autoRun = true,
                                       width = 10,
                                       height = 10,
                                       deriveX = _ => 0,
                                       deriveY = _ => 0,
                                       setMinSimilarity = _ => {},
                                       setMaxSimilarity = _ => {},
                                     }) => {

  const loadGraph = useLoadGraph();
  const sigma = useSigma();

  const [running, setRunning] = useState(autoRun);
  const [paused, setPaused] = useState(false);
  const [ticks, setTicks] = useState(0);
  const camera = useCamera();

  const columnCount = nodes.reduce((max, n) => Math.max(max, (n.column || 0) + 1), 0);
  const maxRadius = nodes.reduce((max, n) => Math.max(max, (n.size || 0) / 2), 0);
  if (!width) {
    width = columnCount * 10;
  }
  if (!height) {
    height = 10;
  }
  useEffect(() => {
    const graph = new UndirectedGraph();
    nodes.forEach(node => graph.addNode(node.id, node));
    adjacency.forEach((neighbors, idx) => neighbors
      .forEach(([nbr, edgeAttributes]) => {
        if (nodes[idx] && nodes[nbr]) {
          graph.addEdge(nodes[idx].id, nodes[nbr].id, edgeAttributes);
          if (nodes[idx].category === nodes[nbr].category) {
            nodes[nbr].score = Math.max(nodes[nbr].score || 0,
                                        edgeAttributes.details.reduce((max, e) => Math.max(max, e.score), 0));
          }
        }
      }));
    const [min, max] = nodes
      .filter(n => n.score)
      .reduce(([min, max], node) => [Math.min(min, node.score), Math.max(max, node.score)],
              [1, 0]);
    setMinSimilarity(min);
    setMaxSimilarity(max);

    // Sort query nodes by degree
    const qnodes = nodes.filter(n => n.column === 0);

    const maxQueryNodeDegree = qnodes.reduce((max, n) => Math.max(max, graph.degree(n.id)), 0);
    qnodes.forEach(n => {
      n.rank = graph.degree(n.id) / maxQueryNodeDegree;
      graph.setNodeAttribute(n.id, "rank", n.rank || 0)
    });
    nodes.forEach(n => {
      n.size = NODE_SIZE_DEFAULT + Math.log2(graph.degree(n.id) || 1);
      // Layout doesn't work unless position and velocity are initialized
      n.x = (width || 100) / 2 * n.column / columnCount;
      n.y = (height || 100) / 2 * n.rank;
      n.vx = n.vy = 0;
    });
    loadGraph(graph);
  }, [loadGraph, nodes, adjacency, setMinSimilarity, setMaxSimilarity]);

  const simulation = useMemo(() => {
    return forceSimulation(nodes)
        .force('x', forceX(deriveX).strength(d => 0.5 + d.size / (maxRadius * 2) / 2))
        .force('y', forceY(deriveY).strength(d => 0.5 + d.size / (maxRadius * 2) / 2))
      .velocityDecay(0.35)
      .force('collide', forceCollide(d => d.size))
      .force('many-body', forceManyBody().strength(d => -100))
      .stop();
  }, [nodes, maxRadius, width, height, deriveX, deriveY]);

  useEffect(() => {
    autoRun && setRunning(true);
  }, [simulation, autoRun]);

  const propagatePositions = (why = "") => {
    const graph = sigma.getGraph();
    let xmin = 1;
    let xmax = 0;
    nodes.forEach(node => {
      graph.setNodeAttribute(node.id, "x", node.x || 0);
      graph.setNodeAttribute(node.id, "y", node.y || 0);
      xmin = Math.min(node.x || 0, xmin);
      xmax = Math.max(node.x || 0, xmax);
    });
    if (xmin === xmax) {
      reset();
    }
    sigma.refresh();
  };

  const onTick = useCallback((singleFrame = false) => {
    setTicks(ticks + 1);
    simulation.tick();
    propagatePositions("tick");
    return !singleFrame && running && !paused;
  }, [simulation, running, paused, ticks, propagatePositions]);

  const animate = useAnimation(onTick);

  useEffect(() => {
    if (ticks > MAX_TICKS) {
      //console.log(`Animation stopped after ${ticks} ticks`);
      setRunning(false);
      setPaused(false);
      setTicks(0);
      camera.reset();
    }
  }, [ticks]);

  const step = () => {
    animate(true);
  };

  const run = () => {
    setPaused(running);
    setRunning(!running);
  };

  const reset = () => {
    onReset && onReset();
    setRunning(false);
    setPaused(false);
    setTicks(0);
    simulation.alpha(1).restart().stop();
    nodes.forEach(n => {
      n.x = Math.random() * width;
      n.y = Math.random() * height;
      n.vx = n.vy = 0;
    })
    propagatePositions("reset");
  }

  useEffect(() => {
    if (running && !paused) {
      animate();
    }
  }, [running, paused, animate]);

  return controlsPosition && (
    <ControlsContainer position={controlsPosition}>
      <button className={`glyphicon glyphicon-${running ? "pause" : "play"}`} onClick={run}/>
      <button className="glyphicon glyphicon-step-forward" onClick={step}/>
      <button className="glyphicon glyphicon-refresh" onClick={reset}/>
      <div className="ticks admin">{ticks}</div>
    </ControlsContainer>
  );
};

export default SigmaEdgeGraphLoader;
