import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Dropdown, DropdownButton} from "react-bootstrap";
import OutboundLink from "../../OutboundLink";
import _ from "lodash";
import {naturalSort} from "../../../lib/utils";


// "Green" links
const ExternalLinks = ({links = []}) => {

  if (links.length === 0) {
    return null;
  }
  const [groupedLinks, setGroupedLinks] = useState({});
  useEffect(() => {
    setGroupedLinks(_.groupBy(links.filter(x => x.title && x.url), "title"));
  }, [links]);

  const linksList = Object.keys(groupedLinks).sort().map(groupName => {
    const linkGroup = groupedLinks[groupName];
    if (linkGroup.length === 1) {
      return linkGroup.map((lnk) => {
        const url = lnk.url;
        const handleClick = e => e;
        return (
          <OutboundLink
            key={groupName}
            className="srch-rst-source"
            title={lnk.description}
            href={url}
            onClick={handleClick}
          >
            {lnk.title}
          </OutboundLink>
        );
      });
    } else {
      // dropdown link set
      linkGroup.sort((a, b) => {
        return naturalSort(a.external_id, b.external_id);
      });
      return (
        <DropdownButton
          key={groupName}
          title={groupName}
          className="srch-rst-link-group srch-rst-source"
        >
          {linkGroup.map(({ url, title, external_id }) => (
            <Dropdown.Item key={external_id} href={url} target="_blank" title={title}>
              {external_id}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      );
    }
  });
  return (<div className={"source-links"}>{linksList}</div>);
};

ExternalLinks.propTypes = {
  links: PropTypes.array.isRequired,
};

const _MDExternalLinks = (props) => {
  const {entity = {}, links = []} = props;
  return (<ExternalLinks links={!links ? [] : links} entity={entity} />);
}

export const MDExternalLinks = _MDExternalLinks;
