import React from "react";
import {SubmitSearchButton} from "./SubmitSearchButton";
import CopyToClipboardButton from "./CopyToClipboardButton";
import {Dropdown} from "react-bootstrap";
import {copyToClipboard} from "../lib/utils";


const EntityPopover = ({ entity }) => {
  return (
    <React.Fragment>
      <Dropdown.Item onClick={()=> copyToClipboard(entity.id)}>
        <CopyToClipboardButton className="flat-button" getText={()=> entity.id} />
        <span>Copy ID</span>
      </Dropdown.Item>
      <Dropdown.Item
        onClick={()=> copyToClipboard(entity['label'])}>
        <CopyToClipboardButton className="flat-button" getText={()=> entity['label']} />
        <span>Copy Name</span>
      </Dropdown.Item>
      <Dropdown.Item
        as={SubmitSearchButton}
        className="data-rr-ui-dropdown-item dropdown-item"
        ids={[entity.id]}
        dsConfig={entity.dsConfig}
        category={entity.category}
      />
    </React.Fragment>
  );
}

export default EntityPopover;
