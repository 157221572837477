import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import {
  AutosuggestSandbox,
  QueryDetailsSandbox,
  SearchResultSandbox,
  ModifiersSandbox, MarkdownSandbox
} from "../components/sandbox";
import {
  MarkdownDocumentation, MarkdownCheatSheet,
  CategoriesYMLDocumentation,
  ParserDocumentation, DatasetsYMLDocumentation,
} from "./docs";
import "../components/sandbox/styles.css";

const tabs = {
  "Markdown only": MarkdownSandbox,
  "Auto Suggest": AutosuggestSandbox,
  "Search Result": SearchResultSandbox,
  "Query Details": QueryDetailsSandbox,
  "Modifiers": ModifiersSandbox,
  "Markdown docs": MarkdownDocumentation,
  // Test cheatsheet usage
  //"Cheatsheet": MarkdownCheatSheet,
  "datasets.yml": DatasetsYMLDocumentation,
  "categories.yml": CategoriesYMLDocumentation,
  "Parser": ParserDocumentation,
}

const CustomRendererSandbox = ({searchSessionStore: {fetchEntities}}) => {
  document.title = "Template Sandbox";
  const [currentTab, setCurrentTab] = useState(localStorage['sandboxTab'] || 1);
  return (
    <Row className="clear-margin">
      <Col xs={{span: 10, offset: 1}}>
        <div>
          <h1>Template Sandbox</h1>
          <p><em>Test templates, interpolation, and text marking</em></p>
          <Tabs defaultActiveKey={currentTab} id="uncontrolled-tab-example">
            {Object.entries(tabs).map(([k, Component], idx) => {
              return (
                <Tab
                  eventKey={idx + 1}
                  key={idx + 1}
                  title={k}
                  className="sandbox-tab"
                  onClick={() => {setCurrentTab(idx+1); localStorage['sandboxTab'] = idx+1}}>
                <br/>
                <Component fetchEntities={fetchEntities} />
              </Tab>);
            })}
          </Tabs>
        </div>
      </Col>
    </Row>
  );
};

export default inject("searchSessionStore")(observer(CustomRendererSandbox));
