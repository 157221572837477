import md5 from "md5";
import {toJS} from "mobx";
import {getConfig} from "./configMgr";
import {RequestBuilder} from "../queries/request";
import {InvalidSearchParameters} from "../queries/errors";

// TODO: much of this module could replaced by an OpenAPI/swagger library
const { MAX_URL_LENGTH } = getConfig();

const SEARCH_PAGE = "search";

const cache = window.localStorage;

// Convert URL params into internal JS params
// Include _only_ those params used in the URL, _not_ all API params
const REQUEST_KEY = {
  "direct_connection_id": "directConnectionID",
  "export_format": "exportFormat",
  "find_related": "findRelated",
  "ids": "searchIDs",
  "imap": "dsConfig",
  "limit": "pageSize",
  "min_expansion": "minExpansion",
  "page_token": "pageToken",
  "sim_method": "simMethod",
  "sim_type": "simType",
  "sim_types": "simType",
  "sim_threshold": "simThreshold",
  "sim_substructure": "simSubstructure",
  "target_id": "targetID",
};

export const generateQueryParams = (params) => {
  const {
    categories = null,
    category = null,
    datasetsFilter = null,
    directConnectionID = null,
    dsConfig = null,
    exportFormat = null,
    findRelated = null,
    full = null,
    indirect = null,
    messages = null,
    minExpansion = null,
    model = null,
    neighborhood = null,
    pageSize = null,
    pageToken = null,
    queryEntities = null,
    searchIDs = null,
    simMethod = null,
    simSubstructure = null,
    simType = null,
    simThreshold = null,
    snapshotSize = null,
    stream = null,
    targetID = null,
    targetCategory = null,
    xf = null,
  } = params;
  const queryParams = {};
  // TODO: use a map instead of individual settings

  if (searchIDs != null) {
    queryParams['ids'] = searchIDs;
  }
  else if (queryEntities != null) {
    queryParams['ids'] = queryEntities.map(el => el.id);
  }
  if (dsConfig != null) {
    queryParams["imap"] = dsConfig;
  }
  if (simThreshold != null) {
    queryParams["sim_threshold"] = simThreshold;
  }
  if (simType != null) {
    queryParams["sim_type"] = simType;
  }
  if (simMethod != null) {
    queryParams["sim_method"] = simMethod;
  }
  if (simSubstructure != null) {
    queryParams["sim_substructure"] = simSubstructure;
  }
  if (minExpansion != null) {
    queryParams["min_expansion"] = minExpansion;
  }
  if (findRelated != null) {
    queryParams["find_related"] = findRelated;
  }
  if (indirect != null) {
    queryParams["indirect"] = indirect;
  }
  if (categories?.length > 0) {
    queryParams["categories"] = categories;
  }
  if (stream) {
    queryParams["stream"] = stream;
  }
  if (category != null) {
    queryParams["category"] = category;
  }
  if (datasetsFilter != null) {
    queryParams["datasets_filter"] = datasetsFilter;
  }
  if (model != null) {
    queryParams["model"] = model;
  }
  if (messages != null) {
    queryParams["messages"] = messages;
  }
  if (directConnectionID != null) {
    queryParams["direct_connection_id"] = directConnectionID;
  }
  if (neighborhood != null) {
    queryParams["neighborhood"] = neighborhood;
  }

  if (targetID != null) {
    queryParams['target_id'] = targetID;
  }

  if (targetCategory != null) {
    queryParams['target_category'] = targetCategory;
  }

  if (!!pageSize) {
    queryParams["limit"] = pageSize;
  }
  if (!!pageToken) {
    queryParams["page_token"] = pageToken;
  }
  if (!!exportFormat) {
    queryParams["export_format"] = exportFormat;
  }
  if (full !== null) {
    queryParams["full"] = full;
  }
  if (snapshotSize !== null) {
    queryParams["snapshot_size"] = snapshotSize;
  }
  if (xf !== null) {
    queryParams["xf"] = xf;
  }
  return queryParams;
};

export const constructSearchRequest = (baseURL, params, forcePOST = false, streamProgress = false) => {
  // SSE is disabled by default until we've tested w/potential AWS/cloudfront timeouts
  const queryParams = generateQueryParams(params);
  const url = SearchContext.getAppSearchURL(queryParams, {}, baseURL);
  if (url == null) {
    throw new InvalidSearchParameters(params);
  }
  if (streamProgress) {
    url.searchParams.set('stream_progress', true);
  }
  if (!forcePOST && url.toString().length < MAX_URL_LENGTH
      && !queryParams.messages
      && !queryParams.templates) {
    return new RequestBuilder(url);
  }
  url.searchParams.delete('ids');
  const body = {
    ids: queryParams.ids,
  }
  if (queryParams.messages) {
    url.searchParams.delete('messages');
    body.messages = queryParams.messages;
  }
  if (queryParams.templates) {
    url.searchParams.delete('templates');
    body.templates = queryParams.templates;
  }
  return new RequestBuilder(url).asPOST(JSON.stringify(body));
};

class SearchContext {
  // Given parameters in either client or server-side format, generate an appropriate URL
  static getAppSearchURL = (params, localParams, baseURL=`/${SEARCH_PAGE}`) => {
    if (params.searchIDs || params.queryEntities) {
      params = generateQueryParams(params);
    }
    if (!params.ids?.length) {
      if (!(params.model && params.messages)) {
        return null;
      }
    }
    const url = new URL(baseURL, window.location.origin);
    Object.entries(params).forEach(([name, value]) => {
      if (value != null) {
        if (value && typeof(value) !== 'string' && value.length) {
          value.forEach(element => {
            url.searchParams.append(name, element);
          });
        }
        else {
          url.searchParams.set(name, value);
        }
      }
    });
    const hashParams = new URLSearchParams(localParams);
    if (url.toString().length > MAX_URL_LENGTH) {
      url.searchParams.getAll('ids').forEach(el => hashParams.append('ids', el));
      url.searchParams.delete('ids');
      url.searchParams.getAll('messages').forEach(el => hashParams.append('messages', el));
      url.searchParams.delete('messages');
    }
    url.hash = hashParams.toString();
    return url;
  };

  // Convert query string parameters into internal app parameters; remove any params that are API-only,
  // e.g. exportFormat, targetID, etc.
  static parseQueryStringParams = (queryStr, hash = "") => {
    if (hash.startsWith("#")) {
      hash = hash.substring(1);
    }
    const searchParams = new URLSearchParams(`${queryStr}&${hash}`);
    const params = { searchIDs: []};
    searchParams.forEach((val, name) => {
      const jsKey = REQUEST_KEY[name] || name;
      switch (name) {
        case "ids":
          const ids = [val]
            .map(e => e.replace("entrez_gene:", "entrezgene:")
                   .replace("open_targets:", "opentargets:")
                   .replace("patent:", "patents:")
                   .replace("company:", "orgs:")
            )
            .reduce((result, el) => {
              if (el.trim() !== "" && el.indexOf(":") !== -1) {
                result.push(el);
              }
              return result;
            }, []);
          // Ensure all values are unique and sorted
          params[jsKey] = Array.from(new Set(params[jsKey].concat(ids))).sort();
          break;
        case "limit":
        case "neighborhood":
        case "imap":
        case "min_expansion":
          params[jsKey] = parseInt(val, 10);
          break;
        case "indirect":
        case "find_related":
        case "sim_substructure":
          params[jsKey] = val === "true" || val === "";
          break;
        case "sim_threshold":
          params[jsKey] = parseFloat(val);
          break;
        // Convert obsolete usage into current usage
        case "sim_types":
          if (val.indexOf(":") !== -1) {
            const [type, simThreshold] = val.split(":", 2);
            params[jsKey] = type;
            params["simThreshold"] = parseFloat(simThreshold);
          }
          else {
            params[jsKey] = val;
          }
          break;
        // These parameters don't belong in a search URL
        case "export_format":
        case "datasets_filter":
        case "category":
        case "categories":
        case "target_id":
        case "model":
        case "snapshot_size":
          break;
        default:
          params[jsKey] = val;
          break;
      }
    });
    return params;
  };
}

export default SearchContext;
