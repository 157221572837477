import React           from 'react'
import PropTypes       from 'prop-types'
import _               from 'lodash'
import SingleChain from './SingleChain'
import { Carousel }    from 'react-bootstrap'

class SingleChainContainer extends React.Component{

    carouselEnd = null;

    constructor(props){
        super(props);
        this.state = { isCarouseling: false };
        this.handleCarouseling = this.handleCarouseling.bind(this);
    }

    getRangesToDisplay(data, showCount) {
        if(data.length === showCount){
            return [data]
        }
        const oddShowCount = showCount % 2 === 0 ? showCount - 1 : showCount;
        const sliceCount = Math.floor(data.length/oddShowCount);
        const result = [];
        result.push(_(data).drop(0).take(oddShowCount).value());
        for (let i = 1; i < sliceCount; i++) {
            result.push(_(data).drop(i*(oddShowCount - 1)).take(oddShowCount).value())
        }
        return [...result, _(data).takeRight(oddShowCount).value()]
    }

    indexData(blocks){
        return blocks.map((el,i) => ({idx: i, ...el}))
    }

    handleCarouseling(){
        // because there are no CarouselingStart/CarouselingEnd events, use timer delay to simulate them
        if (!this.state.isCarouseling) {
            if (this.carouselEnd) {
                clearTimeout(this.carouselEnd);
            }
            this.setState({ isCarouseling: true }, () => {
                this.carouselEnd = setTimeout(() => this.setState({ isCarouseling: false }),1000)
            })
        }
    }

    render(){
        const {
            scale,
            showCount,
            evidenceChainData,
            isResizing,
            isDragging,
            isScrolling,
            datasets,
            highlightedDatasets
        } = this.props;
        const { isCarouseling } = this.state;
        const slices = this.getRangesToDisplay(evidenceChainData, showCount)
        //console.log(`Show ${showCount} at ${scale}`);
        return (
            <div className="ev-ch-container">
                <Carousel
                  style={{width: "100%"}}
                  interval={null}
                  indicators={false}
                  controls={slices.length > 1}
                  nextIcon={<div className="glyphicon glyphicon-chevron-right"/>}
                  prevIcon={<div className="glyphicon glyphicon-chevron-left"/>}
                  onSelect={this.handleCarouseling} wrap={false}
                >
                {
                    slices.map((path, idx) =>
                        <Carousel.Item key={idx}>
                            <SingleChain
                                path={path}
                                scaleFactor={scale}
                                actions={{isResizing, isDragging, isScrolling, isCarouseling}}
                                datasets={datasets}
                                highlightedDatasets={highlightedDatasets}
                            />
                        </Carousel.Item>
                    )
                }
                </Carousel>
            </div>
        )
    }
}

SingleChainContainer.propTypes = {
    evidenceChainData: PropTypes.arrayOf(PropTypes.object).isRequired,
    showCount: PropTypes.number.isRequired,
    scale: PropTypes.string.isRequired
}

export default SingleChainContainer;
