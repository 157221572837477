import React, {useEffect, useState} from "react";
import QuerySummaryItem from "./QuerySummaryItem";
import QuerySummaryHeader from "./QuerySummaryHeader";
//import "react-accessible-accordion/dist/fancy-example.css";
import QueryDetails from "./QueryDetails";
import { inject, observer } from "mobx-react";
import {mark} from "../../lib/utils";


const QuerySummary = ({searchSessionStore, style}) => {

  const [filter, setFilter] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [expandAliases, setExpandAliases] = useState(false);

  const { queryEntities = [], templates = {}} = searchSessionStore;

  const getDisplayName = obj => obj['label'] || obj.id;
  const filteredQueryEntities = queryEntities
    .filter(el => getDisplayName(el).toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  const displayedEntities = filteredQueryEntities.length > 0 ? filteredQueryEntities : queryEntities;

  useEffect(() => {
    if (!displayedEntities.includes(activeItem)) {
      setActiveItem(displayedEntities.length ? displayedEntities[0] : null);
    }
  }, [displayedEntities, filter, activeItem]);

  const markText = (text) => {
    if (filter) {
      const searchText = filter.toLowerCase();
      const start = text.toLowerCase().indexOf(searchText);
      if (start >= 0) {
        const end = start + searchText.length;
        const marked = mark(text.substring(start, end));
        return (
          <span>{text.substring(0, start)}{marked}{text.substring(end)}</span>
        );
      }
    }
    return text;
  };
  const  contents = (
    <div className="query-summary-contents query-results-accordion accordion-padding">
      <QuerySummaryHeader
        totalAmount={queryEntities.length}
        filteredAmount={filteredQueryEntities.length}
        filterText={filter}
        onFilterInputChange={setFilter}
      />
      {activeItem && (
        <QueryDetails
          expandAliases={expandAliases}
          onAliasesToggle={setExpandAliases}
          templates={templates}
          entity={activeItem}
        />
      )}
      <div className="summary-body">
        <ul className="summary-body-list">
          {displayedEntities.map((qe, idx) => (
            <QuerySummaryItem
              key={qe.id}
              displayName={getDisplayName(qe)}
              selected={activeItem && qe.id === activeItem.id}
              onClick={_ => setActiveItem(qe)}
              markText={markText}
            />
          ))}
        </ul>
      </div>
    </div>
  );

  return <div className="query-summary" style={style}>{contents}</div>;
}

export default inject("searchSessionStore")(observer(QuerySummary));
