// Client-side defaults
export const CS_AS_Top_Default = `*{id}*`;
export const CS_AS_Item_Default = `{id}`;
export const CS_AS_Group_Default = `<span class="search-option-group-header">{label}</span>`;
export const CS_AS_Group_Pager_Default = `<button class="as-list-showmore-btn">{label}</button>`;
export const CS_AS_Select_Group_Default = `<button class="search-option-select-group">{label}</button>`;
// Backend side defaults
// global defaults
export const AS_Top_Default = `#### {title} [{dataset_id}]

{category}`;

export const AS_Item_Default = `**{title}**

{description}`;

// compounds
export const AS_Top_Compound = `<SMILES encoding='{smiles}' width='128px' height='128px'/>

#### {title} [{dataset_id}]

{category}`;

export const AS_Item_Compound = `<SMILES encoding='{smiles}' width='64px' height='64px'/>

**{title}**

{description}`;

// publications
export const AS_Item_Publication = `{title}

_{citation}_`;

// patents
export const AS_Item_Patents = `**{title}**

\\[Publication Date\\]: {publicationDate}

\\[Inventors\\]: {inventors}

_{abstract}_`;

export const AS_Ovrd_SMILES = `<SMILES encoding='{smiles}' width='64px' height='64px'/>`;
