import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import modalFactory from "../modalFactory";
import TokenDetailsBody from "./TokenItemDetail";
import CopyToClipboardButton from "../../CopyToClipboardButton";
import {flattenEntity} from "../../../markdown/utils";

const SelectedOptionsDetails = ({
  selectedOptions = [],
  onItemDelete,
  isOpen,
  hideModal
}) => {
  const getQueryIDs = () => selectedOptions.map((si) => si.id).join("\n");
  return (
    <Modal show={isOpen} onHide={hideModal} >
      <Modal.Header closeButton>
        <Modal.Title>{`Search Terms List (${selectedOptions.length})`}
          <CopyToClipboardButton title="Copy query IDs" className="flat-button" getText={getQueryIDs}/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="er-l-dlg-body" autoFocus={true}>
        {selectedOptions.map((si, idx) => (
          <div key={si.id}>
            <TokenDetailsBody entity={flattenEntity(si)} onItemDelete={onItemDelete} />
            <br style={{ clear: "both" }} />
            {idx < selectedOptions.length - 1 ? <hr /> : null}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
}

SelectedOptionsDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.object)
};

export default SelectedOptionsDetails;
export const TokenDetailsModalProvider = modalFactory(SelectedOptionsDetails);
