import React from "react";
import {DELETE_MARKER} from "../../lib/utils";

const QuerySummaryHeader = ({
  totalAmount,
  filteredAmount,
  filterText,
  onFilterInputChange
}) => (
  <div className="summary-header">
    <span>{`Query Items${
      totalAmount === 0 
        ? ""
        : ` (${totalAmount === filteredAmount 
          ? totalAmount 
          : `${filteredAmount}/${totalAmount}`})`}`
    }</span>
    <br />
    {totalAmount > 5 ? (
      <React.Fragment>
        <input
          type="text"
          value={filterText}
          placeholder="filter..."
          onChange={(e)=>onFilterInputChange(e.target.value)} />
        <span
          className="input-reset inside"
          title="Clear"
          onClick={()=>onFilterInputChange("")}
          style={{display: filterText.length ? "inline" : "none"}}>
          {DELETE_MARKER}
        </span>
      </React.Fragment>
    ) : null}
  </div>
);

export default QuerySummaryHeader;
