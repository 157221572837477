import React from 'react'
import OutboundLink from '../../../components/OutboundLink'
import SpinnerAnimation from '../../../components/Spinner'
import Checkbox from "../../../components/controls/Checkbox";
const SignIn = ({
    username, onUsernameChange, onUsernameBlur,
    password, onPasswordChange,
    rememberMe, onRememberMeChange,
    enableSubmit, isRequestProcessing,
    onSignIn,
    onRegisterClick,
    onForgotPass
}) => (
    <div>
      {/*<span>Email</span>*/}
        <p>
            <input
                className    = "login-control-username"
                type         = "text"
                autoComplete = "username"
                placeholder  = "Username/Email"
                value        = { username }
                onChange     = { onUsernameChange }
                onBlur       = { onUsernameBlur }
                disabled     = { !isRequestProcessing ? "" : "disabled" }
            />
        </p>
      {/*<span>Password</span>*/}
        <p>
            <input
                type         = "password"
                className    = "login-control-pass"
                autoComplete = "current-password"
                placeholder  = "Password"
                value        = { password }
                onChange     = { onPasswordChange }
                disabled     = { !isRequestProcessing ? "" : "disabled" }
            />
        </p>
        <p>
          <Checkbox
            checked={rememberMe}
            label={"Remember Me"}
            onChange={onRememberMeChange}
          />
          <button type="button" id={"forgotPass"} className="signup-btn" onClick={onForgotPass}>Forgot Password?</button>
        </p>
        <p>
          <button autoFocus
                  inputMode={"submit"}
                  className = "login-submit"
                  onClick   = { onSignIn }
                  disabled  = { enableSubmit ? "" : "disabled" }
          >
            {isRequestProcessing ? 'Authorizing' : "Sign in" }
            <SpinnerAnimation style = {{float: "right", display: isRequestProcessing ? "" : "none"}}/>
          </button>
        </p>
        <p className="signup-block">
            <button
                type = "button"
                className = "signup-btn"
                onClick   = { onRegisterClick }
                title     = {"Click here to register"}
                style     = {{float: "left"}}
            >
                Not registered yet?
            </button>
            <OutboundLink
                title       = "Please contact us at support@plexresearch.com"
                href= "mailto:support@plexresearch.com"
                className   = "signup-btn"
                style       = {{float: "right"}}
                targetBlank = {false}
            >
                {"Need help?"}
            </OutboundLink>
            <br/>
        </p>
    </div>
)

export default SignIn;
