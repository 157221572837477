import {useCallback, useEffect, useState} from "react";
import useDraggable from "./Draggable";


export const useResizable = (resizeHandleRef, resizableRef, options = {}) => {

  const {onResize = () => {}} = options;

  const [baseSize, setBaseSize] = useState(null);
  const [currentSize, setCurrentSize] = useState(null);
  const [eventType, setEventType] = useState(null);

  const handleDrag = useCallback(({type, dx, dy}) => {
    setEventType(type === "drag" ? "resize" : type);
    const resizable = resizableRef.current;
    if (resizable) {
      const bounds = resizable.getBoundingClientRect();
      if (baseSize == null) {
        setBaseSize({width: bounds.width, height: bounds.height});
        setCurrentSize({width: bounds.width + dx, height: bounds.height + dy});
      }
      else {
        setCurrentSize({width: baseSize.width + dx, height: baseSize.height + dy});
      }
      if (type === "end") {
        setBaseSize({width: bounds.width, height: bounds.height});
      }
    }
  }, [resizableRef.current, baseSize]);

  useEffect(() => {
    const resizable = resizableRef.current;
    if (resizable && currentSize) {
      console.debug(`Apply ${eventType} ${currentSize.width}x${currentSize.height}`);
      resizable.style.width = `${currentSize.width}px`;
      resizable.style.height = `${currentSize.height}px`;
      onResize({type: eventType, ...currentSize});
    }
  }, [resizableRef.current, currentSize, eventType]);

  useDraggable(resizeHandleRef, {onDrag: handleDrag});
};

export default useResizable;
