import MoleculeImage from "../../components/MoleculeImage";
import LoadingAnimation from "../../components/LoadingAnimation";

export const overrideMap = {
  SMILES: {
    component: MoleculeImage
  },
  Loading: {
    component: LoadingAnimation,
  }
};
