import React, {useCallback, useEffect, useState} from "react";
import logo from "../img/official_logo.png";
import SearchBox from "../components/search-box";
import { inject, observer } from "mobx-react";

import {setPageTitle} from "../lib/utils";

const LandingPage = ({searchSessionStore: {cancelSearch}}) => {
  useEffect(() => {
    // Cancel/clear any extant search when page first loads
    cancelSearch();
  }, []);
  useEffect(() => {
    setPageTitle();
  }, []);

  return (
    <div className="app-container">
      <div className="logo-wrapper">
        <img src={logo} className="app-logo" alt="Plex Research"/>
      </div>
      <SearchBox/>
    </div>
  );
}

export default inject("searchSessionStore")(observer(LandingPage));
