import { getObjectProjection, createSample } from "../utils";
import {
  AS_Top_Default,
  AS_Item_Default,
  AS_Top_Compound,
  AS_Item_Compound,
  AS_Item_Publication,
  AS_Item_Patents,
  AS_Ovrd_SMILES
} from "./templates";
import {
  SAMPLE_COMPOUND,
  SAMPLE_PUBLICATION,
  SAMPLE_PATENT
} from "../sample-data-objects";

export const AS_samples = [
  createSample(
    "Top item",
    AS_Top_Default,
    getObjectProjection(SAMPLE_COMPOUND, ["title", "dataset_id", "category", "smiles"])
  ),
  createSample(
    "Regular item",
    AS_Item_Default,
    getObjectProjection(SAMPLE_COMPOUND, ["title", "description"])
  ),
  createSample(
    "Compound top item",
    AS_Top_Compound,
    getObjectProjection(SAMPLE_COMPOUND, [
      "title",
      "smiles",
      "dataset_id",
      "category"
    ])
  ),
  createSample(
    "Compound regular item",
    AS_Item_Compound,
    getObjectProjection(SAMPLE_COMPOUND, ["title", "smiles", "description"])
  ),
  createSample(
    "Publication regular item",
    AS_Item_Publication,
    getObjectProjection(SAMPLE_PUBLICATION, ["title", "citation"])
  ),
  createSample(
    "Patent regular item",
    AS_Item_Patents,
    getObjectProjection(SAMPLE_PATENT, [
      "title",
      "publicationDate",
      "inventors",
      "abstract"
    ])
  )
];

export const AS_Extended_samples = [
  createSample(
    "SMILES",
    AS_Ovrd_SMILES,
    getObjectProjection(SAMPLE_COMPOUND, ["smiles"])
  )
];
