export const TDL_Default = `
<div>
    <div style="margin-right: 5px; float: left"> 
        <SMILES encoding='{smiles}' width='128px' height='128px'/>
    </div>
    <i>{category}</i>
</div>

<b>{gene_symbol} {title plex_match} {organism | bracket} {source_id id | bracket}</b>
{source abstract description}<br/>
{smiles --notmd}
`;

export const TDL_Target = `not implemented yet`;

export const TDL_Compound = `not implemented yet`;
