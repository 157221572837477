import _ from 'lodash'

class ResponsiveHelper {
    defaultSettings = {
        minWidthRatio: 0.2,
        maxWidthRatio: 0.9,
        minHeightRatio: 0.2,
        maxHeightRatio: 0.9
    };

    constructor(settings) {
        this.sizeGrid = [
            {id: 0, scale: "lg", width: 200, height: 300, canInc: false, canDec: true},
            {id: 1, scale: "md", width: 150, height: 260, canInc: true, canDec: true},
            {id: 2, scale: "sm", width: 100, height: 190, canInc: true, canDec: false}
        ]
        this.defaultSettings = settings;
        this.updateInnerParams(settings);
    }

    static getViewport(){
        const w = window.innerWidth
          || (document.documentElement && document.documentElement.clientWidth)
          || (document.body && document.body.clientWidth);
        const h = window.innerHeight
          || (document.documentElement && document.documentElement.clientHeight)
          || (document.body && document.body.clientHeight);
        return { width : w, height : h }
    }

    updateInnerParams = (settings) => {
        const {minWidthRatio = 0.2, maxWidthRatio = 0.9, minHeightRatio = 0.2, maxHeightRatio = 0.9}
          = settings || this.defaultSettings;
        const { width: screenWidth, height: screenHeight } = ResponsiveHelper.getViewport()
        this.minWidth = Math.round(screenWidth * minWidthRatio);
        this.maxWidth = Math.round(screenWidth * maxWidthRatio);
        this.minHeight = Math.round(screenHeight * minHeightRatio);
        this.maxHeight = Math.round(screenHeight * maxHeightRatio);
    }

    increaseScale = (settings)=> {
        return settings.canInc ? _(this.sizeGrid).find(x => x.id === settings.id - 1) : settings;
    }

    decreaseScale = (settings) => {
        return settings.canDec ? _(this.sizeGrid).find(x => x.id === settings.id + 1) : settings;
    }

    getTotalWidth = (blockCount) => {
        const res = this.sizeGrid
                .map(x => x.width)
                .sort((a,b) => a < b ? -1 : a > b ? 1 : 0)
                .reduce((acc, w) => {
                    const t = Math.max( w * blockCount, acc);
                    return  t > this.maxWidth ? acc : t
                },this.minWidth);
        return res+Math.round(res/10 + 60); // Math.round(res/10) - correction on margins/paddings
    }

    /*
        rowData: Array<int> : array of block counts for every row
    */
    getTotalHeight = (rowData) => {
        const areaHeight = _(rowData).map(x => this.getResponsiveSettings(x).height).sum();
        return Math.min(areaHeight, this.maxHeight)
    }

    // TODO: using maxElCount no more reasonable. Now need to calc settings depending on possible width that fits all blocks
    getResponsiveSettings = (blockCount) => {
        return _(this.sizeGrid).find(x => x.width * blockCount <= this.maxWidth) || this.sizeGrid[0];
    }

    getScale = (blockCount) => {
        return this.getResponsiveSettings(blockCount).scale
    }
}

export default ResponsiveHelper;
