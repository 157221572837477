export const SR_Default = "#### {title} {id | bracket}";

export const SR_Backend_Default = `<Header
  rank={rank}
  url={source.url}
  source_name={source.name}
  title={title}
  showMatch={showMatch}
/>
<Links links={links}/>
<Text text={smiles} title='SMILES' />
<Text text={inchikey} title='Inchi Key'/>
<List list={aliases} title='Aliases' />
{description}
`;

export const SR_Ovrd_Header = `<Header
  rank={rank}
  url={source.url}
  source_name={source.name}
  title={title}
  showMatch={showMatch}
/>`;

export const SR_Ovrd_Links = `<Links 
  links={links}
/>`;

export const SR_Ovrd_CollapsibleText = `<Text
  text={inchi}
  title='inchi'
/>`;

export const SR_Ovrd_CollapsibleList = `<List
  list={aliases}
  title='Aliases'
/>`;
