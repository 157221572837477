import React from "react";
import logo from "../img/official_logo.png";

const ErrorPage = (props) => (
  <div className="app-container error-page">
    <div className="logo-wrapper">
      <img src={logo} className="app-logo" alt="Plex Research" />
    </div>
    <div className="error-content">
      <span>
        Oops. We seem to have encountered a problem. Please go back to the
        main page and try again.
      </span>
      <br />
      <details>
        <div className="error-header">{props.error && props.error.toString()}</div>
        <div className="error-details">{
          props.errorInfo.componentStack
            .split("\n")
            .map((line, idx) => (<span key={idx}>{line}<br/></span>))
        }</div>
      </details>
    </div>
    <div>
      <a href={props.location.href}>Back to the main page</a>
    </div>
  </div>
);

export default ErrorPage;
