import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {toast} from "react-toastify";
import { inject, observer } from "mobx-react";
import contentDisposition from "content-disposition";

import ICON_XLSX from "../../img/export-xlsx.png";
import ICON_TSV from "../../img/export-tsv.png";
import ICON_CSV from "../../img/export-csv.png";
import { checkOfflineState } from "../OfflineDetect";
import { saveBlob } from "../../lib/utils";


const ICON_BLANK = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";


const ExportMenu = ({
    className,
    searchSessionStore: {
      awaitingInitialResults,
      searchIDs,
      lastError,
      exportSearchResultsAsFormat,
    },
    userStore: {isAdmin}
  }) => {

  const isDisabled = awaitingInitialResults
    || !searchIDs?.length
    || lastError !== null;

  const [isDownloading, setIsDownloading] = useState(false);

  const handleExport = (format) => useCallback(e => {
    let filename = null;
    if (!window.navigator.onLine) {
      e.preventDefault();
    } else {
      setIsDownloading(true);
      exportSearchResultsAsFormat(format)
        .then(rsp => {
          const header = rsp.headers.get('content-disposition');
          const disposition = typeof header === "string" ? contentDisposition.parse(header) : null;
          filename = disposition ? disposition.parameters.filename : `export.${format}`;
          return rsp;
        }).then(rsp => rsp.blob()).then(blob => {
          saveBlob(filename, blob);
        })
        .catch(err => {
          toast.error(`Export failed (${err})`);
          console.error(`Export error: ${err}`);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  }, [exportSearchResultsAsFormat]);

  const title = isDisabled
    ? "Search is in progress\nExport is unavailable until it completes"
    : isDownloading
      ? "Download in progress"
      : "Export results";

  const disabled = isDisabled || isDownloading;

  return (
      <span title={title} className="export-menu">
        <DropdownButton
          id="dropdown-export-menu"
          key="export"
          className={className}
          title={<span className={`glyphicon glyphicon-download-alt ${isDownloading ? " icon-pulse" : ""}`} />}
          disabled={disabled}
        >
          <Dropdown.Item
            disabled={disabled}
            onClick={checkOfflineState(
              handleExport("csv")
            )}
          >
            <img src={ICON_CSV} alt="Export as CSV" />
            <span>Export as CSV</span>
          </Dropdown.Item>
          <Dropdown.Item
            disabled={disabled}
            onClick={checkOfflineState(
              handleExport("tsv")
            )}
          >
            <img src={ICON_TSV} alt="Export as TSV" />
            <span>Export as TSV</span>
          </Dropdown.Item>
          <Dropdown.Item
            disabled={disabled}
            onClick={checkOfflineState(
              handleExport("xlsx")
            )}
          >
            <img src={ICON_XLSX} alt="Export as Excel" />
            <span>Export as Excel</span>
          </Dropdown.Item>
          <Dropdown.Item
            disabled={disabled}
            onClick={checkOfflineState(
              handleExport("graphml")
            )}
          >
            <div className="fi fi-rs-network-analytic" />
            <span>Export as GraphML</span>
          </Dropdown.Item>
          {isAdmin && (<Dropdown.Item
            disabled={disabled}
            onClick={checkOfflineState(
              handleExport("gexf")
            )}
          >
            <div className="fi fi-rs-network-analytic"/>
            <span>Export as GEXF</span>
          </Dropdown.Item>)}
          {isAdmin && (<Dropdown.Item
            className={"admin"}
            disabled={disabled}
            onClick={checkOfflineState(
              handleExport("svg")
            )}
          >
            <div className={"fi fi-rs-picture"}/>
            <span>Export as Image</span>
          </Dropdown.Item>)}
        </DropdownButton>
      </span>
    );
};

ExportMenu.propTypes = {
  searchIDs: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default inject("searchSessionStore", "userStore")(observer(ExportMenu));
