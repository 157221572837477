import React from "react";

const EmptyResult = ({ content }) => (
  <div className="empty-result">
    <p className="empty-result-content">
      {(content && content.split("\n").map((line, idx) => (<span key={idx}>{line}<br/></span>))) || "No results"}
    </p>
  </div>
);

export default EmptyResult;
