import {wait} from "../lib/utils";
import msgpack from "msgpack-lite";
import {random} from "lodash";
import {NotAuthorized, SearchTimeoutError, ServerError, ServerOfflineError, ServerStartingException} from "./errors";
import { getConfig } from "../lib/configMgr";

const {env} = getConfig();

let requestID = 0;

export const toJSON = response => {
  // Make sure that if the response is not JSON we still deliver some useful information about the error
  // TODO: handle errors w/JSON content
  const isMsgPack = response.headers.get('content-type').includes("application/x-msgpack");
  const isJSON = response.headers.get('content-type').includes("application/json");
  return (isMsgPack
          ? response.arrayBuffer().then(buffer => msgpack.decode(new Uint8Array(buffer)))
          : isJSON ? response.json() : response.text())
    .catch(error => {
      const status = response.status;
      const url = response.url;
      const details =  response.statusText ? `: ${response.statusText}` : ""
      const content = `Error parsing server response from ${url}${details}: (${error})`;
      console.error(content, response);
      return {
        "status": status >= 400 ? status : 400,
        "content": content
      }
  });
};


export class RequestBuilder {
  _method = "GET";
  _url = null;
  _headers = new Headers(env === "dev" ? {} : {"Accept": "application/x-msgpack; */*"});
  //_headers = new Headers();
  _body = null;
  _failed_auth_tokens = new Set();

  constructor(url) {
    this._url = url;
    this._id = ++requestID;
  }

  get id() {
    return this._id;
  }

  get url() {
    return this._url;
  }

  get auth() {
    return this._authData;
  }

  withHeader = (name, value) => {
    this._headers.set(name, value);
    return this;
  };

  withJSONContentType = () => {
    this.withHeader("Content-Type", "application/json");
    return this;
  };

  withAuthorization = (authData) => {
    if (!(authData && authData.jwtToken)) {
      console.warn("Bad authData", authData);
      throw new Error("Unable to create authorized request");
    }
    this._authData = authData;
    return this.withHeader("authorization", "Bearer " + authData.jwtToken);
  };

  asPOST = body => {
    this._method = "POST";
    this._body = body;
    return this;
  };

  fetch = (retries = 0, backoff = 5000, cannedResponse = null) => {
    const MAX_RETRIES = 2;
    if (retries === 0 && this._authData) {
      //this._authData.jwtToken = 'meant-to-fail';
    }

    const request = this;
    return fetch(this.url.toString(), {
      method: this._method,
      headers: this._headers,
      body: this._body
    })
      .then(response => {
        if (cannedResponse) {
          if (cannedResponse instanceof TypeError) {
            throw cannedResponse;
          }
          response = cannedResponse;
        }
        if (response.ok) {
          return response;
        }
        if ([502, 504].includes(response.status)) {
          throw new ServerOfflineError();
        }
        if ([408, 413, 504].includes(response.status)) {
          throw new SearchTimeoutError();
        }
        if ([429, 502, 503].includes(response.status)) {
          console.warn(`Server busy, retry after ${backoff / 1000}s (${this.url})`, response);
          const nextBackoff = Math.min(backoff * 2, 30000) + random(0, 1000, false);
          return wait(backoff).then(() => request.fetch(0, nextBackoff));
        }
        if (response.status === 401) {
          if (!request._authData) {
            console.error("No auth data provided");
          }
          else if (retries >= MAX_RETRIES) {
            console.error("Max auth retries exceeded");
          }
          else {
            const failedToken = request._authData.jwtToken;
            const abbr = (token) => `${token.slice(0, 6)}...${token.slice(-6)}`;
            const abbrToken = abbr(failedToken);
            if (request._failed_auth_tokens.has(failedToken)) {
              console.error(`Already failed once with this token (${abbrToken})`);
            }
            request._failed_auth_tokens.add(failedToken);
            console.info(`Auth failure with [rid=${request._id} url=${request.url}] ${abbrToken}, refreshing token`, this._authData, response);
            return request._authData.refresh()
              .then((authData) => {
                console.info(`Repeat request [rid=${this.id}] with new auth ${abbr(authData.jwtToken)}`);
                return request.withAuthorization(authData).fetch(retries + 1);
              });
          }
          throw new NotAuthorized();
        }
        if (response.headers.get('content-type').includes('application/x-msgpack')) {
          return response.arrayBuffer().then((buffer) => {
            const json = msgpack.decode(new Uint8Array(buffer));
            throw new ServerError(`[${response.status}] ${json.error || JSON.stringify(json)}`);
          });
        }
        if (response.headers.get('content-type').includes('application/json')) {
          return response.json().then((json) => {
            throw new ServerError(`[${response.status}] ${json.error || JSON.stringify(json)}`);
          });
        }
        return response.text().then((text) => {
          throw new ServerError(`[${response.status}] ${text}`);
        });
      })
      .catch(error => {
        // Check for fetch errors
        if (error instanceof TypeError) {
          throw new ServerOfflineError(error);
        }
        console.error("Request error", error);
        throw error;
      });
  };
}
