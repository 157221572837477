export const parser_doc = `
\`\`\`
Expression parser: version 2

[SYNTAX]: { TERM.1 TERM.2 TERM.3 ... TERM.n | FILTER.1 | FILTER.2 }
  [TERM]:                 OBJECT_KEY              // id, title, entity.id
    [OBJECT_KEY]:         SIMPLE | COMPOUND       // id, title, entity.id
      [SIMPLE]:           STRING                  // id, title
      [COMPOUND]:         STRING contains '.'     // entity.id
  [FILTER]:                                       // upper, fixed=3 
    [WITH_ARGUMENT]:    <operator>=<argument>     // fixed=3 
    [WITHOUT_ARGUMENT]: <operator>                // upper

[RULES]
  For multiple object keys fallback lookup is in left-to-right order
  In case of fallback options the first defined value will be used
  For multiple modifiers application order is left-to-righ

[EXAMPLES]
  const dataObject = {
    id: "123134",
    entity: {
      title: "BRD4",
      rank: 0.345345,
      category: "target"
    }
  }

  // simple object key
  <span>{id}</span> 
    -> <span>123134</span> 

  // simple object key with compound object key as fallback
  // dataObject.title === undefined BUT dataObject.entity.title === "BRD4"
  <span>{title entity.title }</span>
    -> <span>BRD4</span>

  // single filter with argument
  <span>{entity.rank | fixed=2}</span>
    -> <span>0.34</span>

  // single filter without argument
  <span>{entity.title | lower}</span>
    -> <span>brd4</span>

\`\`\`
`;
