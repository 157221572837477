import React from "react";
import OutboundLink from "./OutboundLink";
import LabeledEntity from "./LabeledEntity";
import {naturalSort} from "../lib/utils";
import {interpolateEdgeDetails} from "../markdown/interpolation";

const EdgeDetails = ({edgeSource = null, edgeTarget = null, edgeAttributes = {}, datasets}) => {
  const {details: evidence = []} = edgeAttributes || {};
  const edgeDatasets = Array.from(new Set(evidence.map(d => d.dataset)));
  const evidenceByDataset = edgeDatasets.reduce((result, ds) => {
    result[ds] = evidence.filter(d => d.dataset === ds)
      .map(d => interpolateEdgeDetails(d, datasets[ds], edgeSource?.category, edgeTarget?.category));
    return result;
  }, {});

  const consolidateByTitle = (edges = []) => {
    const byTitle = edges.reduce((result, edge) => {
      const key = edge.title + edge.url;
      if (result[key]) {
        result[key].push(edge);
      }
      else {
        result[key] = [edge];
      }
      return result;
    }, {});
    return Object.entries(byTitle).reduce((result, [k, elist]) => {
      const edge = elist[0];
      const title = elist.length > 1 ? `${edge.title} (${elist.length})` : edge.title;
      result.push({...edge, title});
      return result;
    }, []);
  };

  return (
    <div
      className={"graph-details"}
    >
      <div className="nodes">
        {edgeSource && edgeTarget && (
          <div className="evidence-arrow" />
        )}
        {([edgeSource, edgeTarget]).filter(e => !!e)
          .map(entity => (
            <LabeledEntity key={entity.id} entity={entity} dataset={datasets[entity.dataset]} showCategory={false}/>
          ))}
      </div>
      <div className="edges">
        {Object.entries(evidenceByDataset).map(([ds, edges], idx) => (
          <div key={`${ds}-edges`} className="dataset-edges" style={{
            flexShrink: edges.length > 4 ? 1 : 0,
            flexGrow: edges.length > 5 ? 1 : 0,
          }}>
            {datasets[ds] && datasets[ds].url
             ? (<OutboundLink
                key={ds}
                className="dataset"
                href={datasets[ds].url} title={datasets[ds].description}>
                {`${datasets[ds].name}${edges.length > 3 ? " (" + edges.length + ")" : ""}`}
              </OutboundLink>)
             : datasets[ds]
               ? (<span key={ds} className="dataset" title={datasets[ds].description}>{datasets[ds].name}</span>)
               : (<span key={ds} className="dataset">{`(unknown ds=${ds})`}</span>)
            }
            <div className={"edge-details"}>
              {consolidateByTitle(edges)
                .sort((a, b) => naturalSort(a.title, b.title))
                .map((edge, eidx) => (
                  <div key={eidx} className="edge-detail">
                    {edge.url
                     ? (<OutboundLink
                        href={edge.url}
                        title={edge.description}>{edge.title || `(title missing (${edge.dataset}))`}
                      </OutboundLink>)
                     : (<span
                        title={`${edge.description} (${edge.source_id})`}>{edge.title || `(title missing (${edge.dataset}))`}</span>)}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
};


export default EdgeDetails;
