import React, {useMemo} from "react";
import {MultiUndirectedGraph} from "graphology";
import {SigmaContainer} from "@react-sigma/core";
import SigmaEdgeGraphLoader from "../components/SigmaEdgeGraphLoader";

const NODES = [
  {id: 1, rank: 0.9, size: 40, color: "#00FF00", column: 0},
  {id: 11, rank: 0.9, size: 10, color: "#00FF00", column: 0},
  {id: 12, rank: 0.9, size: 10, color: "#00FF00", column: 0},
  {id: 2, rank: 0.5, size: 20, color: "#0000FF", column: 0},
  {id: 21, rank: 0.5, size: 25, color: "#0000FF", column: 0},
  {id: 22, rank: 0.5, size: 10, color: "#0000FF", column: 0},
  {id: 23, rank: 0.5, size: 10, color: "#0000FF", column: 0},
  {id: 24, rank: 0.5, size: 5, color: "#0000FF", column: 0},
  {id: 25, rank: 0.5, size: 5, color: "#0000FF", column: 0},
  {id: 26, rank: 0.5, size: 15, color: "#0000FF", column: 0},
  {id: 3, rank: 0.1, size: 10, color: "#FF00FF", column: 0},
  {id: 31, rank: 0.1, size: 10, color: "#FF00FF", column: 0},
  {id: 32, rank: 0.1, size: 10, color: "#FF00FF", column: 0},

  {id: 221, rank: 0.9, size: 40, color: "#00FF00", column: 1},
  {id: 2211, rank: 0.9, size: 10, color: "#00FF00", column: 1},
  {id: 2212, rank: 0.9, size: 10, color: "#00FF00", column: 2},
  {id: 222, rank: 0.5, size: 20, color: "#0000FF", column: 2},
  {id: 2221, rank: 0.5, size: 25, color: "#0000FF", column: 2},
  {id: 2222, rank: 0.5, size: 10, color: "#0000FF", column: 2},
  {id: 2223, rank: 0.5, size: 10, color: "#0000FF", column: 3},
  {id: 2224, rank: 0.5, size: 5, color: "#0000FF", column: 3},
  {id: 2225, rank: 0.5, size: 5, color: "#0000FF", column: 1},
  {id: 2226, rank: 0.5, size: 15, color: "#0000FF", column: 3},
  {id: 223, rank: 0.1, size: 10, color: "#FF00FF", column: 3},
  {id: 2231, rank: 0.1, size: 10, color: "#FF00FF", column: 3},
  {id: 2232, rank: 0.1, size: 10, color: "#FF00FF", column: 2},
];


const initNodes = (nodes) => {
  nodes.forEach(n => {
    n.x = n.column * 100;
    n.y = n.rank * 100;
  })
  return nodes;
};


const SigmaGraphPlayground = ({nodes = NODES, controlsPosition = "bottom-right"}) => {
  const onReset = () => {
    initNodes(nodes);
  };
  return (
    <div className="graph" style={{border: "2px solid gray", width: "50%"}}>
      <SigmaContainer
        graph={MultiUndirectedGraph}
        style={{width: "100%", height: "400px"}}
        settings={{
          renderEdgeLabels: true,
          minCameraRatio: 0.95,
          maxCameraRatio: 1.05
        }}
      >
        <SigmaEdgeGraphLoader
          nodes={nodes}
          controlsPosition={controlsPosition}
          onReset={onReset}
        />
      </SigmaContainer>
    </div>
  );
};

export default SigmaGraphPlayground;
