import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AboutPlexModalProvider } from "../modals/AboutPlexModal";

const InfoMenu = ({className}) => (
  <span title="About Plex" className="info-menu">
    <DropdownButton
      id="dropdown-info-menu"
      key="info"
      className={className}
      title={<span className="glyphicon glyphicon-info-sign" />}
    >
      <AboutPlexModalProvider>
        {({ showModal }) => (
          <Dropdown.Item onClick={() => showModal()}>About Plex Search</Dropdown.Item>
        )}
      </AboutPlexModalProvider>
      <Dropdown.Item
        className={"admin"}
        title={"Ask Plex about Plex"}>
        Application Help
      </Dropdown.Item>
      <Dropdown.Item
        title="Contact support@plexresearch.com"
        href="mailto:support@plexresearch.com"
      >
        Contact support@plexresearch.com
      </Dropdown.Item>
    </DropdownButton>
  </span>
);

export default InfoMenu;
