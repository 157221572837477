import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";

export const RE_MODULATION = new RegExp(
  '((allosteric )?inhibit(ors?|i(on|ng))?'
  + '|antagonis([em]|t(s|ic)?)'
  + '|(allosteric )?activat(e|ors?|i(on|ng))?'
  + '|(\\b|_)agonis([em]|t(s|ic)?)'
  + '|(positive|negative).*?modulat(i(on|ng)))',
  "gi");

// Must return a string
export const formatActivitiesTooltip = (entries, markText = null) => {
  const listItems = entries.reduce((result, [title, desc], idx) => {
    const item = `<em>${title}</em> ${desc}`.replaceAll(RE_MODULATION, '<b>$1</b>');
    return result + `<li class='activity-entry'>${markText ? markText(item, true) : item}</li>`;
  }, "");
  return `<ul class='activities-tooltip' >${listItems}</ul>`;
}

// Special-case activities formatting
// list of elements of the form [geneSymbol, {activityLabel: [title, desc], ...}]
export const isActivitiesList = (list) => list.length > 0 && typeof(list[0]) !== "string";

export const formatActivitiesList = (list, markText: * = null) =>
  list.map((el, idx) => formatActivity(el, idx, markText));

const Activity = ({label, entries, index, markText = null}) => {
  const [tooltipHTML, setTooltipHTML] = useState("");
  const [activityLabel, setActivityLabel] = useState(label);

  // Marking large tooltips is very slow, need to optimize
  markText = null;

  useEffect(() => {
    setTooltipHTML(formatActivitiesTooltip(entries, markText));
  }, [entries, markText]);

  useEffect(() => {
    const marked = tooltipHTML.indexOf("</mark") !== -1;
    setActivityLabel(marked ? (<mark>{label}</mark>) : (markText && markText(label) || label));
  }, [label, markText, tooltipHTML]);

  return (
    <span
      className="specific-activity"
      key={index}
      data-tooltip-id="tt"
      data-tooltip-html={tooltipHTML}
    >
      {index !== 0 ? ", " : ""}{activityLabel}
    </span>
  );
};


export const formatActivity = ([symbol, activity], idx, markText = null) => {
  try {
    const activities = Object.entries(activity)
      .map(([label, entries], label_idx) => {
        return (<Activity label={label} entries={entries} index={label_idx} key={label_idx} markText={markText} />)
      });
    const marked = markText && markText(symbol);
    return (<span className="target-activity" key={idx}>{marked || (<b>{symbol}</b>)} {activities}</span>);

  } catch(error) {
    console.warn(`Error decoding activities for ${symbol}`, error);
  }
  return (<span className="target-activity target-activity-truncated" key={idx}>{symbol}</span>);
};

const VisiblePart = ({ list, markText = null, showEllipsis = false }) => {

  const formatList = (list, markText = null) => {
    if (isActivitiesList(list)) {
      return formatActivitiesList(list, markText);
    }
    return markText ? list.map(el => markText(el)) : list;
  };
  const [items, setItems] = useState(formatList(list, markText));
  const isActivities = isActivitiesList(list);

  useEffect(() => {
    const unmarkedItems = formatList(list);
    if (markText) {
      const markedItems = formatList(list, markText);
      const hasMarked = !_.isEqual(unmarkedItems, markedItems)
      setItems(hasMarked ? (_.isEqual(items, markedItems) ? items : markedItems) : unmarkedItems);
    }
    else {
      setItems(_.isEqual(items, unmarkedItems) ? items : unmarkedItems);
    }
  }, [list, markText]);

  return (
    // symbol => (label=>desc)
    <span className={`collapsible-visible${isActivities && " activities" || ""}`}>{
      [...items, ...(showEllipsis ? [showEllipsis] : [])].map((el, item_idx) => [
        (item_idx !== 0
          ? (<span key={`div-${item_idx}`} className="list-divider">|</span>)
          : null),
        (<span className="collapsible-visible-item" key={item_idx}>{el}</span>),
        " "
      ])}</span>
  );
}

VisiblePart.propTypes = {
  list: PropTypes.array.isRequired,
  markText: PropTypes.func,
};

export default VisiblePart;
