import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { checkOfflineState } from "./OfflineDetect";

const FiltersBox = ({ searchSessionStore }) => {
  const {
    params: { directConnectionID: dc, neighborhood: nbr },
    filterByDirectConnection,
    filterByNeighborhood
  } = searchSessionStore;

  return (
    <ul className="custom-nav-bar filter-box filter-bar">
      {dc || (nbr || nbr === 0) ? (
        <Fragment>
          <li>Filters</li>
          {dc ? (
            <li className="results-filter results-dc-filter">
              <Button
                size="sm"
                title={`Direct connections to ${dc}`}
                onClick={checkOfflineState(() => filterByDirectConnection(null))}
              >
                {`Connected to ${dc}`}
                <span className="glyphicon glyphicon-remove filter-box-btn-img" />
              </Button>
            </li>
          ) : null}
          {nbr || nbr === 0 ? (
            <li className="results-filter results-nbr-filter">
              <Button
                size="sm"
                title={`Neighborhood ${nbr}`}
                onClick={checkOfflineState(() => filterByNeighborhood(null))}
              >
                {`Neighborhood ${nbr}`}
                <span className="glyphicon glyphicon-remove filter-box-btn-img" />
              </Button>
            </li>
          ) : null}
        </Fragment>
      ) : null}
    </ul>
  );
};

FiltersBox.propTypes = {
  directConnectionID: PropTypes.object,
  neighborhood: PropTypes.object,
  handleClearDC: PropTypes.func,
  handleClearNBH1: PropTypes.func
};

export default inject("searchSessionStore")(observer(FiltersBox));
