import {getConfig} from "../lib/configMgr";
import {toJSON} from "./request";
import {SearchCanceled} from "./errors";
import {constructSearchRequest} from "../lib/SearchContext";
import {wait} from "../lib/utils";

const { ExternalRESTAPI } = getConfig();

export const fetchConnected = (searchParams, datasetsFilter, category, auth) => {
    return fetchSearch({...searchParams, datasetsFilter, category}, auth);
}

export const fetchSearch = (searchParams, auth, searchType = "search",
                            canceled = () => false, onProgress = () => {},
                            polling = false) => {
  // Only returns when initial search results become available
  const req = constructSearchRequest(ExternalRESTAPI.Search, searchParams);
  if (!polling && !searchParams.pageToken && !searchParams.targetCategory) {
    //console.debug(`Initiate search fetch: ${searchType}`);
  }
  return req
    .withJSONContentType()
    .withAuthorization(auth)
    .fetch()
    .then(toJSON)
    .then((result) => {
      if (canceled()) {
        throw new SearchCanceled("Search canceled");
      }
      const {status = null, page_token = null, results = null} = result;
      if (results || (status && (status === 200 || status >= 400))) {
        return searchType === "search" ? {pageToken: page_token, ...result} : result;
      }
      if (result.stream) {
        return new Promise((resolve, reject) => {
          const eventSource = new EventSource(`${ExternalRESTAPI.Stream}?channel=${result.stream}`);
          eventSource.onmessage = event => {
            const data = JSON.parse(event.data);
            //console.log("Search update", data);
            if (data.status && (data.status === 200 || data.status >= 400)) {
              eventSource.close();
              // Actual results need some post-processing, so re-request
              return req.fetch()
                .then(toJSON)
                .then(result => resolve(result))
                .catch(error => reject(error));
            }
            else {
              onProgress(data.progress, data.total, data.info || "");
            }
          };
          eventSource.onerror = e => {
            eventSource.close();
            console.error("Search failed", e);
            reject(e);
          }
        });
      }
      onProgress(result.progress, result.total, result.info || "");
      // Make sure we use the most recent auth available...
      return wait(5000).then(() => fetchSearch(searchParams, req.auth, searchType, canceled, onProgress, true));
    })
};

export const exportSearchResults = (params, auth, format = "svg", baseURL = ExternalRESTAPI.Search) => {
  const exportParams = {"exportFormat": format}
  const req = constructSearchRequest(
    baseURL,
    {
      ...params,
      ...exportParams,
    },
    false
  );

  return req
    .withJSONContentType()
    .withAuthorization(auth)
    .fetch();
};
