import localConfig from "../configs/config.json";
import devConfig from "../configs/config.dev.json";
import versionInfo from "../configs/versionInfo.json";

export const getConfig = _ => {
  if (process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV?.trim() === "dev") {
    return devConfig;
  }

  if (localConfig.cell === "dev" && !localConfig.patched) {
    const api = localConfig.ExternalRESTAPI;
    Object.entries(api).forEach(([k, v]) => {
      api[v] = v.replace("https://plexsearch.com/api/", "http://localhost:5000/api/");
    });
    localConfig.patched = true;
  }
  return localConfig;
};

export const getEnv = () => getConfig().env;

export const getVersionInfo = () => versionInfo;
