import React, {useEffect, useState} from "react";
import { inject, observer } from "mobx-react";
import {toast} from "react-toastify";
import _ from "lodash";

export const checkOfflineState = (next = f => f) => evarg => {
  if (navigator.onLine) {
    next(evarg);
  } else {
    toast.warning("Your computer seems to be offline. Until network connectivity is restored, most actions will be unavailable.",
                  {toastId: "offline"});

    if (evarg && evarg.preventDefault) {
      evarg.preventDefault();
    }
    if (evarg && evarg.stopPropagation) {
      evarg.stopPropagation();
    }
  }
};

const COLOR_ONLINE = "color: #00A94A";
const COLOR_OFFLINE = "color: #ED7F00";

const OfflineDetect = (props) => {
  const {appStatusStore, userStore} = props;
  const [online, setOnline] = useState(true);

  const notifyAndUpdateAppState = online => {
    if (online) {
      toast.dismiss();
      userStore.restoreSession();
    }
    appStatusStore.online = online;
  };
  useEffect(() => {
    notifyAndUpdateAppState(online);
  }, [online])

  const handleOnline = () => {
    console.log("%cNetwork is available", COLOR_ONLINE);
    setOnline(true);
  };

  const handleOffline = () => {
    console.log("%cNetwork is unavailable", COLOR_OFFLINE);
    setOnline(false);
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("offline", handleOffline)
      window.removeEventListener("online", handleOnline)
    };
  }, [])

  return online ? null : <div className="offline" />;
}

export default inject("appStatusStore", "userStore")(observer(OfflineDetect));
