import React from "react";
import Select from "react-select";


export const SimpleSelect = (props) => {
  const {
    className = null,
    label = "",
    title = "",
    options = [],
    getOptionLabel = (x) => `${x}`,
    getOptionValue = (x) => `${x}`,
    disabled,
    onChange = () => {},
    controlShouldRenderValue = true,
    openMenuOnFocus = true,
    openMenuOnClick = true,
    value = null,
    autosize = true,
    ...selectProps
  } = props;
  const isDisabled = disabled || !options?.length;
  const currentValue = `${value == null && options?.length ? options[0] : value}`;
  return (
    <span className={`simple-select${className ? ' ' + className : ''}${isDisabled ? ' disabled' : ''}`}>
      <label className=".simple-select-label" title={title}>{label && (<span>{label}</span>)}<Select
        autosize={autosize}
        classNamePrefix={"select"}
        options={(options || []).map(opt => ({label: getOptionLabel(opt), value: getOptionValue(opt)}))}
        defaultValue={{label: getOptionLabel(currentValue), value: getOptionValue(currentValue)}}
        getOptionLabel={opt => opt.label}
        getOptionValue={opt => opt.value}
        disabled={isDisabled}
        onChange={(v) => onChange(v.value)}
        isClearable={false}
        isLoading={false}
        isMulti={false}
        controlShouldRenderValue={controlShouldRenderValue}
        hideSelectedOptions={false}
        isSearchable={false}
        openMenuOnClick={openMenuOnClick}
        openMenuOnFocus={openMenuOnFocus}
        {...selectProps}
      /></label></span>
  );
}

export default SimpleSelect;
