import { getConfig } from "../lib/configMgr";
import { toJSON, RequestBuilder } from "./request";
import {toast} from "react-toastify";

const { ExternalRESTAPI, AWSCognito: { region, userPoolID, clientID } } = getConfig();

export const fetchVersionData = () => {
  const req = new RequestBuilder(ExternalRESTAPI.VersionInfo);

  return req.fetch()
    .then(toJSON)
    .then(data => ({
        ...data,
        userPoolID: data.pool_id,
        clientID: data.client_id
    }))
    .catch(error => {
      if (error instanceof TypeError) {
        const msg = "Server is unavailable, refresh page when server is available";
        console.warn(msg);
        toast.warning(msg);
        return {
          ready: null,
          region,
          userPoolID,
          clientID,
        };
      }
      console.debug(`Version fetch error ${error}`);
      throw error;
    });
};

export const flushCache = () => {
  const req = new RequestBuilder(`${ExternalRESTAPI.Reload}?cache=true`);

  return req.asPOST().fetch().then(toJSON).catch(err => {});
};
