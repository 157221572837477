import React from "react";

export const MessageType = {
  None: "",
  AlreadyConfirmed: "AlreadyConfirmed",
  CheckLatestEmail: "CheckLatestEmail",
  ConfirmAccount: "ConfirmAccount",
  ConfirmationFailed: "ConfirmationFailed",
  CopyVerificationCode: "CopyVerificationCode",
  InvalidUsername: "InvalidUsername",
  LimitExceededException: "LimitExceededException",
  LinkExpired: "LinkExpired",
  LoginError: "LoginError",
  NewConfirmationLink: "NewConfirmationLink",
  NotAuthorized: "NotAuthorized",
  NotAuthorizedException: "NotAuthorizedException",
  PasswordExpired: "PasswordExpired",
  PasswordResetRequiredException: "PasswordResetRequiredException",
  UserConfirmed: "UserConfirmed",
  UserLambdaValidationException: "UserLambdaValidationException",
  UserNotConfirmedException: "UserNotConfirmedException",
  UserNotFoundException: "UserNotFoundException",
  VerificationCodeResent: "VerificationCodeResent",
};

export const getMessageObject = (err, arg) => {
  const code = typeof(err) === 'string' ? err : err.code;
  const detail = typeof (err) === 'string' ? err : err.message;
  let output = null;

  if (code === MessageType.UserConfirmed) {
    output = MessageObject(`User ${arg ? arg + " " : ""} confirmed, please sign in.`, "info",
                           MessageType.UserConfirmed);
  }
  else if (code === MessageType.PasswordResetRequiredException) {
    output = MessageObject("You must change your password to continue.", "error",
                           MessageType.PasswordResetRequiredException);
  }
  else if (code === MessageType.PasswordExpired) {
    output = MessageObject("Your password has expired and must be changed.", "error",
                           MessageType.PasswordExpired);
  }
  // AWS Cognito response: {"__type":"NotAuthorizedException","message":"Incorrect username or password."}
  else if (code === MessageType.UserNotFoundException || code === MessageType.NotAuthorizedException) {
    output = MessageObject(`Sign in failed for user ${arg}\nNew users should select the registration link below`,
                           "error", MessageType.UserNotFoundException);
  }
  else if (code === MessageType.UserNotConfirmedException) {
    output = MessageObject(
      `You must confirm your email address before signing in.  Please follow the link in the most recent confirmation sent to ${arg}.`,
      "error", MessageType.UserNotConfirmedException);
  }
  else if (code === MessageType.InvalidUsername) {
    output = MessageObject(`Invalid username "${arg}".`, "error", MessageType.InvalidUsername);
  }
  else if (code === MessageType.LinkExpired) {
    output = MessageObject("Link expired. Look for a newer confirmation or re-register.", "warning",
                           MessageType.LinkExpired);
  }
  else if (code === MessageType.ConfirmationFailed) {
    output = MessageObject("Confirmation failed, please re-register.", "warning", MessageType.ConfirmationFailed);
  }
  else if (code === MessageType.ConfirmAccount) {
    output = MessageObject(
      `An email confirmation link has been sent to ${arg}.  Follow it to complete registration.`, "info",
      MessageType.ConfirmAccount);
  }
  else if (code === MessageType.CheckLatestEmail) {
    output = MessageObject(
      `Please respond to the latest confirmation email sent to ${arg ? arg : "your email"}.`, "warning",
      MessageType.CheckLatestEmail);
  }
  else if (code === MessageType.AlreadyConfirmed) {
    output = MessageObject("Already confirmed, please log in.", "info", MessageType.AlreadyConfirmed);
  }
  else if (code === MessageType.NewConfirmationLink) {
    output = MessageObject(
      `A new confirmation link has been sent to ${arg}. Follow it to complete registration.`, "warning",
      MessageType.NewConfirmationLink);
  }
  else if (code === MessageType.NotAuthorized) {
    output = MessageObject(arg ? arg : "User is not authorized to use this application.", "error",
                           MessageType.NotAuthorized);
  }
  else if (code === MessageType.VerificationCodeResent) {
    output = MessageObject(
      `A new access link has been sent to ${arg ? arg : "your email"}.  Follow it to change your password.`, "warning",
      MessageType.VerificationCodeResent);
  }
  else if (code === MessageType.CopyVerificationCode) {
    output = MessageObject(
      `An access link has been sent to your ${arg ? arg : "your email"}.  Follow it to change your password.`, "info",
      MessageType.CopyVerificationCode);
  }
  else if (code === MessageType.LoginError) {
    output = MessageObject(`The system can't authorize ${arg ? arg : "this user"}.`, "error",
                           MessageType.LoginError);
  }
  else {
    output = MessageObject(detail, arg, code);
  }

  return output;
};

export const MessageObject = (message: string = "", type: string = MessageType.None, name: string = "") => ({
  type,
  name,
  message
});

export const Message = ({message = null}) => {
  if (typeof(message) === "string") {
    message = MessageObject(message);
  }
  return message && (
    <p className={`message ${message.type}`}>{message.message}</p>
  );
};
