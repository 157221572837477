import React from "react";
import "./styles.css";

const TemplateEditor = ({ initText, onTemplateChange }) => {
  return (
    <div>
      <h4>Template</h4>
      <textarea
        className="sndb-template-editor"
        value={initText}
        onChange={x => onTemplateChange(x.target.value)}
      />
    </div>
  );
};

export default TemplateEditor;
