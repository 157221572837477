import React, {useCallback} from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {inject, observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
import * as PropTypes from "prop-types";

const KEY_API = "api";
const KEY_SIGNOUT = "sign-out";
const KEY_CHANGEPASS = "change-password"

const UserMenu = ({
                    userName, apiKey,
                    className,
                    onSignOut,
                    onChangePassword,
                    onGenerateAPIKey,
                  }) => (
  <span title="User information" className="user-menu">
    <DropdownButton
      id="dropdown-user-menu"
      key="user"
      className={className}
      onSelect={key => {
        if (key === KEY_API) {
          onGenerateAPIKey();
        } else if (key === KEY_SIGNOUT) {
          onSignOut();
        } else if (key === KEY_CHANGEPASS) {
          onChangePassword();
        }
      }}
      title={<span className="glyphicon glyphicon-user"/>}
    >
      <Dropdown.Header>Hello, {userName ? userName : "anonymous"}!</Dropdown.Header>
      <Dropdown.Divider/>
      <Dropdown.Item eventKey={KEY_CHANGEPASS}>Change Password</Dropdown.Item>
      <Dropdown.Divider/>
      <Dropdown.Header>API Key</Dropdown.Header>
      <CopyToClipboard text={apiKey || ""}>
        <Dropdown.Item
          title="Current API Key, click to copy"
          className={"api-key"}
          disabled={!apiKey}>
          <span className="glyphicon glyphicon-copy"/>
          <span className={"api-key"}>{apiKey ? apiKey : "No key has been generated"}</span>
        </Dropdown.Item>
      </CopyToClipboard>
      <Dropdown.Item title="Generate a new API Key" eventKey={KEY_API}>
        {apiKey ? "Regenerate" : "Generate"} API Key
      </Dropdown.Item>
      <Dropdown.Divider/>
      <Dropdown.Item eventKey={KEY_SIGNOUT}>Sign Out</Dropdown.Item>
    </DropdownButton>
  </span>
);


UserMenu.propTypes = {
  userName: PropTypes.string,
  onChangePassword: PropTypes.func,
  onSignOut: PropTypes.func
};
const UserMenuWrapper = ({
    userStore,
    searchSessionStore,
    appStatusStore,
    className,
  }) => {

  const {userName, apiKey} = userStore;
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangePassword = useCallback(() => {
    navigate("/changepass", {
      state: {
        username: userName
      }
    });
  }, [userName]);

  const handleSignOut = useCallback(() => {
    appStatusStore.clear();
    searchSessionStore.clear();
    userStore.signOut();
    // Do not save a "from" URL
    navigate("/signin", {replace: true});
  }, [appStatusStore, searchSessionStore, userStore]);

  const handleGenerateAPIKey = useCallback(() => {
    userStore.generateAPIKey();
  }, [userStore]);

  return (
      <UserMenu
          className={className}
          userName={userName}
          apiKey={apiKey}
          onChangePassword={handleChangePassword}
          onSignOut={handleSignOut}
          onGenerateAPIKey={handleGenerateAPIKey}
      />
  );
};

export default inject("userStore", "appStatusStore", "searchSessionStore")(observer(UserMenuWrapper));

