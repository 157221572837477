import LabeledEntity from "../../components/LabeledEntity";
import MoleculeImage from "../../components/MoleculeImage";
import {
  MDCollapsibleText,
  MDCollapsibleList
} from "../../components/results-grid/grid-row/MDCollapsibleContent";

export const overrideMapSample = {
  Text: {
    component: MDCollapsibleText
  },
  List: {
    component: MDCollapsibleList
  },
  Ball: {
    component: LabeledEntity,
    props: { scale: "lg" }
  },
  SMILES: {
    component: MoleculeImage
  }
};
