// Look up stuff using wikimedia API
import LRU from "lru-cache";
import {wait} from "./utils";

export const NOT_FOUND = 'NOTFOUND';
export const PENDING = 'PENDING';
const WIKI_API = 'https://en.wikipedia.org/w/api.php'
const cache = new LRU(500);
const REGEXP_IMAGE = /\.(svg|png|jpe?g|gif)$/i;
const REGEXP_LOGO = /logo/i;
const REGEXP_IGNORE = /(Commons|Wik[it][^-]+)/i;
export const fetchWikiFileURL = async (filename) => {
  try {
    const url = new URL(WIKI_API)
    const p = url.searchParams;
    p.set('action', 'query');
    p.set('titles', filename);
    p.set('prop', 'imageinfo');
    p.set('iiprop', 'url');
    p.set('origin', '*');
    p.set('format', 'json');
    p.set('formatversion', 2)
    return fetch(url.toString(), {
      headers: {
        'Origin': window.location.origin,
        'Content-Type': 'application/json; charset=UTF-8',
      }
    })
      .then(response => response.json())
      .then(json => {
        return json.query.pages[0].imageinfo[0].url;
      });
  }
  catch (err) {
    console.error("fetch failed: ", err);
    throw err;
  }
}
export const fetchWikiImage = (terms) => {
  const cached = cache.get(terms);
  if (cached === PENDING) {
    return wait(500).then(() => fetchWikiImage(terms));
  }
  if (cached) {
    return cached;
  }
  cache.set(terms, PENDING);
  const wiki = new URL(WIKI_API);
  const p = wiki.searchParams;
  p.set('action', 'query');
  p.set('titles', terms);
  p.set('prop', 'pageimages|images');
  p.set('piprop', 'original');
  p.set('pilicense', 'any');
  p.set('format', 'json');
  p.set('formatversion', 2)
  p.set('origin', '*');
  return fetch(wiki.toString(), {
    headers: {
      'Origin': window.location.origin,
      'Content-Type': 'application/json; charset=UTF-8',
    }
  })
    .then(response => response.json())
    .then(async json => {
      if (json.query && json.query.pages) {
        for (const page of Object.values(json.query.pages)) {
          const logos = (page.images || [])
            .filter((image) => (REGEXP_LOGO.test(image.title) && !REGEXP_IGNORE.test(image.title)));
          for (const image of logos) {
            const url = await fetchWikiFileURL(image.title)
            cache.set(terms, url);
            if (image.title.indexOf(terms.lower()) !== -1) {
              return url;
            }
          }
          if (logos.length > 0) {
            const url = await fetchWikiFileURL(logos[0].title)
            cache.set(terms, url);
            return url;
          }
          if (page.original && page.original.source && REGEXP_IMAGE.test(page.original.source)) {
            cache.set(terms, page.original.source);
            return page.original.source;
          }
        }
      }
      cache.set(terms, NOT_FOUND);
      return NOT_FOUND;
    })
    .catch(error => {
      cache.set(terms, NOT_FOUND);
      return NOT_FOUND;
    });
};
