import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import OutboundLink from '../OutboundLink'
import {OverlayTrigger, Popover, PopoverBody} from 'react-bootstrap'
import Checkbox from '../controls/Checkbox'
import {elideString} from '../../lib/utils'
import _ from 'lodash'

const DP_SELECTION_LIMIT = 3;

const GroupedEdges = (props) => {

  const {
    edges,
    datasets,
    highlightedDatasets = null,
    actions: {isResizing = false, isDragging = false, isScrolling = false, isCarouseling = false} = {},
    scaleFactor = null,
  } = props;

  const highlighted = new Set(highlightedDatasets);

  const initializeSelectedIndices = (edges) => {
    const firstPerDataset = _(edges)
      .uniqBy('dataset');
    const datasetCount = firstPerDataset.value().length;
    if (datasetCount >= 2) {
      const selectedIndices = firstPerDataset.map(x => x.key).value();
      if (datasetCount === 2) {
        // Show at least three datapoints, if available
        selectedIndices.push(_(edges).map(x => x.key).without(...selectedIndices).head());
      }
      return selectedIndices;
    }
    return _(edges).take(dpSelectionLimit).map(x => x.key).value();
  };

  const computeDatapoints = (edges) =>_(edges)
    .map(edge => ({
      dataset: edge.dataset,
      // Interpolate/replace coded titles/descriptions, then
      // group identical datapoints and add a count to the label
      dataPoints: _(edge.dataPoints)
        .groupBy('id') /* was 'title' */
        .entries()
        .map(([groupTitle, groupEntries]) => ({
          ...groupEntries[0],
          title: `${groupEntries[0].title}${groupEntries.length > 1
            ? " (" + groupEntries.length + ")"
            : ""}`
        }))
        .value()
    }))
    .flatMap(group => group.dataPoints.map(dp => ({dataset: group.dataset, ...dp})))
    .map((el, idx) => ({key: idx, ...el}))
    .value();

  const datapoints = computeDatapoints(edges);
  const perDatasetCounts = Object.fromEntries(edges.map(el => ([el.dataset, el.dataPoints.length])));

  const [selectedIndices, setSelectedIndices] = useState(initializeSelectedIndices(datapoints));
  const [showPopup, setShowPopup] = useState(false);
  const dpSelectionLimit = DP_SELECTION_LIMIT;

  const toggleSelection = (key) =>
    setSelectedIndices(selectedIndices.includes(key)
                         ? _(selectedIndices).without(key).value()
                         : [...selectedIndices, key])

  const renderAllDatapoints = () => {
    const groups = getGroupedDatapoints(false);
    return <Popover id="renderAllDatapoint"
                    className="ev-ch-pop-all-dp"
                    onScroll={(e) => e.stopPropagation()}>
      <PopoverBody className="ev-ch-pop-all-dp-body">
        {
          groups.map((group, groupidx) => (
            <ul key={groupidx}>
              <OutboundLink href={group.url}
                            title={group.description}>{`${group.title} (${group.dpCount})`}</OutboundLink>
             {
               group.dps.map((dp, dpidx) => {
                 const isChecked = selectedIndices.includes(dp.key)
                 const limitExceeds = selectedIndices.length === dpSelectionLimit
                 const isDisabled = !isChecked && limitExceeds
                 const title = limitExceeds
                               ? `A maximum of ${dpSelectionLimit} values may be displayed`
                               : `Select a maximum of ${dpSelectionLimit} values to display`
                 return (
                   <li key={dpidx}>
                     <Checkbox checked={isChecked} disabled={isDisabled}
                               title={title}
                               onChange={() => toggleSelection(dp.key)}/>
                     <OutboundLink href={dp.url || ""}
                                   title={`${dp.title}\n${dp.description}`}>
                       {elideString(dp.title, 30)}
                     </OutboundLink>
                   </li>);
                 }
               )
             }
           </ul>
          ))
        }
      </PopoverBody>
    </Popover>
  };

  const getGroupedDatapoints = (selectedOnly = false) => {
    return datapoints
      .filter(dp => selectedOnly ? selectedIndices.includes(dp.key) : true)
      .reduce((array, dp) => {
        const existingDataset = array.find(el => el.dataset === dp.dataset)
        if (existingDataset) {
          existingDataset.dps.push(dp);
        }
        else {
          const ds = dp.dataset === "missing" ? {name: "Missing", id: "missing"} : datasets[dp.dataset];
          if (!ds) {
            console.warn(`Dataset metadata missing for '${dp.dataset}'`);
          }
          else if (dp.dataset !== "missing" && !ds.url) {
            console.warn(`Dataset ${ds.name} is missing its URL`);
          }
          array.push({
                       dataset: ds.id,
                       title: ds.name,
                       description: ds.description,
                       url: ds.url,
                       dps: [dp],
                       dpCount: perDatasetCounts[ds.id],
          })
        }
        return array
      }, []);
  };

  const renderSelectedDatapoints = (groupedDatapoints) => {
    return <div className="ev-ch-edge-list-wrp">
      {
        groupedDatapoints.map((group, idx) => group.dps.length && (
          <ul className="ev-ch-edge-list" key={idx}>
            {group.url
              ? (<OutboundLink
                className={`dataset-info${highlighted.has(group.dataset) ? " highlight" : ""}`}
                href={group.url} title={group.description}>
                {`${group.title} (${group.dpCount})`}
              </OutboundLink>)
              : (<span className="dataset-info" title={group.description}>{group.title}</span>)}
            {
              group.dps.map((dp, dpidx) => (
                <li key={dpidx} className="datapoints-info truncate-overflow truncate-lines-2">
                  {dp.url
                    ? (<OutboundLink href={dp.url} title={dp.description}>{dp.title || '<title missing>'}</OutboundLink>)
                    : (<span title={`${dp.description} (${dp.source_id})`}>{dp.title || '<title missing>'}</span>)}
                </li>
              ))
            }
          </ul>
        )) || (<span className="alert-warning">Evidence temporarily unavailable</span>)
      }
    </div>
  };

  const renderSummary = () => {
    const {edges = []} = props;
    const datasetCount = edges.length;
    const datapointCount = edges
      .map(edge => edge.dataPoints.length)
      .reduce((acc, count) => acc + count, 0);

    return (<div className="ev-ch-edge-summary">
      <b>{datasetCount}</b><span>{`DATA SET${datasetCount === 1 ? "" : "S"}`}</span>
      <br/>
      <b>{datapointCount}</b><span>{`DATA POINT${datapointCount === 1 ? "" : "S"}`}</span>
    </div>)
  };

  useEffect(() => {
    if (showPopup) {
      setShowPopup(false);
    }
  }, [isResizing, isDragging, isScrolling, isCarouseling, scaleFactor]);

  const small = scaleFactor === "sm";
  const renderExpandButton = small || datapoints.length > selectedIndices.length;
  return (
    <div className={`ev-ch-fgm-wrp ev-ch-resp-edge ev-ch-resp-body-${scaleFactor}`}>
      {!small && renderSummary()}
      <div className="ev-ch-fgm-edge-body">
        <div className="ev-ch-fgm-edge-seprt"/>
        <span className="glyphicon glyphicon-play ev-ch-fgm-edge-icon"/>
      </div>
      {!small && renderSelectedDatapoints(getGroupedDatapoints(true))}
      {renderExpandButton && <OverlayTrigger
        defaultShow={false}
        delay={0.2}
        flip={false}
        popperConfig={{}}
        target={null}
        trigger={"click"}
        rootClose={true}
        show={showPopup}
        onToggle={() => setShowPopup(!showPopup)}
        onHide={() => setShowPopup(false)}
        placement="bottom"
        overlay={renderAllDatapoints()}>
        <span className="ev-ch-edge-tgl">
          {small
            ? <span className="glyphicon glyphicon-option-horizontal ellipsis-icon"/>
            : "Show all"}
        </span>
      </OverlayTrigger>}
    </div>
  )
};

GroupedEdges.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.shape({
    dataset: PropTypes.string.isRequired,
    dataPoints: PropTypes.arrayOf(PropTypes.shape({
      source_id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      dataset: PropTypes.string,
      dataset_id: PropTypes.string,
    })).isRequired
  })).isRequired,
  scaleFactor: PropTypes.string.isRequired
}

export default GroupedEdges
