import React from "react";
import {getBestName, getCategoryKey, getDatasetID} from "./utils";
import {interpolate} from "../markdown/interpolation";

const splitID = (id) => {
  const colon = id.indexOf(':');
  return [id.substring(0, colon), id.substring(colon + 1)];
}
const NO_WIKIPEDIA = /^(UCI|CID|S?CHEMBL|.{50,})/i;
// Map aliases' recognizable prefixes/patterns into dataset-native references
// Anything in an alias list matching a dataset's specified id_format pattern will transformed
const matchExternalID = (alias, datasets, category = null, baseCategory = null) => {
  const matches = [];
  datasets.forEach(ds => {
    const id_format = (ds.categories
        && (ds.categories[category] && ds.categories[category].id_format
          || ds.categories[baseCategory] && ds.categories[baseCategory].id_format))
      || ds.id_format;
    if (alias.startsWith(`${ds.id}:`)) {
      alias = alias.substring(ds.id.length + 1);
    }
    const [format, flags] = id_format.slice(-2) === "/i"
      ? [id_format.slice(0, -2), "i"]
      : [id_format, ""];
    try {
      const pattern = new RegExp(`^${format}$`, flags);
      if (pattern.test(alias)) {
        const replacement = `${ds.id}:${ds.id_replace || "$1"}`;
        const dsid = alias.replace(pattern, replacement);
        if (dsid.indexOf(':') !== -1 && dsid !== "") {
          matches.push([ds, dsid]);
        }
      }
    } catch (error) {
      console.warn(`Bad dataset id format regular expression for '${ds.id}': "${ds.id_format}" (${error})`);
    }
  });
  return matches;
};
export const createSourceLink = (dataset, datasetID, entity, dsWiki) => {
  if (!entity) {
    console.error("Entity not provided for source link", dataset, datasetID);
    return null;
  }
  if (!dataset) {
    console.error(`Dataset metadata not provided for source link, datasetID=${datasetID} (${entity.id})`, entity);
    return null;
  }
  if (!dataset.categories) {
    if (dataset.id !== "smiles") {
      console.warn("No dataset categories found for source link", dataset);
    }
    return dsWiki && createWikipediaLink(entity, dsWiki);
  }
  if (!datasetID) {
    console.error("Dataset ID not provided for source link", entity);
    return null;
  }
  const category = entity.category;
  const baseCategory = entity.baseCategory;

  // Look for dataset:<key>:dataset_id
  let info = null;
  if (/^[-a-z]+:/.test(datasetID)) {
    const key = getCategoryKey(datasetID);
    datasetID = getDatasetID(datasetID);
    info = dataset.categories[key];
  }
  if (info == null) {
    info = dataset.categories[category] || dataset.categories[baseCategory];
  }
  if (!info || !info.url) {
    if (!dataset.name || !dataset.url) {
      console.warn(`No metadata found for dataset '${dataset.name}' category '${category}'`);
    }
    return {
      title: (info && info.name) || dataset.name,
      url: (info && info.url) || dataset.url,
      external_id: datasetID,
      dataset: dataset.id,
    }
  }
  const props = {...entity, id: encodeURIComponent(datasetID)};
  return {
    title: info.name || dataset.name,
    description: dataset.description,
    url: info.url ? interpolate(info.url, props) : null,
    title2: info.name2 ? info.name2 : null,
    url2: info.url2 ? interpolate(info.url2, props) : null,
    external_id: datasetID,
    dataset: dataset.id,
  }
};
const createWikipediaLink = (entity, dsWiki) => {
  // Categories which support wikipedia links are identified in datasets.yml, wikipedia section
  if (!dsWiki || !dsWiki.categories) {
    console.warn("Unexpectedly missing dswiki info", dsWiki);
    return null;
  }

  if (!(dsWiki.categories[entity.category] || dsWiki.categories[entity.baseCategory])) {
    return null;
  }
  const bestName = getBestName(entity);
  if (!bestName) {
    return null;
  }
  if (NO_WIKIPEDIA.test(bestName)) {
    return null;
  }
  const searchTerm = bestName.replace(' ', '_');
  return createSourceLink(dsWiki, searchTerm, entity, dsWiki);
};
export const generateExternalLinks = (entity, datasets) => {
  // dataset:dataset_id; uses edge_url
  // dataset:<subtype>:dataset_id; uses <subtype>.url from 'categories' block of datasets.yml
  if (!datasets) {
    console.warn("Datasets is empty");
    return [];
  }

  if (entity.aliases && entity.aliases.length > 10000) {
    console.warn(`Way too many aliases for ${entity.id} (${entity.aliases.length}), probably an error`)
    entity.aliases = entity.aliases.slice(0, 10000)
  }
  if (entity.links) {
    return entity.links;
  }
  if (entity.novel || entity.id?.startsWith("smiles:")) {
    return [];
  }
  const dsWiki = datasets['wikipedia'];

  const addLink = (links_array, link) => {
    if (!link || !links_array) {
      return null;
    }
    // avoid duplicate links
    for (let i = 0; i < links_array.length; i++) {
      if (links_array[i].external_id === link.external_id && links_array[i].dataset === link.dataset) {
        return link;
      }
    }
    links_array.push(link);
    if (link.title2 && link.url2) {
      links_array.push({...link, title: link.title2, url: link.url2})
    }
    return link;
  };
  entity.links = [];
  const [ds, dsid] = splitID(entity.source_id || entity.id);
  const primaryLink = createSourceLink(datasets[ds], dsid, entity, dsWiki);
  const e_links = primaryLink ? [primaryLink] : [];
  const category = entity.category;
  const baseCategory = entity.baseCategory;
  try {
    const potentialLinks = (entity.aliases || []).slice();
    if (category === 'target' && entity.gene_aliases) {
      entity.gene_aliases.forEach(alias => potentialLinks.push(alias));
    }
    if (category === 'patent') {
      potentialLinks.forEach(alias => {
        if (!alias.startsWith(dsid)) {
          const link = createSourceLink(datasets['patents'], alias, entity, dsWiki);
          if (link) {
            addLink(e_links, link);
          }
        }
      });
    }
    if (entity.title) {
      potentialLinks.push(entity.title);
    }
    if (entity.source_id) {
      potentialLinks.push(entity.source_id);
    }
    let wikiLinkCreated = primaryLink?.title === dsWiki.name;
    potentialLinks.forEach(alias => {
      const filteredDatasets = Object.values(datasets)
        .filter(v => v.id_format && (v.categories[category] || v.categories[baseCategory]))
        // Omit google links for schembl-only patents
        .filter(v => !(entity.source === "schembl" && v.id === "patents"));
      const matches = matchExternalID(alias, filteredDatasets, category, baseCategory);
      Object(matches).forEach(match => {
        const [dataset, externalID] = match;
        const [dsName, dsid] = splitID(externalID);
        if (dsName === 'wikipedia') {
          wikiLinkCreated = true;
        }
        // May want to filter out pubchem/schembl patent "aliases"
        const link = createSourceLink(dataset, dsid, entity, dsWiki);
        if (link) {
          addLink(e_links, link);
        }
      });
    });
    // Include a wikipedia link where appropriate
    if (!wikiLinkCreated && dsWiki) {
      addLink(e_links, createWikipediaLink(entity, dsWiki));
    }
    return entity.links = e_links;
  } catch (error) {
    console.error(`Error generating external links for ${entity.id}`, error);
    return entity.links;
  }
};

// Limited markdown rendering: generate links from markdown links
export const linkify = ({text = "", referencedEdges = {}, asString = false}) => {
  const s = text;
  let lastEndIndex = 0;
  const RE = /\[([^\]]+)]\(([^)]+)\)/g;
  const parts = [];
  let match;
  let key = 0;
  while ((match = RE.exec(s)) != null) {
    const unmatched = s.substring(lastEndIndex, match.index);
    if (unmatched !== "") {
      parts.push(asString ? unmatched : (<span key={++key}>{unmatched}</span>));
    }
    parts.push(
      asString ? `[${match[1]}](${match[2]})` : (<a key={++key} href={match[2]} target="_blank">{match[1]}</a>));
    lastEndIndex = match.index + match[0].length;
  }
  if (lastEndIndex < s.length) {
    parts.push(asString ? s.substring(lastEndIndex) : (<span key={++key}>{s.substring(lastEndIndex)}</span>));
    if (asString) {
      parts.push("\n");
      Object.entries(referencedEdges).forEach(([id, url]) => {
        parts.push(`[${id}]: ${url}\n`)
      });
    }
  }
  return asString ? "".join(parts) : parts;
};
