import React, {useEffect, useRef, useState} from "react";
import { Collapse } from "react-bootstrap";
import {isActivitiesList, formatActivitiesList} from "./VisiblePart";
import * as PropTypes from "prop-types";
import {NBSP} from "../../../lib/utils";

const CollapsiblePart = ({ listRef, list, onToggle = null, isOpen = false, markText = null, openOnMarked = false }) => {

  const isActivities = isActivitiesList(list);
  const formatList = (list, markText = null) => {
    if (isActivitiesList(list)) {
      return formatActivitiesList(list, markText);
    }
    return markText ? list.map(el => markText(el)) : list;
  };
  const scrollerRef = useRef();
  const [items, setItems] = useState(formatList(list, markText));
  const [hasMarkedItems, setHasMarkedItems] = useState(false);
  const [firstMarked, setFirstMarked] = useState(null);

  const hideToggle = (openOnMarked && hasMarkedItems) || !onToggle;

  useEffect(() => {
    setFirstMarked(scrollerRef.current?.querySelector('mark') || null);
  });

  useEffect(() => {
    if (hasMarkedItems && scrollerRef.current) {
      // window.scrollIntoView has too many unintended side effects, so set the scroll directly
      if (firstMarked != null) {
        scrollerRef.current.scrollTop = firstMarked.offsetTop - scrollerRef.current.offsetTop;
      }
    }
  });

  useEffect(() => {
    const unmarkedItems = formatList(list);
    if (markText) {
      const markedItems = formatList(list, markText);
      const hasMarked = !_.isEqual(unmarkedItems, markedItems);
      setItems(hasMarked ? (_.isEqual(items, markedItems) ? items : markedItems) : unmarkedItems);
      setHasMarkedItems(hasMarked);
    }
    else {
      setItems(_.isEqual(items, unmarkedItems) ? items : unmarkedItems);
      setHasMarkedItems(false);
    }
  }, [list, markText]);

  if (openOnMarked && !isOpen && hasMarkedItems) {
    isOpen = true;
  }

  return items.length && (
    <span ref={listRef} className="collapsible-collapsible">
      <span
        className={`top collapse-toggler ${isOpen ? "" : "collapsible-collapsed"}${hideToggle ? " hidden" : ""}`}
        onClick={onToggle}
        title={`${isOpen ? "collapse" : "show"} ${items.length} more results`}
      >
        &nbsp;{isOpen ? (<span className={`glyphicon glyphicon-chevron-up`} />) : (<span>+{items.length}&nbsp;more</span>)}
      </span>
      <Collapse in={isOpen} >
        <div ref={scrollerRef} className="collapsible-scroller">
          <ul className={`collapsed-list collapse-hidden-in${isActivities && " activities" || ""}`}>{
            items.map((item, item_idx) => (<li key={item_idx}>{item}</li>))
          }
          </ul>
        </div>
      </Collapse>
      <span
        className={`bottom collapse-toggler${isOpen ? "" : " collapsible-collapsed"}${!isOpen || hideToggle ? " hidden" : ""}`}
        onClick={onToggle}
        title={`${isOpen ? "collapse" : "show"} ${
          items.length
        } more results`}
      >
        {isOpen ? (<span className={`glyphicon glyphicon-chevron-up`} />) : (<span>{items.length}&nbsp;more</span>)}
      </span>
    </span>
  );
};

CollapsiblePart.propTypes = {
  list: PropTypes.array.isRequired,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  markText: PropTypes.func,
};

export default CollapsiblePart;
