import {useCallback, useEffect, useMemo, useState} from "react";
import {useEventHandlers} from "../../SigmaEdgeGraphLoader";

const DEFAULT_SETTINGS = {
  onSelectionStart: null,
  onSelectionChange: null,
  onSelectionEnd: null,
};

// Function used to check collision between a selection rectangle and the nodes as circles
// Circle = {x, y, r}
// Rectangle = {x, y, w, h}
const checkCollision = (circle, rect) => {
  const distX = Math.abs(circle.x - rect.x - rect.w / 2);
  const distY = Math.abs(circle.y - rect.y - rect.h / 2);

  if (distX > rect.w / 2 + circle.r) return false;
  if (distY > rect.h / 2 + circle.r) return false;

  if (distX <= rect.w / 2) return true;
  if (distY <= rect.h / 2) return true;

  const dx = distX - rect.w / 2;
  const dy = distY - rect.h / 2;

  return dx * dx + dy * dy <= circle.r * circle.r;
};

// The plugin function "enhancing" the given renderer
const useSelectionRectangle = (sigma, container, settings = {}) => {
  const {onSelectionStart, onSelectionChange, onSelectionEnd} = {...DEFAULT_SETTINGS, ...(settings || {})};

  const graph = sigma.getGraph();
  const camera = sigma.camera;
  const [maxNodeSize, setMaxNodeSize] = useState(-Infinity);
  const [selectionDiv, setSelectionDiv] = useState(null);
  const state = useMemo(() => ({
    isSelecting: false,
    xStart: 0,
    yStart: 0,
    xCurrent: 0,
    yCurrent: 0
  }), []);

  class SelectionState {
    get isActive() { return state.isSelecting; }
    get bounds() { return getSelectionRectangle(); }
    get selectedNodes() { return getSelectedNodes(); }
  }
  const selectionState = useMemo(() => new SelectionState(), [state]);

  useEffect(() => {
    const handlers = {
      selectionStart: onSelectionStart,
      selectionChange: onSelectionChange,
      selectionEnd: onSelectionEnd,
    }
    Object.entries(handlers).forEach(([event, handler]) => handler && sigma?.on(event, handler));
    return () => {
      Object.entries(handlers).forEach(([event, handler]) => handler && sigma?.off(event, handler));
    };
  }, [sigma, onSelectionStart, onSelectionChange, onSelectionEnd]);

  useEffect(() => {
    let max = 0;
    graph.forEachNode((node, attr) => {
      max = Math.max(attr.size, max);
    });
    setMaxNodeSize(max);
  }, [graph]);

  useEffect(() => {
    const div = document.createElement('div');
    div.className = "selection-rectangle";
    div.style.display = 'none';
    container.appendChild(div);
    setSelectionDiv(div);
    return () => {
      setSelectionDiv(null);
      container.removeChild(div);
    }
  }, [container]);

  const getSelectionRectangle = () => {
    const x1 = state.xStart;
    const y1 = state.yStart;

    const x2 = state.xCurrent;
    const y2 = state.yCurrent;

    const x3 = Math.min(x1, x2);
    const y3 = Math.min(y1, y2);

    const x4 = Math.max(x1, x2);
    const y4 = Math.max(y1, y2);

    return {
      x1: x1,
      y1: y1,
      x2: x2,
      y2: y2,
      x: x3,
      y: y3,
      width: x4 - x3,
      height: y4 - y3
    };
  };

  const updateSelectionElement = (element = null, displayStyle = null) => {
    if (element) {
      if (displayStyle) {
        element.style.display = displayStyle;
      }
      const rectangle = getSelectionRectangle();
      element.style.left = `${rectangle.x}px`;
      element.style.top = `${rectangle.y}px`;
      element.style.width = `${rectangle.width}px`;
      element.style.height = `${rectangle.height}px`;
    }
  };

  const offset = (el) => {
    const rect = el.getBoundingClientRect();

    return {
      top: rect.top + document.documentElement.scrollTop,
      left: rect.left + document.documentElement.scrollLeft
    };
  };

  const getEventX = (event) => {
    if (event.original) event = event.original;

    return event.pageX - offset(container).left;
  };

  const getEventY = (event) => {
    if (event.original) event = event.original;

    return event.pageY - offset(container).top;
  };

  const sizeRatio = Math.pow(camera.getState().ratio, 0.5); // NOTE: actual rendered size is divided by ratio

  const getSelectedNodes = (rectangle = null) => {
    if (rectangle == null) {
      rectangle = getSelectionRectangle();
    }
    let p1 = {x: rectangle.x - maxNodeSize, y: rectangle.y - maxNodeSize};
    let p2 = {x: p1.x + rectangle.width + maxNodeSize, y: p1.y};
    let h = {x: p1.x, y: p1.y + rectangle.height + maxNodeSize};

    p1 = sigma.viewportToGraph(p1);
    p2 = sigma.viewportToGraph(p2);
    h = sigma.viewportToGraph(h);
    h = p2.y - h.y;

    // Now we need to actually check collisions
    const collisionRectangle = {
      x: rectangle.x,
      y: rectangle.y,
      w: rectangle.width,
      h: rectangle.height
    };

    // .quadtree.rectangle(p1.x, 1 - p1.y, p2.x, 1 - p2.y, h)

    return graph.nodes()
      .filter(nodeID => {
        const attr = graph.getNodeAttributes(nodeID);
        const nodePosition = sigma.graphToViewport({x: attr.x, y: attr.y});
        const size = attr.size / sizeRatio;
        const collisionCircle = {
          r: size,
          x: nodePosition.x,
          y: nodePosition.y
        };
        return checkCollision(collisionCircle, collisionRectangle);
      });
  };

  const handleDownStage = useCallback(({event})=> {
    camera.disable();
    state.isSelecting = true;
    state.xStart = getEventX(event);
    state.yStart = getEventY(event);
    state.xCurrent = state.xStart;
    state.yCurrent = state.yStart;

    sigma.emit('selectionStart', {state: selectionState});
  }, [sigma, camera, selectionDiv]);

  const handleMouseUp = useCallback(() => {
    if (!state.isSelecting) return;

    camera.enable();
    state.isSelecting = false;
    if (selectionDiv) {
      selectionDiv.style.display = 'none';
    }

    sigma.emit('selectionEnd', {state: selectionState, nodes: getSelectedNodes()});
    // TODO: suppress click event? or have click handler ignore drag?

  }, [sigma, camera, selectionDiv, getSelectedNodes]);

  const handleMouseMove = useCallback((event) => {
    if (!state.isSelecting) return;

    state.xCurrent = getEventX(event);
    state.yCurrent = getEventY(event);

    updateSelectionElement(selectionDiv, 'block');

    sigma.emit('selectionChange', {state: selectionState, nodes: getSelectedNodes()});

  }, [sigma, selectionDiv, getSelectedNodes]);

  useEventHandlers({
                     downStage: handleDownStage,
                     mouseup: handleMouseUp,
                     mousemove: handleMouseMove
                   });
  useEffect(() => {
    selectionDiv && selectionDiv.addEventListener('mousemove', handleMouseMove);
    return () => selectionDiv && selectionDiv.removeEventListener('mousemove', handleMouseMove);
  }, [selectionDiv, handleMouseMove]);

  return selectionState;
}

export default useSelectionRectangle;
