import { getConfig } from "../lib/configMgr";
import { toJSON, RequestBuilder } from "./request";
import {TAXID_NONE} from "../stores/AppStatusStore";
import {fetchSearch} from "./search";

const { ExternalRESTAPI } = getConfig();

export const fetchEntities = (ids, dsConfig, auth) => {
  const url = new URL(ExternalRESTAPI.GetEntities, window.location.origin);
  if (dsConfig !== null) {
    url.searchParams.set('imap', dsConfig);
  }
  const req = new RequestBuilder(url);
  return req
    .asPOST(JSON.stringify(ids))
    .withJSONContentType()
    .withAuthorization(auth)
    .fetch()
    .then(toJSON);
};

export const fetchSuggestions = (searchTerms, category, taxID, dsConfig, auth) => {
  const url = new URL(ExternalRESTAPI.GetSuggestions, window.location.origin);
  // Remove square brackets with adjacent spaces (avoid modifying SMILES strings)
  const terms = typeof searchTerms === "string"
                ? [searchTerms.replace(/( \[ ?| ?] )/g, ' ')]
                : searchTerms;
  if (dsConfig !== null) {
    url.searchParams.set('imap', dsConfig || 0)
  }
  if (category) {
    url.searchParams.set("category", category);
  }
  if (taxID && taxID !== TAXID_NONE && terms.length > 1) {
    url.searchParams.set('tax_id', taxID);
  }
  const req = new RequestBuilder(url);

  // For testing, set this to a desired error condition
  //const cannedResponse = TypeError("Failed to fetch");
  //const cannedResponse = new Response(new Blob([JSON.stringify({})], {type: 'application/json'}),
  //                                   {status: 500, statusText: "Not OK"});
  return (
    req
      .asPOST(JSON.stringify(terms))
      .withJSONContentType()
      .withAuthorization(auth)
      .fetch() /* .fetch(0, 5000, cannedResponse */
      .then(toJSON)
  );
};

export const fetchChatCompletion = (searchParams, chatParams, auth) => {
    return fetchSearch({searchIDs: [], ...(searchParams || {}), ...chatParams}, auth, "chat");
};
