import React from "react";
import {capitalize} from "lodash";

const NotificationDetailsList = ({ title = "", list = [], className = null }) => {
  return (
    <div className={`message-details-list${className ? ' ' + className : ''}`} >
      <b>{title}</b>
      <ul className="message-details">
        {list.map((el, i) => (
          <li className="message-details-item" key={i}>{el}</li>
        ))}
      </ul>
    </div>
  );
};

export const Notification = ({ notification }) => (
  <div className={`log-message alert-${notification.type}`} >
    <p className="er-l-dlg-lbl-date">{new Date(notification.createdAt).toString()}</p>
    <p className="er-l-dlg-lbl-type">{capitalize(notification.type)}{notification.data?.title ? ` ${notification.data.title}` : ""}</p>
    <p className="er-l-dlg-lbl-msg">{notification.content}</p>
      {notification.data?.list?.length > 0 && (
          <div className="er-l-dlg-lbl-msg">
              <NotificationDetailsList
                list={typeof(notification?.data?.list) === "string"
                      ? notification?.data?.list.split("\n")
                      : notification?.data?.list}
              />
          </div>)}
  </div>
);

export default Notification;
