import { getConfig } from "../lib/configMgr";
import { toJSON, RequestBuilder } from "./request";

const { ExternalRESTAPI } = getConfig();

export const fetchCategoryMetadata = (auth, dsConfig = null) => {
  const params = dsConfig !== null ? `?imap=${dsConfig}` : "";
  const req = new RequestBuilder(`${ExternalRESTAPI.Categories}${params}`);

  return req
    .withAuthorization(auth)
    .fetch()
    .then(toJSON);
};

export const fetchDatasetMetadata = (auth, dsConfig = null) => {
  const params = dsConfig !== null ? `?imap=${dsConfig}` : "";
  const req = new RequestBuilder(`${ExternalRESTAPI.Datasets}${params}`);

  return req
    .withAuthorization(auth)
    .fetch()
    .then(toJSON);
};
