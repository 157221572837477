import ReactGA from "react-ga4";

/**
 * Abstraction of analytics functionality.
 * Track page views, events, performance, and exceptions.
 *
 * @module Analytics
 * @class
 * @internal
 */

let _debug = false;

const _CATEGORY = {
  SEARCH: "Search",
  INVESTIGATE: "Investigate",
  USER: "User"
};

const _DIMENSIONS = {
  USER_ID: "userId",
  TENANT_ID: "dimension1"
};

const _log = (...args) => {
  if (_debug) {
    console.log("[Analytics]", ...args);
  }
};

const _warn = (...args) => {
  if (_debug) {
    console.warn("[Analytics]", ...args);
  }
};

const _error = (...args) => {
  console.error("[Analytics]", ...args);
};

const _hashCode = (str) => {
  let hash = 0;
  if (str.length !== 0) {
    for (let i = 0; i < str.length; i++) {
      const ch = str.charCodeAt(i);
      hash = (hash << 5) - hash + ch;
      hash = hash & hash;
    }
  }
  if (hash < 0) {
    hash = 0xffffffff + hash + 1;
  }
  return ("00000000" + hash.toString(16).toUpperCase()).substring(-8);
};

const _extract_id = (str) => {
  if (str && str.indexOf("?id=") !== -1) {
    str = str.replace(/^[^?]+\?id=(.*)/, "$1");
  }
  return str;
};

const _redact = (str) => {
  if (str) {
    str = str.toString();
    const email = str.toLowerCase().match(/^([a-z0-9._%+-]+)@([a-z0-9.-]+)(\.[a-z]{2,4})$/);
    if (email) {
      str = email[1].substring(0, 1) + "***(AT)" + email[2].substring(0, 1) + "***" + email[3];
    }
    else {
      let notpath = str.match(/^(\/([^/]+)?)/);
      if (notpath) {
        str = notpath[0];
      }
      else {
        str = _extract_id(str);
        if (!str.match(/^ID::/)) {
          str = _hashCode(str);
        }
      }
    }
  }
  else{
    str = "";
  }
  return str.replace(/@/g, "(AT)");
};

export const initialize = (trackingID, history, options) => {
  if (!trackingID) {
    _warn("TrackingID is required");
    return;
  }

  _debug = options && options.debug;
  _log(trackingID);

  ReactGA.initialize(trackingID, {
    debug: false,
    titleCase: false,
    gaOptions: options && options.gaOptions
  });
  ReactGA.enable = options && options.enable;

  pageview(window.location.pathname);
  history.listen(function(location) {
    pageview(location.pathname);
  });
  if(_debug) {
    window.analytics = require('./Analytics.js');
  }
};

export const enable = (active) => {
  ReactGA.enable = active;
};

export const identify = (userID, tenantID) => {
  ReactGA.set({
    [_DIMENSIONS.USER_ID]: userID,
    [_DIMENSIONS.TENANT_ID]: tenantID
  });
};

export const pageview = (path) => {
  path = _redact(path);
  _log("pageview", path);
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export const event = (category, action, label, elapsed) => {
  if (elapsed !== undefined) {
    _log(category + ":" + action, label, elapsed + "ms");
  } else {
    _log(category + ":" + action, label);
  }
  if (ReactGA.enable) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: elapsed
    });
    if (elapsed !== undefined) {
      ReactGA.timing({
        category: category,
        variable: action,
        label: label,
        value: elapsed
      });
    }
  }
};

export const timing = (category, action, value, label, extra) => {
  if (ReactGA.enable) {
    let fields = {
      category: category,
      variable: action,
      value: value,
      label: label
    };
    for (let attrname in extra) {
      if (extra.hasOwnProperty(attrname)) {
        fields[attrname] = extra[attrname];
      }
    }
    ReactGA.timing(fields);
  }
};

export const exception = (description, fatal) => {
  // GA will convert any objects "[Object]", which isn't useful
  // Console output accepts objects
  // TODO: un-minify stack in production
  let descriptionWithStack = description.stack || Error(description).stack;
  _error("Error logged =>", descriptionWithStack);

  if (ReactGA.enable) {
    ReactGA.exception({
      description: descriptionWithStack,
      fatal: fatal
    })
  }
};

export const register = (username) => {
  event(_CATEGORY.USER, "register", _redact(username));
};

export const resendConfirmation = (username) => {
    event(_CATEGORY.USER, "resend-confirmation", _redact(username));
};

export const signin = (userID, tenantID) => {
  event(_CATEGORY.USER, "signin", userID);
  if (userID && tenantID) {
    identify(userID, tenantID);
  }
};

export const sessionRestore = (userID, tenantID) => {
  event(_CATEGORY.USER, "session-restore", userID);
  if (userID && tenantID) {
    identify(userID, tenantID);
  }
};

export const passwordReset = (userID) => {
  event(_CATEGORY.USER, "password-reset", _redact(userID));
};

export const passwordChange = (userID) => {
  event(_CATEGORY.USER, "password-change", _redact(userID));
};

export const signout = (userID) => {
  event(_CATEGORY.USER, "signout", _redact(userID));
  identify();
};

export const ketcherOpen = (smiles) => {
  event(_CATEGORY.SEARCH, "ketcher-open", _redact(smiles || ""));
};

export const ketcherSearch = (smiles) => {
  event(_CATEGORY.SEARCH, "ketcher-search", _redact(smiles));
};

export const autosuggest = (search_term, elapsed) => {
  event(_CATEGORY.SEARCH, "autosuggest", _redact(search_term), elapsed);
};

export const search = (search_term, elapsed) => {
  event(_CATEGORY.SEARCH, "search", _redact(search_term), elapsed);
};

export const echains = (start_id, end_id, elapsed) => {
  event(
    _CATEGORY.INVESTIGATE,
    "evidence-chains",
    start_id + "=>" + end_id,
    elapsed
  );
};

export const outboundLink = (args) => {
  if (ReactGA.enable) {
    ReactGA.outboundLink(args, () => {
    });
  }
};

export default {
  initialize,
  enable,
  identify,
  register,
  resendConfirmation,
  signin,
  sessionRestore,
  passwordReset,
  passwordChange,
  signout,
  ketcherOpen,
  ketcherSearch,
  search,
  autosuggest,
  echains,
  exception,
  pageview,
  outboundLink,
  log: _log,
  warn: _warn,
  warning: _warn,
  error: _error
};

