import React, {Component, Fragment} from "react";
import { Modal, Table } from "react-bootstrap";
import img_logo from "../../img/logo_circles_only.png";

import OutboundLink from "../OutboundLink";
import modalFactory from "./modalFactory";
import { inject, observer } from "mobx-react";

const getStyleFromVerComparison = (req = "", given = "") => {
  const [rMajor, rMinor, rParch] = req
    .split(".")
    .map(x => x.replace(/\D+/g, ""));
  const [gMajor, gMinor, gParch] = given
    .split(".")
    .map(x => x.replace(/\D+/g, ""));
  // "0.0.0" means version info is unavaliable
  if (!(parseInt(rMajor, 10) <= parseInt(gMajor, 10) || given === "0.0.0")) {
    return { color: "red" };
  }
  if (!(parseInt(rMinor, 10) <= parseInt(gMinor, 10))) {
    return { color: "orange" };
  }
  if (parseInt(rParch, 10) <= parseInt(gParch, 10)) {
    return { color: "green" };
  }
  return { color: "gray" };
};

const AboutPlexModal = (props) => {
  const {
    appStatusStore: {
      versionRequirements: {
        templates,
        frontend,
        backend,
        data,
      } = {},
      versionInfo: {
        tid: tenantID,
        domains,
        pool_id: userPoolID,
      } = { },
    },
    hideModal,
    isOpen,
    userStore: {
      isAdmin = false
    }
  } = props;
  const tenantDomains = domains ? domains.filter(x => x !== "plexresearch.com").join(", ") : "";
  return (
      <Modal
        show={isOpen}
        onHide={hideModal}
        dialogClassName="about-mdl-main"
      >
        <Modal.Body className="about-mdl-content">
          <div>
            <img src={img_logo} alt="logo" className="about-mdl-logo" />
            <div className="about-mdl-content-title">Plex Search</div>
            <div>
              <Table>
                <tbody>
                <tr>
                  <td>Version</td>
                  <td>{frontend.required}</td>
                </tr>
                <tr>
                  <td
                    style={getStyleFromVerComparison(
                      backend.required,
                      backend.given
                    )}
                  >
                    API
                  </td>
                  <td>{backend.required}</td>
                  <td>{backend.given}</td>
                </tr>
                <tr>
                  <td
                    style={getStyleFromVerComparison(
                      templates.required,
                      templates.given
                    )}
                  >
                    UI Templates
                  </td>
                  <td>{templates.required}</td>
                  <td>{templates.given}</td>
                </tr>
                <tr>
                  <td
                    style={getStyleFromVerComparison(
                      data.required,
                      data.given
                    )}
                  >
                    Datasets Release
                  </td>
                  <td>{data.required}</td>
                  <td>{data.given}</td>
                </tr>
                {isAdmin && (
                  <tr className="admin">
                    <td>pool ID</td>
                    <td>{userPoolID}</td>
                  </tr>)}
                {isAdmin && tenantID && (
                  <tr className="admin">
                    <td>Tenant ID</td>
                    <td>{tenantID}</td>
                  </tr>
                )}
                {tenantDomains !== "" && (
                  <tr>
                    <td>Domains</td>
                    <td>{tenantDomains}</td>
                  </tr>
                )}
                </tbody>
              </Table>
              <div className="about-mdl-content-vrs">
                © {new Date().getFullYear()}{" "}
                <OutboundLink href="https://plexresearch.com/">
                  Plex Research Inc
                </OutboundLink>
                <div>All rights reserved</div>
              </div>
              <div className="about-mdl-content-vrs">
                UIcons by <a href="https://www.flaticon.com/uicons">Flaticon</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
};

export default AboutPlexModal;

export const AboutPlexModalProvider = modalFactory(
  inject("appStatusStore", "userStore")(observer(AboutPlexModal))
);
