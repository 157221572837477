import React from "react";
import PropTypes from "prop-types";
import { checkOfflineState } from "./OfflineDetect";

const FilterButton = ({ item, type, onFilterSelection }) => {
  const tooltip =
    type === "dc"
      ? `${item.direct_connections} directly connected items`
      : type === "nbh"
      ? `Cluster of ${item.neighborhood_size} members`
      : "Unknown filter";

  const title =
    type === "dc"
      ? `Direct Connections (${item.direct_connections})`
      : type === "nbh"
      ? `Neighborhood ${item.neighborhood !== -1 ? item.neighborhood : "(isolated)"} (${item.neighborhood_size})`
      : "Unknown filter";

  const handleFilter = () => {
    onFilterSelection(type, item);
  };
  const disabled = item.direct_connections === 0 || item.neighborhood === -1;
  const className = `srch-rst-link${disabled ? " srch-rst-link-disabled" : ""}`;

  return (
    <button
      title={tooltip}
      onClick={disabled ? () => {} : checkOfflineState(handleFilter)}
      className={className}
    >
      {title}
    </button>
  );
};

FilterButton.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    direct_connections: PropTypes.number.isRequired,
    neighborhood: PropTypes.number.isRequired,
    neighborhood_size: PropTypes.number.isRequired
  }).isRequired,

  type: PropTypes.string.isRequired,
  onFilterSelection: PropTypes.func.isRequired
};

export default FilterButton;
