import React, {useState, useCallback} from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import modalFactory from "./modalFactory";
import { Notification } from "../Notification";

const NotificationCenter = ({ isOpen = false, hideModal, notifications = [], clearNotifications = () => {}}) => {
  const clear = useCallback(() => {
    clearNotifications();
    hideModal();
  });
  return (
    <Modal show={isOpen} onHide={hideModal} className="message-list">
      <Modal.Header closeButton>
        <Modal.Title><span>Messages</span>
          <button className={`flat-button glyphicon glyphicon-trash`} onClick={clear}></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notifications.map(notification => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </Modal.Body>
    </Modal>
  );
}

NotificationCenter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  messageList: PropTypes.arrayOf(PropTypes.object)
};

const MessageListModalProvider = modalFactory(NotificationCenter);

export default MessageListModalProvider;
