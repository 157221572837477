import {useEffect, useState} from "react";


export const useTypewriter = (props) => {
  const {delay = 50, initialText = '', initialOffset = 50, emitWords = true} = props || {};
  const [text, setText] = useState(initialText);
  const [displayText, setDisplayText] = useState(initialText.substring(0, initialOffset));
  const [isTyping, setIsTyping] = useState(text !== displayText);
  useEffect(() => {
    setIsTyping(text !== displayText);
  }, [text, displayText]);
  useEffect(() => {
    let offset = 0;
    const typingInterval = setInterval(() => {
      if (offset < text.length) {
        const ch = text.charAt(offset);
        if (!emitWords || ch === ' ') {
          setDisplayText(prevText => prevText + ch);
          ++offset;
        } else {
          const sub = text.substring(offset);
          const next = sub.indexOf(" ");
          if (next === -1) {
            setDisplayText(prevText => prevText + sub);
            offset += sub.length;
          } else {
            setDisplayText(prevText => prevText + sub.substring(0, next));
            offset += next;
          }
        }
      } else {
        clearInterval(typingInterval);
      }
    }, delay);
    return () => {
      clearInterval(typingInterval);
    }
  }, [text, delay]);

  return [
    displayText, (text) => {
      setText(text);
      setDisplayText('');
    }
  ];
}
