import {useEffect, useMemo, useRef, useState} from "react";
import debounce from "lodash/debounce";

export const useDebounce = (callback, delay = 400) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = callback;
  }, [callback])
  return useMemo(() => {
    const fn = () => ref.current?.();
    return debounce(fn, delay);
  }, [delay]);
};

export const useDebouncedValue = (value, wait = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(id);
  }, [value]);
  return debouncedValue;
};
