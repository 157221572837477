import OutboundLink from "./OutboundLink";
import SearchContext from "../lib/SearchContext";
import React from "react";

export const SubmitSearchButton = ({
  ids = [],
  dsConfig = 0,
  category = null,
  title = "Submit as new search",
  label = "Submit as new search",
  style = {},
  flat = true,
  disabled = false,
  className = "",
  onSearch = null,
  onClick = null,
}) => {
  const url = SearchContext.getAppSearchURL({searchIDs: ids, dsConfig}, {tab: category});
  const href = url?.href || "";
  if (!onClick) {
    onClick = onSearch ? () => onSearch() : () => {};
  }
  return (
    <OutboundLink
      className={`srch-new-search-link${flat ? " flat-button" : ""}${className !== "" ? " " + className : ""}`}
      title={title}
      href={href}
      style={style}
      disabled={disabled || (!url && !onSearch)}
      onClick={onClick}
    >
      <span className={`glyphicon glyphicon-search search-icon${disabled ? " disabled" : ""}`} onClick={onClick} />
      {label && label !== "" && (<span className={disabled ? "disabled" : null} onClick={onClick} >{label}</span>)}
    </OutboundLink>
  );
};

export default SubmitSearchButton;
