import React from "react";
import PropTypes from "prop-types";
import FilterButton from "../../FilterButton";
import {SubmitSearchButton} from "../../SubmitSearchButton";

const SearchResultFooter = ({
  item,
  onFilterSelection,
  currentCategory
}) => (
  <div className="srch-rst-links search-item-footer">
    <FilterButton item={item} type="dc" onFilterSelection={onFilterSelection} />
    <FilterButton item={item} type="nbh" onFilterSelection={onFilterSelection} />
    <SubmitSearchButton ids={[item.id]} dsConfig={item.dsConfig} category={currentCategory} />
  </div>
);

SearchResultFooter.propTypes = {
  onFilterSelection: PropTypes.func.isRequired,
  currentCategory: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired
};

export default SearchResultFooter;
