import React, {useCallback, useEffect, useState} from "react";
import {DELETE_MARKER} from "../../lib/utils";
import {DropdownButton} from "react-bootstrap";
import _ from "lodash";
import CopyToClipboardButton from "../CopyToClipboardButton";
import DropdownFilter from "../DropdownFilter";

const ResultsFilter = ({
  currentCategory,
  currentCategoryLabel,
  datasets = {},
  enableDSFilter = true,
  filteringDatasetKeys = null,
  filterString = "",
  getCategoryResultsClipboardText = () => "",
  includeQueryTerms = false,
  includeSalts = false,
  isAdmin = false,
  isDatasetsFilteringBusy = false,
  isFilterMatched = true,
  isFiltering = false,
  showWaiting = false,
  showLoading = false,
  displayedCount = null,
  onDatasetsFilterChange = () => {},
  onFilterStringChange = () => {},
  onIncludeQueryTermsChange = () => {},
  onIncludeSaltsChange = () => {},
  searchButton = null,
  showIncludeQueryTermsToggler = false,
  showIncludeSaltsToggler = false,
  totalCount = null,
}) => {
  const allDatasetKeys = new Set(Object.keys(datasets));
  const selectedDatasetKeys = new Set(filteringDatasetKeys?.length ? filteringDatasetKeys : allDatasetKeys);
  const [inputValue, setInputValue] = useState(filterString);

  const handleDatasetsFilterApply = (selected) => {
    onDatasetsFilterChange(selected.length === allDatasetKeys.size ? null : selected);
  };

  const handleDatasetsFilterClear = () => {
    onDatasetsFilterChange(null);
  }

  useEffect(() => {
    onFilterStringChange(inputValue);
  }, [inputValue, displayedCount]);

  const hideResultsFilter = showWaiting || totalCount === null || (totalCount === 0 && !showLoading);
  const reducedResults = isFiltering || !includeSalts;
  const verb = showLoading && !reducedResults ? "Loaded" : "Showing";
  const tally = `${displayedCount !== totalCount
                 ? displayedCount + " of"
                 : ""} ${totalCount !== null ? totalCount : '?'}`;
  const message = totalCount == null
    ? "Waiting for results..."
    : isDatasetsFilteringBusy
      ? "Filtering results by select datasets..."
      : totalCount === 0 && !showLoading
        ? "No Results"
        : `${verb} ${tally} ${currentCategoryLabel} results`;

  const isDSFilterActive = selectedDatasetKeys.size !== allDatasetKeys.size;
  const dsFilterMenuDisabled = isDatasetsFilteringBusy || !onDatasetsFilterChange || !enableDSFilter;
  const filterTitle = isDSFilterActive
                      ? `Filtering on ${Array.from(selectedDatasetKeys).map(x => datasets[x]?.name || x, []).join(", ")}`
                      : "Filter results by dataset"
  const waiting = "";
  const hasFilter = inputValue?.length;
  //const waiting = "Waiting for results...";

  // Even when hiding the results filter, always create its container to preserve layout
  return (
    <div className={`grid-filter${hideResultsFilter ? " undecorated" : ""}`}
         style={hideResultsFilter ? {"visibility": "hidden"} : {}}>
      <span className="filter-summary">{hideResultsFilter ? waiting : message}</span>
      <div className="grid-filter-input">
        <input
          key={currentCategory}
          placeholder="filter results..."
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === "Escape" && setInputValue("")}
          type="text"
          value={inputValue}
          className={isFilterMatched ? "" : "out-of-bounds"}
          title={isFilterMatched ? "" : "Nothing matched"}
        />
        <span
          className={`input-reset inside${hasFilter ? "" : " disabled"}`}
          title="Clear input"
          onClick={() => setInputValue("")}
          style={hasFilter && !hideResultsFilter ? {visibility: "visible"} : {}}>
          {DELETE_MARKER}
        </span>
        <button
          style={showIncludeQueryTermsToggler && !hideResultsFilter ? {} : {display: "none"}}
          className={`toggle-button search-terms-display-toggle${includeQueryTerms ? "" : " active"} glyphicon glyphicon-eye-${includeQueryTerms ? "close" : "open"}`}
          title={(includeQueryTerms ? "Hide" : "Show") + " query items"}
          onClick={() => onIncludeQueryTermsChange(!includeQueryTerms)}
        />
        <button
          style={showIncludeSaltsToggler && !hideResultsFilter ? {} : {display: "none"}}
          className={`salts-display-toggle${includeSalts ? " active" : ""}`}
          title={(includeSalts ? "Showing" : "Hiding") + " salts in results"}
          onClick={() => onIncludeSaltsChange(!includeSalts)}
        >Na⁺</button>
        <DropdownButton
          title={<span title={filterTitle} className="glyphicon glyphicon-filter" />}
          className={`dataset-options${isDSFilterActive ? " active" : ""}`}
          disabled={dsFilterMenuDisabled}
          align="end"
        >
          <DropdownFilter
            items={Object.values(datasets)}
            currentFilterValue={Object.values(datasets).filter(x => selectedDatasetKeys.has(x.id))}
            onFilterApply={handleDatasetsFilterApply}
            onFilterClear={handleDatasetsFilterClear}
            getKey={x => x.id}
            getLabel={x => x.name}
            getTitle={x => x.description || x.name}
            disabled={dsFilterMenuDisabled}
          />
        </DropdownButton>
        {searchButton}
        {isAdmin && (
          <CopyToClipboardButton
            className="admin"
            title="Copy this category results to clipboard"
            disabled={showLoading || displayedCount === 0}
            getText={getCategoryResultsClipboardText}
          />
        )}
      </div>
    </div>
  );
};

export default ResultsFilter;
