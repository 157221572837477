import React, {useEffect, useRef} from "react";
import {JSONEditor} from "vanilla-jsoneditor";
import "./styles.css";

export const Editor = ({data = {}, ...editorProps}) => {
  //console.log("Editor render data", data);
  const refContainer = useRef();
  const refEditor = useRef();
  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {mode: "text", content: typeof(data) === "string" ? {text: data} : {json: data}, ...editorProps},
    });
    return () => {
      refEditor.current?.destroy();
      refEditor.current = null;
    }
  }, []);
  useEffect(() => {
    const content = typeof(data) === "string" ? data : {json: data};
    //console.log("Update editor with", content);
    refEditor.current?.updateProps({content, ...editorProps});
  }, [data, editorProps]);
  return (<div className="jsoneditor-react" ref={refContainer} />);
};

export const ObjectEditor = ({ data, onChange }) => {
  return (
    <div>
      <h4>Data Object</h4>
      <Editor onChange={onChange} data={data} />
    </div>
  );
};

export default ObjectEditor;
