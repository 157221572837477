import {useDebounce} from "./useDebounce";
import {useEffect, useState} from "react";

export const useElementSize = (el) => {
  const [size, setSize] = useState({width: el?.clientWidth || 10, height: el?.clientHeight || 10});
  const onResize = useDebounce(() => setSize({width: el.clientWidth, height: el.clientHeight}), 250);
  useEffect(() => {
    if (!el?.clientWidth || !el?.clientHeight) return;
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(el);
    return () => resizeObserver.disconnect();
  }, [el])
  return size;
};
