import React, {useEffect, useState} from "react";
import {Collapse} from "react-bootstrap";

const CollapsibleText = ({ className = null, isOpen: controllerOpen = false, onToggle : controllerToggle = null, text = "", visibleChars = 160, minimumHidden = 80, markText = null }) => {

  const [isOpen, setIsOpen] = useState(controllerOpen);
  const onToggle = controllerToggle || (() => setIsOpen(!isOpen));
  let forceOpen = false;

  useEffect(() => {
    setIsOpen(controllerOpen);
  }, [controllerOpen, controllerToggle]);

  // Break at a space or end of line
  text = text.replace(/<br\/>/i, "\n").trim();
  if (text && /\w/.test(text[text.length-1])) {
    text += ".";
  }
  const lineCount = text.split("\n").length;
  while (visibleChars < text.length && !/\s/.test(text[visibleChars])) {
    ++visibleChars;
  }
  if (text.length - visibleChars < minimumHidden) {
    visibleChars = text.length;
  }
  let visibleText = text.substring(0, visibleChars);
  let hiddenText = text.length > visibleChars ? text.substring(visibleChars) : "";
  const isLineBroken = lineCount > 0 && text[visibleChars] !== "\n";
  const toggleDisplayStyle = {};
  if (markText) {
    hiddenText = hiddenText.replace(/(<mark[^>]*>|<\/mark>)/g, "");
    visibleText = markText(visibleText.replace(/(<mark[^>]*>|<\/mark>)/g, ""));
    const marked = markText(hiddenText);
    if (marked !== hiddenText) {
      forceOpen = true;
      hiddenText = marked;
      toggleDisplayStyle.display = "none";
    }
  }
  const splitLines = text => {
    if (typeof(text) === "string") {
      return text.split("\n")
        .filter(s => s !== "");
    }
    return text;
  };

  return (
    <span className={`collapsible-text collapsible${className ? ' ' + className : ''}`}>
      {text.trim().length === 0 ? null : (
        <>
          <span key="visible-part" className="collapsible-visible">{
            typeof(visibleText) === "string"
            ? splitLines(visibleText)
              .map((line, idx, array) => idx === array.length - 1 && isLineBroken
                                         ? (<span key={idx}>{line}</span>)
                                         : (<p key={idx}>{line}</p>))
            : visibleText
          }</span>
          {(hiddenText || "").length === 0 ? null : (
            <span key="hidden-part" className="collapsible-collapsible">
              {!(isOpen || forceOpen) && (<span>&nbsp;...</span>)}
              <Collapse in={isOpen || forceOpen} appear={true}>
                <span className="coll-text">{
                  typeof(hiddentText) === "string"
                  ? splitLines(hiddenText)
                    .map((line, idx, array) => idx === 0 && isLineBroken
                                               ? (<span key={idx}>{line}</span>)
                                               : (<p key={idx}>{line}</p>))
                  : hiddenText
                }</span>
              </Collapse>
              <span className={`collapse-toggler${isOpen || forceOpen ? "" : " collapsible-collapsed"}`}
                    style={toggleDisplayStyle}
                    onClick={onToggle}
              >
                &nbsp;<span title={isOpen || forceOpen ? "Show less" : "Show more"} className={`coll-text-tgl glyphicon glyphicon-chevron-${isOpen || forceOpen ? "up" : "down"}`} />
              </span>
            </span>
          )}
        </>
      )}
    </span>
);
};

export default CollapsibleText;
