import { getObjectProjection, createSample } from "../utils";
import {
  SR_Default,
  SR_Backend_Default,
  SR_Ovrd_Header,
  SR_Ovrd_Links,
  SR_Ovrd_CollapsibleText,
  SR_Ovrd_CollapsibleList
} from "./templates";
import { SAMPLE_COMPOUND, SAMPLE_COMPOUND_2 } from "../sample-data-objects";

export const SR_samples = [
  createSample(
    "Default item",
    SR_Default,
    getObjectProjection(SAMPLE_COMPOUND, ["entityID"])
  ),
  createSample("Extended item", SR_Backend_Default, SAMPLE_COMPOUND_2)
];

export const SR_Ext_samples = [
  createSample("Header link", SR_Ovrd_Header, SAMPLE_COMPOUND_2),
  createSample("Link horizontal list", SR_Ovrd_Links, SAMPLE_COMPOUND_2),
  createSample(
    "Collapsible text with title",
    SR_Ovrd_CollapsibleText,
    SAMPLE_COMPOUND_2
  ),
  createSample(
    "Collapsible vertical list with title",
    SR_Ovrd_CollapsibleList,
    SAMPLE_COMPOUND_2
  )
];
