import React from 'react'
import SpinnerAnimation from '../../../components/Spinner'

const Registration = ({
    username,
    password,
    passwordCheck,
    onRegister,
    onSwitchToSignin,
    onChange,
    onFocus,
    errors,
    formSubmitted
}) => (
    <div>
        <p>
            <input
                type         = "text"
                className    = { "login-control-username" + (errors.username ? " .error" : "") }
                autoComplete = "username"
                placeholder  = "Email address"
                value        = { username }
                onChange     = { onChange('username') }
                onFocus      = { onFocus('username', true) }
                onBlur       = { onFocus('username', false) }
                disabled     = { formSubmitted ? "disabled" : "" }
            />
        </p>
        <p>
            <input
                type         = "password"
                className    = "login-control-pass"
                autoComplete = "password"
                placeholder  = "Password"
                value        = { password }
                onChange     = { onChange('password') }
                onBlur       = { onFocus('password', false) }
                disabled     = { formSubmitted ? "disabled" : "" }
            />
        </p>
        <p>
            <input
                type         = "password"
                className    = "login-control-pass"
                autoComplete = "passwordCheck"
                placeholder  = "Confirm password"
                value        = { passwordCheck }
                onChange     = { onChange('passwordCheck') }
                onBlur       = { onFocus('passwordCheck', false) }
                disabled     = { formSubmitted ? "disabled" : "" }
            />
        </p>
        <p>
            <button autoFocus
                className = "login-submit"
                onClick   = { onRegister }
                disabled  = { (formSubmitted || errors.submit) ? "disabled" : "" }
            >
              {formSubmitted ? "Registering..." : "Register"}
              <SpinnerAnimation style={{ float: "right", display: formSubmitted ? "" : "none" }}/>
            </button>
        </p>
        <p className="signup-block">
            <button
                type = "button"
                className = "signup-btn"
                onMouseDown = { onSwitchToSignin }
            >
                Back to sign-in
            </button>
        </p>
    </div>
)

export default Registration;
