import React from "react";
import Collapsible from "../../collapsible";
import _, {isEqual} from "lodash";
import {withPropsChecker} from "../../../lib/utils";
//import withPropsChecker from "../../../../lib/utils";

export const MDCollapsibleText = ({ text, title = "", visibleCharNum = 200, minimumHidden = 100, markText = null, ...props }) => (
  <div className="srch-rst-desc">
    {title ? <span className="srch-rst-b-title">{markText ? markText(title) : title}</span> : null}
    <Collapsible
      type="text"
      item={`${text}`.trim()}
      visibleNum={visibleCharNum}
      minimumHidden={minimumHidden}
      markText={markText}
    />
  </div>
);

export const MDCollapsibleList = ({
  list = [],
  title = "",
  initVisibleNum = 3,
  minimumHidden = 2,
  markText = null,
  abbreviate = true,
  openIfContains = null,
}) => (list && list.length) ? (
  <div className="srch-rst-desc">
    {title ? <span className="srch-rst-b-title">{markText ? markText(title) : title}</span> : null}
    <Collapsible
      type="list"
      item={list}
      visibleNum={initVisibleNum}
      minimumHidden={minimumHidden}
      markText={markText}
      abbreviate={abbreviate}
      openIfContains={openIfContains}
    />
  </div>
) : null;
