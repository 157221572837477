import React, {createContext, useState} from "react";

const modalFactory = ModalComponent => {
  const ModalContext = createContext();
  class WrappedModalComponent extends React.Component {
    showModal = props => {
      this.setState({
        isOpen: true,
        modalProps: props
      });
    };

    hideModal = () => {
      this.setState({
        isOpen: false
      });
    };

    state = {
      isOpen: false,
      showModal: this.showModal,
      hideModal: this.hideModal,
      modalProps: {}
    };

    render() {
      const {children, ...modalProps} = this.props;
      return (
        <ModalContext.Provider value={this.state}>
          <ModalContext.Consumer>{children}</ModalContext.Consumer>
          <ModalContext.Consumer>
            {({ isOpen, hideModal }) => (
              <ModalComponent
                isOpen={isOpen}
                hideModal={hideModal}
                {...modalProps}
              />
            )}
          </ModalContext.Consumer>
        </ModalContext.Provider>
      );
    }
  }

  WrappedModalComponent.displayName = `modalFactory(${ModalComponent.displayName ||
    ModalComponent.name ||
    "Component"})`;

  return WrappedModalComponent;
};

export default modalFactory;
