import { runInAction, autorun, makeAutoObservable, toJS, observable } from "mobx";
import { isEqual } from "lodash";
import hash from "object-hash";
import { exportSearchResults, fetchSearch } from "../queries/search";
import { fetchSuggestions, fetchEntities } from "../queries/autosuggest";
import {NotAuthorized, SearchCanceled, SearchError, ServerError, ServerOfflineError} from "../queries/errors";
import { extractTemplates } from "../markdown/templates-utils";
import {TAXID_NONE} from "./AppStatusStore";
import SearchContext from "../lib/SearchContext";
import {normalize, softMatchRegExp, patchEntity, getBestName, saveCustomLabel, splitID} from "../lib/utils";
import {toast} from "react-toastify";

const DEFAULT_PAGE_SIZE = 500;
const DEFAULT_CATEGORY_KEY = "target";
const LOCAL_PARAMS = new Set(["tab", "filter", "queryEntities", "location", "q", "gtc"]);

export const MIN_FILTER_LENGTH = 2;

const CLEAR_FILTER_ON_TAB_CHANGE = false;
const IGNORE_KEYS = new Set(['rank', 'category', 'url', 'tags', 'links']);

// Predefined tab positions; anything not here gets tacked onto the end
const TAB_ORDINAL = {
  target: 1,
  compound: 2,
  pathway: 3,
  biomarker: 4,
  gds: 5,
  "proteomic-profile": 6,
  gwps: 7,
  orcs: 8,
  "cell-line": 9,
  disease: 10,
  publication: 11,
  patent: 12,
  org: 13,
  moa: 14,
  "genome-position": 15,
  motif: 16,
  // Never display these base categories
  geneset: -1,
  cpdset: -1,
  // Category for HMDB, not yet published
  mp: -1,
};

const filterPredicate = (pattern) => content => {
  const recursiveFilter = obj => {
    // Recursively examine aggregate objects
    if (obj === undefined || obj == null) {
      return false;
    }
    if (Array.isArray(obj)) {
      return obj.reduce((result, el) => result || recursiveFilter(el), false);
    }
    if (typeof(obj) === "object") {
      return Object.entries(obj).reduce((result, [key, value]) => {
        // Kind of a hack, ideally we'd check which fields are referenced in the markdown,
        // but that can be expensive if not precomputed.
        return result || (!IGNORE_KEYS.has(key) && recursiveFilter(value));
      }, false);
    }
    return pattern.test(normalize(obj.toString()));
  };
  return recursiveFilter(content);
};

class SearchSessionStore {

  _appStatusStore = null;
  _awaitingInitialResults = false;
  _tabs = [];
  _categories = {};
  _categoryTotals = new Map();
  _categoryResults = [];
  _clientSearchKey = null;
  _currentCategory = null;
  _chatContext = null;
  _datasets = {};
  _defaultSimThresholds = {};
  _dsConfigDatasets = {};
  _fetchingMetadata = false;
  _fetchingDsConfigMetadata = false;
  _filterCache = new Map();
  _filterString = "";
  _downloadedResults = new Map();
  _entities = {};
  _isFetchingMore = false;
  _isFilterMatched = false;
  _lastError = "";
  _metadata = null;
  _defaultSearchParams = {};
  _params = {};
  _preferredNames = {};
  _queryEntities = [];
  _queuedSearchParams = {};
  _resolvingTerms = [];
  _searchProgress = "";
  _searchResultAnnotation = "";
  _graphTargetCategory = null;
  _simThresholds = {};
  _templates = null;
  _userStore = null;

  constructor(userStore, appStatusStore, resultsBatchSize = DEFAULT_PAGE_SIZE) {
    makeAutoObservable(this);

    this.userStore = userStore;
    this.appStatusStore = appStatusStore;
    this.resultsBatchSize = resultsBatchSize;
    this.clear()

    autorun(async () => {
      // Run whenever one of these observable items changes
      if (Object.entries(this.datasets).length === 0
          && this.userStore.isAuthorized
          && this.authData) {
        this.appStatusStore.fetchMetadata()
          .then(({
                   default_search_params: {
                     find_related: findRelated = false,
                     imap: dsConfig = 0,
                     indirect = false,
                     min_expansion: minExpansion = null,
                     sim_type: simType = "sim",
                     sim_method: simMethod = "tanimoto",
                     sim_substructure: simSubstructure = false,
                     page_size: pageSize = 500,
                     xf = null,
                   } = {},
                   preferred_names: preferredNames = {},
                   sim_types_available: defaultSimTypeThresholds = {},
                 }) => {
            runInAction(() => {
              this.simThresholds = {...defaultSimTypeThresholds, ...this.simThresholds};
              this.defaultSearchParams = {
                dsConfig,
                findRelated,
                indirect,
                minExpansion,
                simType,
                simMethod,
                simSubstructure,
                pageSize,
                xf,
              };
              this.preferredNames = preferredNames || {};
              this.defaultSimThresholds = defaultSimTypeThresholds;
            })
          })
          .then(() => this.appStatusStore.fetchDatasetMetadata(this.authData)
            .then((datasets) => {
              runInAction(() => this.datasets = datasets);
            })
          )
          .catch((error) => {
            this.clear("Could not obtain backend info");
          })
      }
    });
    autorun(() => {
      // Run whenever one of these observable items changes
      if (this.dsConfig != null
          && this.metadata == null
          && userStore.isAuthorized
          && this.authData) {
        this.fetchDsConfigMetadata(this.dsConfig).then(() => {});
      }
    });
  }

  cancelSearch = () => {
    this.clear();
  };

  clear = (errorMessage = null) => {
    this.awaitingInitialResults = false;
    this.isFetchingMore = false;
    this._filterCache.clear();
    this.filterString = "";
    this.isFilterMatched = true;
    this.preferredNames = {};
    this.queuedSearchParams = {};
    this.searchProgress = "";
    this.resolvingTerms = [];
    this.gexf = null;
    this.graphml = null;
    if (errorMessage) {
      this.searchResultAnnotation = errorMessage;
      this.lastError = errorMessage;
      this.datasets = {};
      this.categories = {};
      this.dsConfigDatasets = {};
      this.metadata = null;
    }
    else {
      this.searchResultAnnotation = "";
      this.lastError = null;
      this.queryEntities = [];
      this.currentCategory = DEFAULT_CATEGORY_KEY;
      this.clientSearchKey = null;
      this.setParamsToDefault();
      this.categoryTotals.clear();
      this.downloadedResults.clear();
    }
  }

  setParamsToDefault = () => {
    this.params = {};
    this.awaitingInitialResults = false;
  };

  createSearchKey = (input, ts = new Date().getTime()) => hash({...input, ts: ts});

  get defaultSimThresholds() {
    return this._defaultSimThresholds;
  }

  set defaultSimThresholds(thresholds) {
    this._defaultSimThresholds = observable(thresholds);
  }

  get gexf() {
    return this._gexf;
  }

  set gexf(data) {
    this._gexf = data;
  }

  get graphml() {
    return this._graphml;
  }

  set graphml(data) {
    this._graphml = data;
  }

  get appStatusStore() {
    return this._appStatusStore;
  }

  set appStatusStore(s) {
    this._appStatusStore = s;
  }

  get fetchingMetadata() {
    return this._fetchingMetadata;
  }

  set fetchingMetadata(fetching) {
    this._fetchingMetadata = fetching;
  }

  get fetchingDsConfigMetadata() {
    return this._fetchingDsConfigMetadata;
  }

  set fetchingDsConfigMetadata(fetching) {
    this._fetchingDsConfigMetadata = fetching;
  }

  get categories() {
    return toJS(this._categories);
  }

  set categories(categories) {
    this._categories = categories;
  }

  getNodesMap = (ids) => {
    const nodes = new Map();
    const refs = new Set(ids);
    this.downloadedResults.forEach(catResults => {
      catResults
        .filter(e => refs.has(e.entity.id))
        .forEach(e => nodes.set(e.entity.id, e));
    });
    return nodes;
  }

  get tabs() {
    return this._tabs;
  }

  set tabs(t) {
    this._tabs = t;
  }

  get awaitingInitialResults() {
    return this._awaitingInitialResults;
  }

  set awaitingInitialResults(waiting) {
    this._awaitingInitialResults = waiting;
  }

  get simThresholds() {
    return this._simThresholds;
  }

  set simThresholds(e) {
    this._simThresholds = observable(e);
  }

  get searchProgress() {
    return this._searchProgress;
  }

  set searchProgress(s) {
    this._searchProgress = s;
  }

  get userStore() {
    return this._userStore;
  }

  set userStore(s) {
    this._userStore = s;
  }

  get defaultsLoaded() {
    return !isEqual(this.defaultSearchParams, {});
  }

  get dsConfig() {
    return this.paramsWithDefaults.dsConfig || 0;
  }

  get simType() {
    return this.paramsWithDefaults.simType;
  }

  get simThreshold() {
    return this.paramsWithDefaults.simThreshold;
  }

  get simMethod() {
    return this.paramsWithDefaults.simMethod;
  }

  get simSubstructure() {
    return !!this.paramsWithDefaults.simSubstructure;
  }

  get findRelated() {
    return !!this.paramsWithDefaults.findRelated;
  }

  get indirect() {
    return !!this.paramsWithDefaults.indirect;
  }

  get searchIDs() {
    return this.params.searchIDs || (this.params.queryEntities || []).map(qe => qe.id);
  }

  get resolvingTerms() {
    return this._resolvingTerms;
  }

  set resolvingTerms(terms) {
    this._resolvingTerms = terms;
  }

  get queryEntities() {
    return toJS(this._queryEntities);
  }

  set queryEntities(qe) {
    const unique = new Set();
    this._queryEntities = qe.filter(el => !unique.has(el.id) && unique.add(el.id));
  }

  get isFetchingMore() {
    return this._isFetchingMore;
  }

  set isFetchingMore(s) {
    this._isFetchingMore = s;
  }

  get preferredNames() {
    return this._preferredNames;
  }

  set preferredNames(pn) {
    this._preferredNames = pn;
  }

  get params() {
    return toJS(this._params);
  }

  set params(p) {
    this._params = p;
  }

  get defaultSearchParams() {
    return toJS(this._defaultSearchParams);
  }

  set defaultSearchParams(s) {
    this._defaultSearchParams = s;
  }

  get paramsWithDefaults() {
    if (!this.defaultsLoaded) {
      //console.warn("defaultSearchParams not yet set");
    }
    return {
      ...this.defaultSearchParams,
      ...this.params,
      queryEntities: this.queryEntities
    };
  }

  get queuedSearchParams() {
    // Compose search parameters based on parameters queued for the next search
    return {
      ...this.paramsWithDefaults, ...this._queuedSearchParams,
    };
  }

  set queuedSearchParams(p) {
    // Save in full form in case default settings need to override non-default settings
    this._queuedSearchParams = p;
  }

  modifySearchParameters = (newParams) => {
    this.queuedSearchParams = {...toJS(this.queuedSearchParams), ...toJS(newParams)};
  }

  get hasNewParams() {
    const current = this.params;
    const queued = this.canonicalParams(this.queuedSearchParams);
    //console.debug(`New params? ${!_.isEqual(current, queued)}\ncurrent: ${JSON.stringify(current)}\nqueued: ${JSON.stringify(queued)}`);
    return !_.isEqual(current, queued);
  }

  get metadata() {
    return toJS(this._metadata);
  }

  set metadata(m) {
    this._metadata = m;
  }

  get datasets() {
    return toJS(this._datasets);
  }

  set datasets(d) {
    this._datasets = d;
  }

  get dsConfigDatasets() {
    return toJS(this._dsConfigDatasets);
  }

  set dsConfigDatasets(d) {
    this._dsConfigDatasets = d || {};
  }

  get templates() {
    return this._templates;
  }

  set templates(t) {
    this._templates = t;
  }

  get searchResultAnnotation() {
    return this._searchResultAnnotation;
  }

  set searchResultAnnotation(s) {
    this._searchResultAnnotation = s;
  }

  get lastError() {
    return this._lastError;
  }

  set lastError(e) {
    if (e && e !== this.lastError) {
      toast.error(e, {toastId: e});
    }
    this._lastError = e;
  }

  get isFiltering() {
    return this.filterString.length >= MIN_FILTER_LENGTH;
  }

  get isFilterMatched() {
    return this._isFilterMatched;
  }

  set isFilterMatched(matched) {
    this._isFilterMatched = matched;
  }

  get chatContext() {
    return this._chatContext;
  }

  set chatContext(q) {
    this._chatContext = q;
  }

  get firstNonemptyCategory() {
    if (this.categoryTotals.size === 0) {
      return this.currentCategory || DEFAULT_CATEGORY_KEY;
    }
    for (let tab in this.tabs) {
      if (this.categoryTotals.get(tab.key) !== 0) {
        return tab.key;
      }
    }
    return null;
  }

  get graphTargetCategory() {
    return this._graphTargetCategory;
  }

  set graphTargetCategory(cat) {
    this._graphTargetCategory = cat;
  }

  get currentCategory() {
    return this._currentCategory;
  }

  set currentCategory(key) {
    let filterChanged = false;
    if (this.currentCategory !== key) {
      const lastCategory = this.currentCategory;
      this._currentCategory = key
      // Re-apply filter (or clear it), if any
      if (CLEAR_FILTER_ON_TAB_CHANGE) {
        filterChanged = true;
        this.filterString = "";
      }
      if (key === "graph") {
        if (lastCategory !== this.queryCategory) {
          this.graphTargetCategory = lastCategory;
        }
      }
      else {
        this.updateCategoryResults(filterChanged, true);
      }
    }
  }

  get downloadedResults() {
    return this._downloadedResults;
  }

  getEntityById = (id) => {
    return toJS(this.entities[id]);
  }

  get entities() {
    return this._entities;
  }

  set entities(e) {
    this._entities = e;
  }

  get authData() {
    return this.userStore.authData;
  }

  filterByDirectConnection = id => {
    this.runNewSearch({directConnectionID: id, tab: this.currentCategory});
  };

  filterByNeighborhood = neighborhood => {
    this.runNewSearch({neighborhood: neighborhood, tab: this.currentCategory});
  };

  // Are there more results to be obtained?
  get isLoading() {
    return this.awaitingInitialResults || this.isFetchingMore;
  }

  // Are there more results to be obtained for the current category?
  get isCategoryLoading() {
    const downloaded = this.downloadedResults.get(this.currentCategory)?.length || 0;
    const total = this.categoryTotals.get(this.currentCategory);
    return this.isLoading && downloaded < total;
  }

  get hasFullResults() {
    return this.searchIDs?.length && this.queryEntities?.length && !this.isLoading;
  }

  stringify = item => {
    return JSON.stringify(item);
  }

  _clipboardText = (category, filtered = false, idsOnly = false) => {
    // Mimic what's included in the CSV download
    const downloaded = (filtered ? this.categoryResults : this.downloadedResults.get(category)) || [];
    return downloaded.reduce((result, {entity, rank}) => {
      return idsOnly ? `${result}\n${entity.id}` : `${result}\n${rank}\t${entity.id}\t${getBestName(entity)}`;
    }, "");
  }

  get clipboardText() {
    if (this.isLoading) return "";
    return Array.from(this.downloadedResults.keys()).reduce((result, category) => {
      if (!this.categories[category]) {
        return result;
      }
      return `${result}\n${this.categories[category].name}\n${this._clipboardText(category)}`;
    }, "");
  }

  get categoryClipboardText() {
    return this.isLoading ? "" : this._clipboardText(this.currentCategory, true);
  }

  // Total number of available results for the current category
  get categoryResultsTotal() {
    return this.categoryTotals.get(this.currentCategory) || null;
  }

  get categoryTotals() {
    return this._categoryTotals;
  }

  set categoryTotals(totals) {
    this._categoryTotals = totals;
  }

  // Currently available current category results (filtered)
  get categoryResults() {
    return this._categoryResults;
  };

  set categoryResults(results) {
    this._categoryResults = results;
  }

  get categoryResultsLength() {
    return this._categoryResults.length;
  }

  nearestCache = str => {
    if (str.length >= MIN_FILTER_LENGTH) {
      // Put a cap on how long a substring we'll look at
      const lc = str.toLowerCase().substring(0, 16);
      return (this._filterCache.has(lc) && this._filterCache.get(lc))
        || this.nearestCache(lc.substring(0, lc.length - 1));
    }
    return null;
  };

  fetchEntities = async (ids) => {
    console.debug(`Fetch entities ${ids}`);
    if (this.templates == null) {
      await this.fetchDsConfigMetadata(this.dsConfig);
    }
    const response = await fetchEntities(ids, this.dsConfig, this.authData);
    return response.entities.map(entity => this.patchEntity(entity));
  };

  patchEntity = e => patchEntity(e, this.categories, this.datasets[e.dataset],
                                 this.templates, this.preferredNames, this.datasets);

  _extractLabel = term => {
    if (term.indexOf("label=") !== -1) {
      return [term.replace(/\s+label=.*$/, ""), term.substring(term.indexOf("label=") + "label=".length)];
    }
    return [term , null];
  };


  // Resolve multiple terms, on init or paste
  fetchSuggestions = async (terms, category = null, taxID = null) => {
    console.debug("Fetch suggestions for", terms);
    const {organismRestriction} = this.appStatusStore;
    const {dsConfig} = this.queuedSearchParams;
    if (taxID == null && organismRestriction !== TAXID_NONE) {
      taxID = organismRestriction;
    }
    const split = typeof(terms) === "string" ? terms.split(" ") : [];
    if (category == null && split.length === 2) {
      const prefix = split[1].toLowerCase();
      let count = 0;
      if (prefix.length > 2) {
        category = Object.entries(this.categories).reduce((result, [key, info]) => {
          if (key.startsWith(prefix) || info.name.toLowerCase().startsWith(prefix)) {
            count += 1;
            return key;
          }
          return result;
        }, null);
        if (category != null) {
          terms = split[0];
          if (count > 1) {
            category = null;
          }
        }
      }
    }
    const q = (typeof(terms) === "string" ? terms : terms.join("\n")).trim();
    // Hack to designate an LLM question to derive search terms
    if (q.endsWith("?")) {
      this.chatContext = q;
      this.modifySearchParameters({q: q})
    }
    const labels = [];
    if (terms.map) {
      terms = terms.map(term => {
        const [unlabeled, label] = this._extractLabel(term);
        labels.push(label);
        return unlabeled;
      });
    }
    else {
      const [unlabeled, label] = this._extractLabel(terms);
      labels.push(label);
      terms = unlabeled;
    }
    return fetchSuggestions(terms, category, taxID, dsConfig, this.authData)
      .then(suggestions => {
        suggestions.forEach((el, idx) => {
          if (el.entity) {
            // Clears any extant labels if no label was provided
            saveCustomLabel(el.id, idx < labels.length ? labels[idx] : null);
            el.entity = this.patchEntity(el.entity);
            el.entity['match-type'] = el['match-type'];
            el.entity['match-field'] = el['match-field'];
            el.entity['match-term'] = el['match-term'];
            el.entity['match-value'] = el['match-value'];
            el.entity['match-text'] = el['match-text'];
            el.entity['match-score'] = el['match-score'];
          }
        });
        // Ensure results are returned in the same order as the input terms
        if (typeof(terms) === "string" || terms.length === 1) {
          return suggestions;
        }
        const emap = suggestions.reduce((result, el) => {
          result[el['match-term']] = el;
          return result;
        }, {});
        return terms.map(term => emap[term]);
      });
  };

  _debouncedSetFilterString = _.debounce((input) => {
    const lower = input.toLowerCase();
    if (lower !== this.filterString) {
      runInAction(() => {
        this._filterString = lower;
        this.updateCategoryResults(true);
      });
    }
  }, 200);

  set filterString(input) {
    this._debouncedSetFilterString(input);
  }

  // Update current category results after a filter or data change
  updateCategoryResults = (filterChanged, dataChanged) => {

    const downloaded = this.downloadedResults.get(this.currentCategory) || [];
    let categoryResults = downloaded;
    let filterMatched = true;
    if (dataChanged) {
      this._filterCache.clear();
    }
    if (this.isFiltering) {
      if (!dataChanged && !filterChanged) {
        console.debug("updateCategoryResults: ignore update");
        return;
      }
      const filterString = this.filterString.trimEnd();
      const unfiltered = this.nearestCache(filterString) || downloaded;
      const pattern = softMatchRegExp(filterString, "i");
      const filtered = unfiltered.filter(filterPredicate(pattern));
      const terms = filterString.startsWith(" ")
        ? [this.filterString] : filterString.split(/\s+/).filter(x => x.length > 0);
      const fullMatches = new Set(filtered.map(item => item.id));
      // If multiple terms are provided, find additional results containing ALL terms
      if (terms.length > 1) {
        const discontiguousMatches = unfiltered.filter(item => !fullMatches.has(item.id))
          .reduce((result, item) => {
          if (!fullMatches.has(item.id)
            && _.every(terms, x => filterPredicate(softMatchRegExp(x))(item))) {
            result.push(item);
          }
          return result;
        }, []);
        filtered.push(...discontiguousMatches);
        filtered.sort((a, b) => b.rank - a.rank);
      }

      this._filterCache.set(filterString, filtered);
      if (filtered.length > 0) {
        console.debug(`Set filtered category results (${filtered.length})`);
        categoryResults = filtered;
        filterMatched = true;
      } else if (downloaded.length !== 0) {
        // Restore full results if no filter match
        console.debug("Set unmatched filtered category results", this.currentCategory);
        categoryResults = downloaded;
        filterMatched = false;
      }
    }
    else {
      //console.debug("Use unfiltered category results")
    }
    this.categoryResults = categoryResults;
    this.isFilterMatched = filterMatched;
  };

  get filterString() {
    return this._filterString;
  };

  get clientSearchKey() {
    return this._clientSearchKey;
  }

  set clientSearchKey(key) {
    this._clientSearchKey = key;
  }

  isNewLocation = (location) => {
    const urlParams = this.canonicalParams(this.fromLocation(location));
    const currentParams = this.params;
    return !_.isEqual(urlParams, currentParams);
  }

  runNewSearch = (newParams = null) => {
    if (newParams == null) {
      newParams = this.queuedSearchParams;
    }
    const fromURL = newParams.location;
    if (newParams.location) {
      // Load params from URL
      newParams = this.fromLocation(newParams.location);
    }
    else {
      if (newParams.queryEntities) {
        newParams.searchIDs = newParams.queryEntities.map(el => el.id).sort();
      }
      else {
        newParams.queryEntities = [];
      }
      newParams = {...this.queuedSearchParams, ...newParams};
    }
    if (!_.isEqual(this.searchIDs, newParams.searchIDs)) {
      delete newParams.directConnectionID;
      delete newParams.neighborhood;
    }
    // Discard current results or anything currently running
    this.cancelSearch();
    const {searchIDs = [], queryEntities = []} = newParams;
    if (searchIDs.length === 0) {
      if (queryEntities.length === 0) {
        throw new SearchError("No search IDs provided");
      }
      newParams.searchIDs = queryEntities.map(e => e.id).sort();
    }
    if (newParams.tab) {
      this.currentCategory = newParams.tab;
      delete newParams.tab;
    }
    if (newParams.q) {
      this.chatContext = newParams.q;
    }
    if (newParams.gtc) {
      // Transient setting, used only for initialization from URLs
      this.graphTargetCategory = newParams.gtc;
      delete newParams.gtc;
    }

    // Update params before performing any asynchronous actions to ensure searchURL returns
    // the proper values
    this.params = this.canonicalParams(newParams);
    this.awaitingInitialResults = true;
    this._runNewSearch(newParams, fromURL)
      .catch(error => {
        if (!(error instanceof SearchCanceled)) {
          if (error instanceof NotAuthorized) {
            console.error("Unable to restore session information during search");
          } else {
            console.error(`Search failed (${error.constructor.name})`, error);
            if (error instanceof ServerOfflineError) {
              this.appStatusStore.serverOffline = true;
            }
            const errorMessage = error instanceof ServerOfflineError
                         ? "The Plex Search service is unavailable\nPlease try again later"
                         : error instanceof ServerError
                ? error.message
                : error.status
                  ? error.statusText ? `${error.statusText} (${error.status})`
                    : `Unexpected error (${error.status})`
                  : `${error}`;
            this.clear(errorMessage);
            toast.error(errorMessage, {toastId: `${errorMessage}`});
          }
        }
      });
  }

  get searchURL() {
    return SearchContext.getAppSearchURL(this.params, this.localParams);
  }

  get queuedSearchURL() {
    return SearchContext.getAppSearchURL(this.queuedSearchParams, this.localParams);
  }

  get localParams() {
    const localParams = this.currentCategory ? {tab: this.currentCategory} : {};
    if (this.isFiltering) {
      localParams.filter = this.filterString;
    }
    if (this.chatContext) {
      localParams.q = this.chatContext;
    }
    if (this.graphTargetCategory) {
      localParams.gtc = this.graphTargetCategory;
    }
    return localParams;
  }

  fromLocation = (location) => {
    try {
      const params = SearchContext.parseQueryStringParams(location.search, location.hash);
      return {...this.defaultSearchParams, ...params, location};
    }
    catch (error) {
      this.lastError = `Invalid URL: ${error}`;
      return {...this.defaultSearchParams};
    }
  }

  get queryCategory() {
    return this.queryEntities?.length > 0 ? this.queryEntities[0].baseCategory : null;
  }

  searchSimKey = (params) => {
    if ((params.simType && params.simType !== "default") || params.simType === "") {
      return params.simType;
    }
    const {queryEntities: qe = []} = params;
    if (qe.length === 0) {
      return null;
    }
    const {searchIDs: ids = qe.map(el => el.id)} = params;
    if (ids.length === 0) {
      return null;
    }
    const searchCategory = qe?.length > 0 ? qe[0].baseCategory : null;
    if (searchCategory === "compound") {
      return "sim";
    }
    if (new Set(["cpdset", "geneset"]).has(searchCategory)) {
      return "set";
    }
    if (searchCategory === "org") {
      return "org";
    }
    return "";
  }

  canonicalParams = (params, fromURL = false) => {
    // Remove all parameters whose value matches the server default
    // or those that should not be passed to the server

    // Avoid modifying the original object
    params = toJS({...this.defaultSearchParams, ...params});
    if (params.queryEntities?.length) {
      params.searchIDs = Array.from(new Set(params.queryEntities.map(x => x.id))).sort();
    }
    else {
      params.searchIDs = Array.from(new Set(params.searchIDs)).sort();
    }

    const defaults = new Set(["org", "set", "sim"]);
    const simKey = this.searchSimKey(params);
    if (defaults.has(simKey) || params.simType === "default") {
      delete params.simType;
    }
    if (simKey) {
      // When initializing from URL, save the sim threshold setting from the URL (if any)
      if (fromURL) {
        if (params.simThreshold) {
          this.simThresholds[simKey] = params.simThreshold;
        }
      }
      else {
        // minExpansion overrides a custom simThreshold setting
        params.simThreshold = params.minExpansion ? this.defaultSimThresholds[simKey] : this.simThresholds[simKey];
        // Remove the parameter if it's at its default value
        if (params.simThreshold === this.defaultSimThresholds[simKey]) {
          delete params.simThreshold;
        }
      }
    }
    return Object.entries(params)
      .filter(([key, value]) => !isEqual(value, this.defaultSearchParams[key]))
      .filter(([key, value]) => !LOCAL_PARAMS.has(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  }

  _runNewSearch = async (nextParams, fromURL = false) => {
    const queryEntities = nextParams.queryEntities || [];
    if (queryEntities.length === 0) {
      runInAction(() => this.resolvingTerms = nextParams.searchIDs);
      const resolved = await this.fetchEntities(nextParams.searchIDs);
      runInAction(() => this.resolvingTerms = []);
      queryEntities.push(...resolved);
      nextParams.queryEntities = queryEntities;
      nextParams.searchIDs = queryEntities.map(el => el.id);
    }
    if (nextParams.dsConfig !== this.dsConfig || this.dsConfig == null) {
      this.fetchDsConfigMetadata(nextParams.dsConfig).then(() => {});
    }
    const params = this.canonicalParams(nextParams, fromURL);
    const key = this.createSearchKey(params);
    if (key === this.clientSearchKey) {
      console.debug("Search already in progress");
      return;
    }
    runInAction(() => {
      // Update params again, in case we're initializing from a URL, in which case the defaultParams may not have
      // been loaded before canonicalParams was applied
      this.params = params;
      this.queryEntities = queryEntities;
      this.isFetchingMore = true;
      this.clientSearchKey = key;
      console.debug(`New search URL ${this.searchURL}`);
    });
    return this.fetchSearchResults(key, params)
      .finally(() => {
        if (key === this.clientSearchKey) {
          runInAction(() => this.isFetchingMore = false);
        }
      });
  }

  exportSearchResultsAsFormat = async (format, categories=[]) => {
    const params = {...this.params, full: true, categories };
    return exportSearchResults(params, this.authData, format);
  };

  exportEvidenceChains = async (targetID) => {
    const searchParams = { ...this.params, targetID: targetID, exportFormat: "xlsx" };
    return exportSearchResults(searchParams, this.authData, "xlsx");
  };

  fetchMoreResults = async (
      clientSearchKey,
      pageToken = null
  ) => {
    if (clientSearchKey !== this.clientSearchKey) {
      console.debug(`Discard obsolete results ${clientSearchKey} (${this._clientSearchKey})`)
      return;
    }
    const response = await fetchSearch(
      {
        ...this.params,
        full: true,
        pageToken: pageToken
      },
      this.authData,
      "search",
      () => clientSearchKey !== this.clientSearchKey,
    );
    const {pageToken: nextPageToken, results, totals = {}} = response;
    if (results) {
      this.handleIncrementalSearchResults(results, totals);
      if (!!nextPageToken) {
        return this.fetchMoreResults(clientSearchKey, nextPageToken);
      }
    }
    else {
      throw new Error(`Incremental search results missing from response (${JSON.stringify(response)}`);
    }
  };

  handleIncrementalSearchResults = (batch, totals = null) => {
    if (totals) {
      this.categoryTotals = new Map(Object.entries(totals || {}));
      this.currentCategory = this.currentCategory;
    }
    const dsConfig = this.dsConfig;
    const dl = this.downloadedResults;
    if (dl.size === 0) {
      Object.entries(batch).forEach(([cat, results]) => {
        dl.set(cat, observable(results.map((el, idx) => {
          el.ordinal = idx + 1;
          el.dsConfig = dsConfig;
          el.entity = this.patchEntity(el.entity);
          this.entities[el.entity.id] = el.entity;
          return el;
        }), {deep: false}));
        if (cat === this.currentCategory) {
          this.updateCategoryResults(false, true);
        }
      });
    } else {
      Object.entries(batch).forEach(([cat, results]) => {
        if (results.length !== 0) {
          const downloaded = dl.get(cat);
          const base_idx = downloaded.length;
          results.forEach((el, idx) => {
            el.ordinal = base_idx + idx + 1;
            el.dsConfig = dsConfig;
            el.entity = this.patchEntity(el.entity);
            this.entities[el.entity.id] = el.entity;
          });
          downloaded.push(...results);
          if (cat === this.currentCategory) {
            this.updateCategoryResults(false, true);
          }
        }
      });
    }
  };

  fetchGraph = async (targetCategory, searchParams = null) => {
    searchParams = (searchParams || this.params);
    this.graphTargetCategory = targetCategory;
    return fetchSearch({...searchParams, targetCategory}, this.authData, "graph")
      .then(({graph}) => {
        return {graph, targetCategory, queryCategory: this.queryCategory}
      });
  }

  fetchSearchResults = async (clientSearchKey, params) => {
    try {
      const response = await fetchSearch(
        {
          ...this.canonicalParams(params),
          full: true,
          pageToken: null,
        },
        this.authData,
        "search",
        () => this.clientSearchKey !== clientSearchKey,
        (progress, total, info) => console.log(`Search progress ${progress}/${total} ${info}`)
      );
      const {pageToken, results, totals = {}} = response;
      if (this.clientSearchKey !== clientSearchKey) {
        console.debug("Ignore stale search results");
        return;
      }
      if (results) {
        runInAction(() => {
          this.awaitingInitialResults = false;
          this.handleIncrementalSearchResults(results, totals);
        });
        if (!!pageToken) {
          return this.fetchMoreResults(clientSearchKey, pageToken);
        }
      }
      else {
        console.warn("No incremental search results in response", response);
      }
    } catch (error) {
      // Ignore errors from obsolete searches
      if (this.clientSearchKey === clientSearchKey) {
        throw error;
      }
    }
  };

  fetchDsConfigMetadata = async (dsConfig) => {
    if (this.fetchingDsConfigMetadata) {
      return this.fetchingDsConfigMetadata;
    }
    return this.fetchingDsConfigMetadata = this.appStatusStore
      .fetchDatasetMetadata(this.authData, dsConfig).then(datasets => {
          runInAction(() => this.dsConfigDatasets = datasets);
          return this.appStatusStore.fetchCategoryMetadata(this.authData, dsConfig).then(md => {
            this.updateMetadata(md);
          })
        })
      .catch(error => {
        console.warn("Metadata initialization failed");
        this.clear("Could not obtain graph configuration info");
      })
      .finally(() => runInAction(() => this.fetchingDsConfigMetadata = false));
  };

  updateMetadata = (md) => {
    this.metadata = md;
    this.templates = extractTemplates(md);
    // filter non-category entries such as _templates, _default
    this.categories = Object.keys(md)
      .filter(key => !key.startsWith("_"))
      .reduce((acc, key) => {
        acc[key] = md[key];
        return acc;
      }, {});
    const {isAdmin} = this.userStore;
    const tabs = [
      {
        key: "graph",
        title: "",
        description: "Search results graph",
        name: "graph",
        className: "fi fi-rs-network-analytic"
      }
    ];
    tabs.push(...Object.entries(this.categories)
      .reduce((acc, [key, value], idx) => {
        if (TAB_ORDINAL[key] !== -1) {
          acc.push({
                     key: key,
                     title: value.tab_name,
                     description: value.description || value.tab_name,
                     name: value.name,
                     ordinal: TAB_ORDINAL[key] || (Object.keys(TAB_ORDINAL).length + idx),
                   });
        }
        return acc;
      }, [])
      .sort((a, b) => a.ordinal - b.ordinal));
    this.tabs = tabs;
  };
}

export default SearchSessionStore;
