import React from "react";
import PropTypes from "prop-types";
import { outboundLink } from "../lib/Analytics";

const OutboundLink = ({
  id = null,
  href,
  title = null,
  style = null,
  className = null,
  targetBlank = true,
  onClick = f => f,
  children,
  trackLink = true,
  disabled = false,
}) => {
  className = className ? `${className}${disabled ? " disabled" : ""}` : disabled ? "disabled" : null;
  return (href && <a
    {...(id && { id })}
    target={targetBlank ? "_blank" : null}
    rel="noopener noreferrer"
    title={title}
    href={href}
    style={style}
    className={className}
    onClick={e => {
      if (!disabled) {
        if (trackLink) {
          outboundLink({label: href}, () => {
          });
        }
        onClick(e);
      }
    }}
  >
    {children}
  </a>)
    || <span {...(id && {id })} title={title} className={className}>{children}</span>;
};

OutboundLink.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  targetBlank: PropTypes.bool,
  trackLink: PropTypes.bool
};

export default OutboundLink;
