import { getObjectProjection, createSample } from "../utils";
import { SAMPLE_PUBLICATION, SAMPLE_COMPOUND } from "../sample-data-objects";

const STR_UpperCase = `
## upper
### String uppercasing

{title}

{title | upper}
`;

const STR_LowerCase = `
## lower
### String lower casing

{title}

{title | lower}
`;

const STR_Prefix = `
## prefix
### String prefix

{category}

{category | prefix=00000...}
`;

const STR_suffix = `
## suffix
### String suffix

{category}

{category | suffix=...00000}
`;

const STR_noprefix = `
## noprefix
### Remove substring until a specified character

{id}

{id | noprefix=:}
`;

const Number_Fixed = `
## fixed
### Number rounding

{rank}

{rank | fixed=2}
`;

export const Modifiers_samples = [
  createSample(
    "String: uppercasing",
    STR_UpperCase,
    getObjectProjection(SAMPLE_PUBLICATION, ["title"])
  ),
  createSample(
    "String: lowercasing",
    STR_LowerCase,
    getObjectProjection(SAMPLE_PUBLICATION, ["title"])
  ),
  createSample(
    "String: prefix",
    STR_Prefix,
    getObjectProjection(SAMPLE_PUBLICATION, ["category"])
  ),
  createSample(
    "String: suffix",
    STR_suffix,
    getObjectProjection(SAMPLE_PUBLICATION, ["category"])
  ),
  createSample(
    "String: remove prefix",
    STR_noprefix,
    getObjectProjection(SAMPLE_COMPOUND, ["id"])
  ),
  createSample(
    "Number: rounding",
    Number_Fixed,
    getObjectProjection(SAMPLE_COMPOUND, ["rank"])
  )
];
