import {deburr, escapeRegExp} from "lodash";
import React, {Component, Profiler} from "react";
import {generateExternalLinks} from "./links";
import {toast} from "react-toastify";
import {interpolateEntityFields} from "../markdown/interpolation";

// Misc utilities with no better home ATM
const RE_UNICODE_PUNCTUATION = /[\u2000-\u206F\u2E00-\u2E7F\u00B4']/g;
//const UNICODE_PUNCTUATION_RE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%()*+,\-./:;=?@[\]^_`{|}~]/g;
export const DELETE_MARKER = "ⓧ";
export const NBSP = "\u00A0";

export const normalize = text => (
  //text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  deburr(text)
);

export const markMatchingText = (text, textToMatch, asString = false, attrs = {}) => {
  try {
    if (typeof (text) !== 'string') {
      return text;
    }
    const words = textToMatch.split(/\s/);
    const base = softMatchRegExp(textToMatch, 'gi');
    // In addition to matching the full textToMatch, match individual words within that string (if any)
    const regex = words.length === 1
      ? base
      : RegExp(words.reduce((result, word) =>
                              word.length > 0 ? result + "|" + softMatchRegExp(word, 'gi').source : result,
                            base.source), 'gi');
    const normalized = normalize(text);
    const output = [];
    let offset = 0;
    let match;
    while ((match = regex.exec(normalized)) !== null) {
      const unmatched = text.substring(offset, match.index);
      output.push(unmatched);
      const matched = text.substring(match.index, match.index + match[0].length);
      output.push(mark(matched, asString, {key: offset}));
      offset = regex.lastIndex;
    }
    if (offset < text.length) {
      output.push(text.substring(offset));
    }
    return asString ? output.join("") : output.length > 1 ? output : output[0];
  }
  catch(e) {
    console.warn(`markMatchingText failed applying ${textToMatch} to ${text}`);
  }
  return text;
}

export const mark = (text, asString = false, attrs = {}) => {
  if (asString) {
    const sattrs = Object.entries(attrs).reduce((result, [key, value]) => (
      `${result} ${key}="${value}"`
    ), "");
    return `<mark ${sattrs}>${text}</mark>`;
  }
  return (<mark {...attrs}>{text}</mark>);
};

export const isDataset = (text, datasets) => {
  return new Set(datasets).has(text);
};

export const softMatchRegExp = (text, flags = 'gi') => {
  const MARKER = "__PUNCTUATIONHERE__";
  const REGEXP_MARKER = new RegExp(MARKER, 'g');
  const reString = escapeRegExp(text.replace(RE_UNICODE_PUNCTUATION, MARKER)).replace(REGEXP_MARKER, ".");
  return new RegExp(`${normalize(reString)}`, flags);
};

export const decodeHTMLEntities = text => text.replace(/&amp;/g, "&");

export const elideString = (str, len, end = "...") => {
  return str.length >= len + end.length ? str.substring(0, len) + end : str;
};

const PREFERRED_ALIAS_FORMATS = [
  // Single words, no digits
  /^[^\d\W]+$/,
  // with dashes (no inchi)
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[^\d\W]([^\d\W]|-)+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)([^\d\W]|-)+$/,
  // with spaces or commas
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[^\d\W]([^\d\W]|[-\s,])+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)([^\d\W]|[-\s,])+$/,
  // with parens/brackets/plus
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[^\d\W]([^\d\W]|[-+\s,()\][])+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)([^\d\W]|[-+\s,()\][])+$/,
  // with numbers
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)\D[-\w+\s,()\][]+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[-\w+\s,()\][]+$/,
  // preferred datasets if no other matches
  // /^(CHEMBL\d+)$/,
  /^(CHEMBL|CID)\d+$/,
  // anything else
  /^(.*)$/,
]

export const countNonwordChars = s => {
  return s.replace(/([^\d\W]|[-\s])/g, "").length;
};

export const countDigits = s => {
  return s.replace(/\D/g, "").length;
};

export const getBestName = (entity) => {
  if (entity.entity) {
    entity = entity.entity;
  }
  if (entity.label) {
    return entity.label;
  }
  if (entity.title) {
    return entity.title;
  }
  if (entity.category === 'target' && entity.gene_symbol) {
    return `${entity.gene_symbol} (no title)`;
  }
  const aliases = (entity.aliases || []).slice();
  if (aliases.length > 0) {
    for (let fmt of PREFERRED_ALIAS_FORMATS) {
      const candidates = []
      aliases.forEach(alias => {
        if (alias.match(fmt)) {
          candidates.push(alias);
        }
      });
      if (candidates.length > 0) {
        const sorted = candidates.sort((a, b) => {
          // Prefer CHEMBL > CID > SCHEMBL/BDB/HMDB > UCI
          if (/^(CHEMBL|CID)/i.test(a) && /^(CHEMBL|CID)/i.test(b)) {
            return a - b;
          }
          const delta = countNonwordChars(a) - countNonwordChars(b);
          const delta2 = countDigits(a) - countDigits(b);
          return delta + delta2;
        });
        return `${sorted[0]} (no title)`;
      }
    }
  }
  if (entity.iupac) {
    return `${entity.iupac} (no title)`;
  }
  return null;
};

export const saveBlob = (filename, blobContent) => {
  try {
    const blob = new Blob([blobContent], { type: blobContent.type });
    const url = window.URL.createObjectURL(blob);

    // navigator.msSaveOrOpenBlob - available for IE only
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.setAttribute("style", "display: none");
      a.setAttribute("target", "_black");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error(error);
  }
};

export const safeDOMID = id => {
  return id?.replace(/[^-A-Z0-9_]/gi, "-").replace(/-{2,}/, "-");
};

export const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

const RE_PREFIXED_ID = /^[-a-z]+:.*$/;

export const getCategoryKey = (dsid) => {
  return RE_PREFIXED_ID.test(dsid) && dsid.split(':')[0];
};

export const getDatasetID = id => {
  return (RE_PREFIXED_ID.test(id) && id.slice(id.indexOf(':') + 1)) || id;
}

export const saveCustomLabel = (id, label) => {
  const key = `label-${id}`;
  if (label) {
    sessionStorage[key] = label;
  }
  else {
    sessionStorage.removeItem(key);
  }
};

export const getCustomLabel = id => {
  return sessionStorage[`label-${id}`];
};

export const patchEntity = (entity, categories, dataset, templates, preferredNames={}, datasets = null) => {
  if (entity['_patched']) {
    return entity;
  }
  entity['_patched'] = true;
  if (entity.tags && entity.tags[0] !== entity.category) {
    entity.baseCategory = entity.category;
    entity.category = entity.tags[0] || entity.category;
  }
  else {
    entity.baseCategory = entity.category;
  }
  const cat = categories[entity.category];
  if (!cat && entity.category !== 'archived' && Object.keys(categories).length > 0) {
    console.error(`No category corresponding to '${entity.category}':`, categories);
  }
  // Singular
  entity.categoryName = (cat && cat.name) || entity.category;
  // Plural (e.g. tab name)
  entity.categoryLabel = (cat && cat.tab_name) || entity.categoryName;

  if (typeof (entity.aliases) === 'string') {
    entity.aliases = entity.aliases.split(';')
  }
  // Keep only a single SMILES encoding
  if (entity.smiles && typeof (entity.smiles) !== "string") {
    entity.smiles = entity.smiles[0];
  }

  const prefName = preferredNames[entity.id];
  if (prefName) {
    entity['preferred-name'] = prefName;
  }
  // correct for some GPSA data errors (temporary, remove after data-1.3.1)
  if (entity['direction'] === "down" && entity['id'].indexOf("UP") !== -1) {
    entity['direction'] = "up";
  }

  const cleanTitle = (t) => {
    return t ? t.replace(/;(?!=\s)/g, "; ")
      .replace(/^\[(.*)]$/, "$1") : t;
  } ;

  // Clean up titles for readability
  entity.title = cleanTitle(entity.title);
  entity.iupac = cleanTitle(entity.iupac);
  entity['best-alias'] = cleanTitle(getBestName(entity));
  // Decode and interpolate properties
  entity = interpolateEntityFields(templates, entity, dataset);

  // Convert to a list to facilitate display
  if (entity.activity) {
    entity.activity = Object.entries(entity.activity);
  }

  if (datasets) {
    entity.links = generateExternalLinks(entity, datasets);
  }

  Object.entries(entity).forEach(([k, v]) => {
    if (isTruthy(v)) {
      if (!entity.flags) {
        entity.flags = [k];
      }
      else {
        entity.flags.push(k);
      }
    }
  })

  const label = getCustomLabel(entity.id);
  if (label && label !== entity.label) {
    entity.label = entity.label ? `[${label}] ${entity.label}` : label;
  }
  return entity;
};

export const withProfiler = (WrappedComponent) => (props) => (<Profiler><WrappedComponent {...props} /></Profiler>);

// Wrap a component in this to debug when properties change
export function withPropsChecker(WrappedComponent, defaultName= "?") {
  return class PropsChecker extends Component {
    componentDidUpdate(lastProps) {
      Object.keys(lastProps)
        .filter(key => {
          return lastProps[key] !== this.props[key];
        })
        .forEach(key => {
          const name = WrappedComponent.displayName || WrappedComponent.name || defaultName;
          console.debug(`changed property "${key}" on ${name} from`, lastProps[key], 'to', this.props[key]);
        });
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export const copyToClipboard = (text) => {
  const dataTypes = {
    "text/plain": new Blob([text], {type: 'text/plain'}),
  };
  if (text.indexOf("\t") !== -1) {
    // Not all systems support this and you don't find out until clipboard write
    //dataTypes["text/tab-separated-values"] = new Blob([text], {type: 'text/tab-separated-values'});
  }
  const item = new ClipboardItem(dataTypes);
  navigator?.clipboard?.write([item]).then(() => {})
    .then(() => {})
    .catch((error) => {
      toast.error(`Clipboard write is unavailable (${error})`);
    });
}

export const naturalSort = (a, b) => {
  if (!a) {
    return !b ? 0 : 1;
  }
  return a.localeCompare(b, undefined, {numeric: true, ignorePunctuation: true, sensitivity: "base"});
};

export const RE_PREFIXED = /^([^:]+):.*$/i;
export const RE_INT_ID = /^[^:]+:(\d+)$/i;
export const RE_DATASET_ID = /^([-a-z0-9]+):(.*)$/;

export const splitID = id => [datasetPrefix(id), getDatasetID(id)];

export const stripDataset = id => RE_DATASET_ID.test(id) ? id.replace(RE_DATASET_ID, '$2') : id;

export const datasetPrefix = id => RE_PREFIXED.test(id) && id.replace(RE_PREFIXED, '$1');

export const isInteger = (s) => {
  return Number.isInteger(+(typeof s === 'string' ? s.trim() : s));
};

// TODO: move title setting to Helmet (once helmet errors get fixed)
export const setPageTitle = (basis = "Plex Search") => {
  if (typeof(basis) === "string") {
    document.title = basis;
  }
  else if (basis?.length) {
    const MAX_NAMES = 3
    const queryEntities = basis;
    const name = getBestName(queryEntities[0]);
    if (queryEntities.length > MAX_NAMES) {
      document.title = `${name} and ${queryEntities.length - 1} others`;
    } else if (queryEntities.length > 1) {
      document.title = `${queryEntities.map(x => getBestName(x)).join(", ")}`;
    } else {
      document.title = `${name}`;
    }
  } else {
    document.title = "Plex Search";
  }
};

const TRUTHY = new Set(["y", "yes", "true", "t"]);

export const isTruthy = (s) => {
  return s === true || (typeof(s) === "string" && TRUTHY.has(s.toLowerCase()));
}

