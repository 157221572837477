import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import { Modal } from "react-bootstrap";
import { checkOfflineState } from "./OfflineDetect";
import SubmitSearchButton from "./SubmitSearchButton";
import { getConfig } from "../lib/configMgr";
import {useInterval} from "../hooks/useInterval";

const { ExternalRESTAPI } = getConfig();

export const isQueryPattern = (smiles = "") => {
  return /(\[H]|\*| \|\$[^$]*\$\|)/.test(smiles);
}


const MolecularEditorModal = ({onSearchFromEditor, onAddTerm, onReplaceTerm, showEditor, onCloseEditor,
                                encoding : input = "", substructureQuery = false}) => {
  const inputEncoding = showEditor ? (input || "").replace(/^(smiles|query):/, "") : "";
  const inputIsQuery = input.startsWith("query:") || substructureQuery;
  //console.debug(`modal received '${input}' => ${inputEncoding}`);
  const [ketcher, setKetcher] = useState(null);
  const [initialEditorStructure, setInitialEditorStructure] = useState(null);
  const [editorStructure, setEditorStructure] = useState(null);
  const [isQuery, setIsQuery] = useState(inputIsQuery);
  const [currentSMILES, setCurrentSMILES] = useState(inputEncoding);
  const [isModified, setIsModified] = useState(false);
  const timerRef = useRef();

  const hasInput = inputEncoding.length > 0;

  const toJSON = s => {
    const replacer = (key, value) => {
      if (value?.entries) {
        return Object.fromEntries(value.entries());
      }
      return value;
    }
    return JSON.stringify(s, replacer);
  }

  // Apparently no observer events on the editor, so poll :(
  useInterval(() => {
    const s = showEditor && ketcher && ketcher.editor.struct();
    if (s?.atoms?.size) {
      const json = toJSON(s);
      if (json !== editorStructure) {
        setEditorStructure(json);
        if (initialEditorStructure == null) {
          setInitialEditorStructure(json);
        }
        setIsQuery(isQueryMolecule(s) || substructureQuery);
      }
    }
    else {
      setEditorStructure(null);
    }
  }, showEditor && ketcher ? 200 : null);

  useEffect(() => {
    //console.debug(`Update structure => ${editorStructure}`);
    if (!(editorStructure && initialEditorStructure)) {
      //console.debug("structure empty");
      setCurrentSMILES("");
      setIsQuery(substructureQuery);
      setIsModified(false);
    }
    else if (editorStructure === initialEditorStructure && inputEncoding && inputEncoding !== "") {
      console.debug("No change from initial");
      setCurrentSMILES(inputEncoding || "");
      setIsQuery(inputIsQuery || isQueryPattern(inputEncoding));
      setIsModified(false);
    }
    else {
      // Only look up SMILES value if the editor structure has actually changed
      console.debug("Look up smiles");
      fetchQueryTerm((term, isQuery, smiles) => {
        console.debug(`smiles => ${smiles}`);
        setCurrentSMILES(smiles);
        setIsQuery(isQuery || isQueryPattern(smiles));
        setIsModified(true);
      });
    }
  }, [editorStructure, initialEditorStructure, inputIsQuery]);

  useEffect(() => {
    if (ketcher) {
      if (!showEditor) {
        console.debug("Clear on close");
        timerRef.current = null;
        ketcher.setMolecule(null);
        setKetcher(null);
        setEditorStructure(null);
        setInitialEditorStructure(null);
        setCurrentSMILES("");
      }
    }
  }, [showEditor, ketcher])

  const handleClose = () => {
    onCloseEditor();
  };

  const fetchQueryTerm = (callback = (term, isQuery, smiles) => {}) => {
    // Fetch the current SMILES value from the editor
    const isQuery = isQueryMolecule() || substructureQuery;
    ketcher.getSmiles(true)
      .then(smiles => {
        const isQuerySMILES = isQueryPattern(smiles);
        const term = `${isQuery || isQuerySMILES ? "query" : "smiles"}:${smiles}`;
        console.debug(`Fetched '${smiles}' => '${term}'`);
        callback(term, isQuery || isQuerySMILES, smiles);
        setIsQuery(isQuery || isQuerySMILES);
    })
      // Ignore, we'll retry later
      .catch(error => {console.error("fetchQueryTerm error", error)});
  };

  const handleAddTerms = () => {
    handleClose();
    fetchQueryTerm(onAddTerm);
  }

  const handleReplaceTerms = () => {
    handleClose();
    fetchQueryTerm(onReplaceTerm);
  }

  const handleSubmitSearch = () => {
    handleClose();
    fetchQueryTerm(onSearchFromEditor);
  };

  const onIFrameLoad = () => loadKetcher();

  useEffect(() => {
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [showEditor])

  const isQueryMolecule = (s) => {
    const structure = s || ketcher?.editor?.struct();
    if (!structure) {
      return false;
    }
    if (structure.isReaction || structure.rgroups.size || structure.rgroupAttachmentPoints.size) {
      return true;
    }
    //console.log(`Check ${toJSON(structure)} for query`);
    return structure.atoms.keys().some((atomID) => {
      const atom = structure.atoms.get(atomID);
      if (!atom) {
        return false;
      }
      // Look for anything that might make this a query
      if (atom.pseudo
          || atom.queryProperties?.customQuery
          || atom.attachmentPoints
          || atom.explicitValence !== -1
          || atom.rxnFragmentType !== -1
          || atom.atomList) {
        return true;
      }
      // Look for query atoms
      return (atom.isQuery() || Array.from(atom.sgs.values()).some((sGroupID) => {
        const sGroup = structure.sgroups.get(sGroupID);
        return sGroup && sGroup.type === "queryComponent";
      }));
    });
  };

  const loadKetcher = () => {
    const iframe = document.querySelector('iframe.mol-editor');
    const ko = iframe?.contentWindow?.ketcher || window?.frames['iframe-ketcher']?.window?.ketcher;
    if (!(ko && ko.editor)) {
      timerRef.current = setTimeout(loadKetcher, 200);
    }
    else {
      timerRef.current = null;
      // Copied loosely from Ketcher.isQueryComponentSelected
      setKetcher(ko);
      if (inputEncoding && inputEncoding !== "") {
        // NOTE: ketcher structure value is not immediately available for read :(
        console.debug(`ketcher.setMolecule '${inputEncoding}'`);
        ko.setMolecule(inputEncoding);
      }
      setCurrentSMILES(inputEncoding);
    }
  };

  return (
    <Modal
      show={showEditor}
      onHide={() => onCloseEditor(currentSMILES)}
      size="lg"
      dialogClassName="ketcher-wrp-mdl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Molecular Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Iframe
          id={"iframe-ketcher"}
          url={ExternalRESTAPI.Ketcher}
          className={"mol-editor"}
          width="850px"
          height="570px"
          display="initial"
          position="relative"
          onLoad={onIFrameLoad}
          allowFullScreen={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="smiles-list">
          {currentSMILES && currentSMILES !== ""
           ? (
             <div className="mol-editor-smiles">
               {currentSMILES} {isQuery ? (<span className={"sub"}>(substructure query)</span>) : ''}
             </div>
           )
           : ("No molecule defined")}
        </div>
        <button
          className="btn"
          onClick={handleReplaceTerms}
          disabled={!currentSMILES || !isModified || !hasInput}
          title="Replace term with new structure">
          Replace
        </button>
        <button
          className="btn"
          onClick={handleAddTerms}
          disabled={!currentSMILES || !isModified}
          title="Add new structure to search terms">
          Add
        </button>
        <SubmitSearchButton
          className="btn mol-editor-submit-search"
          label="Search"
          flat={false}
          disabled={!currentSMILES}
          onSearch={checkOfflineState(handleSubmitSearch)}
        />
      </Modal.Footer>
    </Modal>
  );
};

MolecularEditorModal.propTypes = {
  onSearchFromEditor: PropTypes.func
};

export default MolecularEditorModal;
