import { getObjectProjection, createSample } from "../utils";
import {
  QD_Default,
  QD_Compound,
  QD_Target,
  QD_Ext_Entity,
  QD_Ext_CollList
} from "./templates";
import { SAMPLE_COMPOUND, SAMPLE_TARGET, jq1_for_query_pane } from "../sample-data-objects";

export const QD_samples = [
  createSample(
    "Default item",
    QD_Default,
    getObjectProjection(SAMPLE_COMPOUND, ["id"])
  ),
  createSample(
    "Compound item",
    QD_Compound,
    getObjectProjection(jq1_for_query_pane)
  ),
  createSample(
    "Target item",
    QD_Target,
    getObjectProjection(SAMPLE_TARGET)
  )
];

export const QD_Extended_samples = [
  createSample(
    "Entity",
    QD_Ext_Entity,
    getObjectProjection(SAMPLE_COMPOUND, ["title"])
  ),
  createSample(
    "CollapsibleList",
    QD_Ext_CollList,
    getObjectProjection(SAMPLE_COMPOUND, ["aliases"])
  )
];
