import React, {useRef} from "react";
import GridRow from "./grid-row/GridRow";
import {useVirtualizer} from "@tanstack/react-virtual";
import LoadingAnimation from "../LoadingAnimation";

const GridViewport = ({
    queryIDs = [],
    currentCategory = null,
    items = [],
    itemsTotal = -1,
    templates,
    markText,
    isLoading,
    onScroll,
    onShowEvidence,
    onFilterSelection,
  }) => {
  const scrollerRef = useRef();
  const virtual = useVirtualizer({
    count: isLoading ? items.length + 1 : items.length,
    getScrollElement: () => scrollerRef?.current,
    estimateSize: () => 150,
    /*overscan: 20,*/
  }, [scrollerRef, isLoading, items]);

  const qids = new Set(queryIDs);
  // This subset represents the visible items in the virtualizer
  const virtualItems = virtual.getVirtualItems();
  const start = virtualItems.length > 0 && virtualItems[0].start || 0;
  //const measure = {};
  const measure = {ref: (node, entry) => {
      return node != null ? virtual.measureElement(node, entry) : 0;
    }};

  return (
      <div
          ref={scrollerRef}
          className="grid-viewport"
          onScroll={onScroll}
      >
        <div
            className="grid-virtualized"
            style={{height: virtual.getTotalSize()}}
        >
          <div
              className="grid-virtualized-content"
              style={{transform: `translateY(${start}px)`}}
          >
            {virtualItems.map((virtualRow) => {
              const isLoadingElement = virtualRow.index === items.length;
              const item = isLoadingElement ? {id: "loading"} : items[virtualRow.index];
              return (
                <div
                  {...measure}
                  key={`virtual-${item.id}`}
                  data-index={virtualRow.index}
                  className="grid-viewport-row"
                >
                  {isLoadingElement
                    ? (<LoadingAnimation />)
                    : (<GridRow
                        className="grid-view-row"
                        total={itemsTotal}
                        item={item}
                        isQueryTerm={qids.has(item.id)}
                        onShowEvidence={onShowEvidence}
                        onFilterSelection={onFilterSelection}
                        currentCategory={currentCategory}
                        templates={templates}
                        markText={markText}
                      />)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
  );
}

//export default observer(GridViewport);
export default GridViewport;
