import Sandbox from "./Sandbox";
import {
  AS_samples,
  AS_Extended_samples
} from "../../markdown/autosuggest/samples";
import { overrideMap as overrideMapAS } from "../../markdown/autosuggest/overrides";

import {
  QD_samples,
  QD_Extended_samples
} from "../../markdown/query-summary/samples";
import { overrideMapSample as overrideMapQD } from "../../markdown/query-summary/overrides";

import {
  SR_samples,
  SR_Ext_samples
} from "../../markdown/search-results/samples";
import { overrideMapSample as overrideMapSR } from "../../markdown/search-results/overrides";

import { Modifiers_samples } from "../../markdown/modifiers/samples";
import { overrideMapSample as overrideMapMod } from "../../markdown/modifiers/overrides";

const createSpec = (key, samples, overrides, additionalSamples) => ({
  key: key,
  samples: samples,
  additionalSamples: additionalSamples,
  overrides: overrides
});

export const MarkdownSandbox = Sandbox(
  createSpec()
);

export const AutosuggestSandbox = Sandbox(
  createSpec("as", AS_samples, overrideMapAS, AS_Extended_samples)
);
export const QueryDetailsSandbox = Sandbox(
  createSpec("qd", QD_samples, overrideMapQD, QD_Extended_samples)
);

export const SearchResultSandbox = Sandbox(
  createSpec("sr", SR_samples, overrideMapSR, SR_Ext_samples)
);

export const ModifiersSandbox = Sandbox(
  createSpec("mod", Modifiers_samples, overrideMapMod)
);
