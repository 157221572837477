import UserStore from "./UserStore";
import SearchSessionStore from "./SearchSessionStore";
import EChainStore from "./EChainStore";
import AppStatusStore from "./AppStatusStore";
import { getConfig } from "../lib/configMgr";

const RESULTS_BATCH_SIZE = getConfig().resultsBatchSize || 500;
const appStatusStore = new AppStatusStore();
const userStore = new UserStore(appStatusStore);
const searchSessionStore = new SearchSessionStore(
  userStore,
  appStatusStore,
  RESULTS_BATCH_SIZE,
);
const eChainStore = new EChainStore(userStore, appStatusStore, searchSessionStore);

export const rootStore = {
  userStore,
  searchSessionStore,
  eChainStore,
  appStatusStore
};
