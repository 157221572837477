import React from "react";

const QuerySummaryItem = ({
  displayName = "Loading...",
  markText = null,
  onClick,
  selected = false,
}) => (
  <li className={`long-text ${selected ? " selected" : ""}`} onClick={onClick}>
    {markText ? markText(displayName) : displayName}
  </li>
);

export default QuerySummaryItem;
