export const SAMPLE_COMPOUND = {
  aliases: [
    "Danamep",
    "Anadin All Night",
    "1268524-70-4",
    "ST24036414",
    "(+)-JQ1",
    ">=98% (HPLC)",
    "(+)-JQ-1",
    "(S)-JQ1"
  ],
  category: "compound",
  dataset_id: "161671",
  description:
    "Aspirin, also known as acetylsalicylic acid (ASA), is a medication used to treat pain, fever, or inflammation",
  id: "unichem:161671",
  ordinal: 1,
  rank: "1.22342342",
  source: {
    url: "https://www.ebi.ac.uk/chembl/compound/inspect/CHEMBL25",
    name: "Chembl"
  },
  isQueryTerm: true,
  links: [
    {
      name: "EntrezGene",
      url: "https://www.ncbi.nlm.nih.gov/gene/5742"
    },
    {
      name: "Ensembl",
      url: "http://www.ensembl.org/id/ENSG00000095303"
    },
    {
      name: "UniProt",
      url: "http://www.uniprot.org/uniprot/P23219"
    }
  ],
  inchi: "InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)",
  inchikey: "BSYNRYMUTXBXSQ-UHFFFAOYSA-N",
  indication_class: "Analgesic; Antirheumatic; Antipyretic",
  smiles: "CC(=O)Oc1ccccc1C(=O)O",
  source_id: "chembl:CHEMBL25",
  title: "ASPIRIN"
};

export const SAMPLE_PATENT = {
  abstract:
    "The present invention encompasses compounds of general formula (I) wherein the groups R1 to R4 and A1 to A5 have the meanings given in the claims and in the specification. The compounds of the invention are suitable for the treatment of diseases characterized by excessive or abnormal cell proliferation pharmaceutical preparations containing such compounds and their uses as a medicament",
  aliases: ["WO2014154762", "WO2014154762A1"],
  category: "patent",
  currentAssignees: [],
  dataset_id: "WO2014154762",
  description: "DIHYDROQUINAZOLINONE ANALOGUES AS BRD4 INHIBITORS",
  filingDate: "2014-03-26 00:00:00",
  id: "patent:WO2014154762",
  inventors: [
    "Christian Smethurst",
    "Harald Engelhardt",
    "Davide GIANNI",
    "Ulrich Reiser"
  ],
  originalAssignees: ["Boehringer Ingelheim International Gmbh"],
  plex_suggest: [],
  publicationDate: "2014-10-02 00:00:00",
  source_id: "patent:WO2014154762",
  title: "Dihydroquinazolinone analogues as brd4 inhibitors",
  versions: ["A1"]
};

export const SAMPLE_PUBLICATION = {
  category: "publication",
  citation:
    "Meng S, Zhang L, Tang Y, Tu Q, Zheng L, Yu L, Murray D, Cheng J, Kim SH, Zhou X, Chen J : J Dent Res. 2014 Jul;93(7):657-62. doi: 10.1177/0022034514534261. Epub 2014 May 5",
  dataset_id: "24799421",
  id: "pubmed:24799421",
  plex_suggest: [],
  source_id: "pubmed:24799421",
  title: "BET Inhibitor JQ1 Blocks Inflammation and Bone Destruction"
};

export const SAMPLE_TARGET = {
  accession: "O60885",
  aliases: [
    "BRD4",
    "BROMODOMAIN-CONTAINING PROTEIN 4",
    "HUNK1",
    "PROTEIN HUNK1"
  ],
  category: "target",
  dataset_id: "23476",
  description:
    "bromodomain containing 4;bromodomain-containing protein 4;chromosome-associated protein",
  gene_aliases: [
    "entrez_gene:23476",
    "HGNC:HGNC:13575",
    "Vega:OTTHUMG00000183252",
    "uniprot:O60885",
    "ensembl:ENSG00000141867",
    "MIM:608749"
  ],
  gene_symbol: "BRD4",
  id: "entrez_gene:23476",
  organism: "Homo sapiens",
  plex_match: "entrez_gene:23476",
  plex_suggest: [
    "BRD4",
    "BROMODOMAIN-CONTAINING PROTEIN 4",
    "ENSEMBL:ENSG00000141867",
    "ENTREZ_GENE:23476",
    "HGNC:HGNC:13575",
    "HUNK1",
    "MIM:608749",
    "PROTEIN HUNK1",
    "UNIPROT:O60885",
    "VEGA:OTTHUMG00000183252"
  ],
  source_dataset_id: "23476",
  source_id: "entrez_gene:23476",
  taxonomy_id: "9606",
  title: "Bromodomain-containing protein 4",
  type: "SINGLE PROTEIN"
};

export const SAMPLE_COMPOUND_2 = {
  direct_connections: 277,
  entity: {
    aliases: [
      "4flp",
      "BB 0262647",
      "SYN3004",
      "CHEBI:137113",
      "JQ-1",
      "MolPort-027-946-817"
    ],
    category: "compound",
    dataset_id: "27249705",
    id: "unichem:27249705",
    inchi:
      "InChI=1S/C23H25ClN4O2S/c1-12-13(2)31-22-19(12)20(15-7-9-16(24)10-8-15)25-17(11-18(29)30-23(4,5)6)21-27-26-14(3)28(21)22/h7-10,17H,11H2,1-6H3/t17-/m0/s1",
    inchikey: "DNVXATUJJDPFDM-KRWDZBQOSA-N",
    plex_suggest: [
      "W-5982",
      "SYN3004",
      "SMR004702930",
      "ZINC57318556",
      "KB-62490",
      "CHEMBL1957266",
      "CHEBI:137113",
      "(+)-JQ-1"
    ],
    smiles: "Cc1sc2c(C(=N[C@@H](CC(=O)OC(C)(C)C)c3nnc(C)n23)c4ccc(Cl)cc4)c1C",
    source_dataset_id: "CHEMBL1957266",
    source_id: "chembl:CHEMBL1957266",
    title: "1268524-70-4"
  },
  description:
    "JQ1 is a thienotriazolodiazepine and a potent inhibitor of the BET family of bromodomain proteins which include BRD2, BRD3, BRD4, and the testis-specific protein BRDT in mammals. BET inhibitors structurally similar to JQ1 are being tested in clinical trials for a variety of cancers including NUT midline carcinoma",
  id: "unichem:27249705",
  links: [
    {
      name: "UniChem",
      url:
        "https://www.ebi.ac.uk/unichem/frontpage/results?queryText=DNVXATUJJDPFDM-KRWDZBQOSA-N&kind=InChIKey&sources=&incl=exclude"
    }
  ],
  neighborhood: 4,
  neighborhood_size: 429,
  rank: 1,
  source: {
    name: "UniChem",
    url:
      "https://www.ebi.ac.uk/unichem/frontpage/results?queryText=DNVXATUJJDPFDM-KRWDZBQOSA-N&kind=InChIKey&sources=&incl=exclude"
  },
  tabs: ["compound"]
};

export const jq1_for_query_pane = {
  aliases: [
    "(+)-JQ-1",
    "(+)-JQ1",
    "(+)-JQ1, >=98% (HPLC)",
    "(S)-JQ1",
    "1268524-70-4",
    "1MRH0IMX0W",
    "3MXF",
    "4FLP",
    "4QZS",
    "EX-A457",
    "FT-0699346"
  ],
  category: "compound",
  dataset_id: "27249705",
  id: "unichem:27249705",
  inchi:
    "InChI=1S/C23H25ClN4O2S/c1-12-13(2)31-22-19(12)20(15-7-9-16(24)10-8-15)25-17(11-18(29)30-23(4,5)6)21-27-26-14(3)28(21)22/h7-10,17H,11H2,1-6H3/t17-/m0/s1",
  inchikey: "DNVXATUJJDPFDM-KRWDZBQOSA-N",
  plex_match: "JQ1",
  plex_suggest: [
    "(+)-JQ-1",
    "(+)-JQ1",
    "(+)-JQ1, >=98% (HPLC)",
    "(S)-JQ1",
    "1268524-70-4",
    "EX-A457",
    "FT-0699346",
    "UNII-1MRH0IMX0W",
    "W-5982",
    "WT-131180",
    "ZINC57318556"
  ],
  smiles: "Cc1sc2c(C(=N[C@@H](CC(=O)OC(C)(C)C)c3nnc(C)n23)c4ccc(Cl)cc4)c1C",
  source_dataset_id: "CHEMBL1957266",
  source_id: "chembl:CHEMBL1957266",
  title: "1268524-70-4"
};
