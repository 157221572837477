import {applyFilter} from "./interpolation-filter";
import {getCategoryKey, getDatasetID} from "../lib/utils";
import {deriveTemplate, TEMPLATE_CONSTANTS} from "./index";

export const MARKDOWN_CHARS = "[[\\]`#*_]";
export const RE_MARKDOWN_ESCAPED = new RegExp(`\\\\(${MARKDOWN_CHARS})`, 'g');
export const RE_MARKDOWN = new RegExp(`(^|(?!\\\\).)(${MARKDOWN_CHARS})`, 'g');
// Needs to handle entity properties and potential component properties injected into the entity
export const RE_PROPERTY_EXPR = /^_?[a-z][-_a-zA-Z0-9]+(\s+[a-z][-_a-zA-Z0-9]+)*(\s*\|\s*\S+.*)?$/;
const RE_INTERPOLATION_ENTIRE = /^\{[^}]*}$/;
const RE_INTERPOLATION = /\{((?!_interpolated-)[^}]*)}/g;
const RE_JSX_COMPONENT = /<([A-Z][A-Za-z]+)([^>]+)>/g;
const RE_JSX_ATTRIBUTE = /(?<=\s)([a-z][a-zA-Z]+)=\{([^}]*)}/g;
export const IPROP_PREFIX = "_interpolated-";

export const escapeReferenceLinkText = (s) => {
  // Replace standalone underscores not at a word boundary
  // ESCAPE_THIS {NOT_THIS}
  // [ESCAPE_THIS][NOT_THIS]
  // [NOT_THIS] NOT_THIS
  return s.replace(/(?<!https[^\[]*)(?<=\w)_(?=\w)/g, "\\_")
    .replace(/(?<=]\[[^]]*)\\_/g, "_")
    .replace(/(?<={[^}]*)\\_/g, "_")
    // Replace square brackets within link text
    .replace(/(\[[^[]+)(\[[^]]+)(?=]][([])/g, "$1\\$2");
};

export const fixNestedLists = (s) => {
  // Bug in markdown-to-jsx renders these as part of _any_ preceding list
  return s.replace(/^(- )/gm, " $1");
};

export const escapeMD = (obj, chars = null) => {
  if (obj.map) {
    return obj.map(x => escapeMD(x, chars));
  }
  if (chars) {
    const re = new RegExp(`[${RegExp.escape(chars)}]`);
  }
  return obj.toString().replace(chars ? `[${chars}]` : RE_MARKDOWN, "$1\\$2");
};

export const unescapeMD = (obj) => {
  return obj.toString()
    .replace(RE_MARKDOWN_ESCAPED, "$1");
};

const hasValue = (x) => !!x || x === false || x === 0;

const composeInterpolation = (keys, filters, props: {} = {}, interpolatedProps: {} = {}, uniqueValues,
                              templateOverrides: {} = {}, markText = null, quote: function = (x) => (x)) => {
  const NOVALUE = "";
  const [propName, propValue] = keys.reduce((result, propName) => {
    if (hasValue(result[1])) {
      return result;
    }
    return [propName, props[propName]];
  }, [null, null]);
  if (hasValue(propValue)) {
    const filtered = filters.reduce((result, mod) => {
      if (result === NOVALUE) {
        return NOVALUE;
      }
      try {
        return applyFilter(result, mod[0], mod[1], uniqueValues, templateOverrides, interpolatedProps, propName,
                           markText, quote);
      } catch (e) {
        console.error(`Could not apply filter '${mod[0]}' to '${result}'`, e);
        return result;
      }
    }, propValue);
    if (typeof(propValue) === 'string' && uniqueValues) {
      uniqueValues.add(propValue.toLowerCase());
      if (props.id === `smiles:${propValue}`) {
        uniqueValues.add(props.id.toLowerCase());
      }
    }
    if (hasValue(filtered)) {
      if (filters.length === 0) {
        return quote(filtered);
      }
      return filtered;
    }
  }
  return NOVALUE;
};

export const substituteComponentProp = (attributeName, value, componentProps, markText = null) => {
  const propName = `${IPROP_PREFIX}${attributeName}-${Object.keys(componentProps).length}`;
  componentProps[propName] = markText && typeof (value) === "string" ? markText(value, true) : value;
  return `${attributeName}="${propName}"`;
}

export const interpolate = (template,
                            entity,
                            componentProps: {} = {},
                            markText: * = null,
                            uniqueValues: Set<any> = new Set(),
                            templateOverrides: {} = {},
                            quote: function = (x) => (x)) => {
  if (typeof (template) !== 'string') {
    return template;
  }
  if (RE_INTERPOLATION_ENTIRE.test(template || "")) {
    const value = "" + evaluateInterpolationExpression(template.substring(1, template.length - 1), entity,
                                                       componentProps, uniqueValues, templateOverrides, markText, quote);
    return markText ? markText(value, true) : value;
  }

  // Account for multiple interpolations within a property, e.g. title='{foo} {bar}'

  // markdown-to-jsx interpolates '{EXP}' expressions as either string or boolean

  // Handle HTML/JSX element attributes "foo={bar}"; the expression is evaluated and the property value injected
  // into componentProps.  A property placeholder is inserted for later resolution to avoid property name conflicts.
  // JSX components must start with a capital letter.
  template = template.replace(RE_JSX_COMPONENT, (match, componentName, attributes) => {
    const overrideProps = templateOverrides[componentName]?.props || {};
    const foundProps = new Set();
    const attrs = attributes.replace(RE_JSX_ATTRIBUTE, (match, attributeName, attributeValue) => {
      foundProps.add(attributeName);
      const props = {...overrideProps, ...componentProps};
      const value = evaluateInterpolationExpression(attributeValue, entity, props,
                                                    uniqueValues, templateOverrides);
      return substituteComponentProp(attributeName, value, componentProps, markText);
    });
    const overrideAttrs = Object.entries(overrideProps)
      .filter(([key, value]) => !foundProps.has(key))
      .reduce((result, [key, value]) => {
        return `${result} ${substituteComponentProp(key, value, componentProps, markText)}`;
      }, '');
    return `<${componentName}${attrs}${overrideAttrs} />`;
  });
  // Handle "{bar}" in freeform text
  return template.replace(RE_INTERPOLATION, (match, expr) => {
    const interpolated = evaluateInterpolationExpression(expr, entity, componentProps,
                                                         uniqueValues, templateOverrides,
                                                         markText, quote);
    const value = interpolated === false ? match : interpolated;
    if (typeof (value) !== "string" || (value.startsWith("<") && value.endsWith(">"))) {
      return "" + value;
    }
    return markText ? markText(quote(value), true) : quote(value);
  });
};

const evaluateInterpolationExpression = (expr, entity, interpolatedProps: {} = {}, uniqueValues,
                                     overrides: {} = {}, markText = null, quote: function = (x) => (x)) => {
  if (typeof (expr) === 'string' && RE_PROPERTY_EXPR.test(expr)) {
    const parts = expr.split('|').map(x => x.trim());
    const keys = parts[0].split(/\s+/).map(x => unescapeMD(x.trim()));
    const filters = parts.slice(1).map(x => x.split('=')
      .map(x => x.trim().replace(/^'(.*)'$/, '$1').replace(/^"(.*)"$/, '$1')));
    return composeInterpolation(keys, filters, {...interpolatedProps, ...entity}, interpolatedProps, uniqueValues,
                                overrides, markText, quote);
  }
  return false;
};

export const interpolateProps = (templateProps, entity, interpolatedProps, uniqueValues, markText = null, templateOverrides = {}) => {
  // Returns a dict with _only_ those properties which were interpolated
  // TODO: sort by dependency
  const newProps = {};
  Object.keys(templateProps).forEach(k => {
    const value = templateProps[k];
    if (value && typeof(value) === 'string') {
      const interpolated = interpolate(value, entity, interpolatedProps, markText, uniqueValues, templateOverrides);
      if (interpolated !== value) {
        newProps[k] = interpolated;
      }
    }
  });
  return newProps;
};

export const resolvePropertyValue = (value, props = {}) => {
  // Interpolate the given property value
  if (typeof(value) === "string") {
    const interpolated = interpolate(value, props);
    if (props[interpolated]) {
      return resolvePropertyValue(props[interpolated], props);
    }
    return interpolated;
  }
  return value;
};
export const interpolateEdgeDetails = (edge, dataset, fromCat, toCat) => {
  const details = {description: "", ...edge};
  // FIXME use an explicit category instead of the default "category" of the dataset
  const key = getCategoryKey(details.dataset_id);
  const categories = dataset && dataset.categories;
  details.id = getDatasetID(details.dataset_id);
  if (!details.url) {
    details.url = key && categories && categories[key] && categories[key].url
                  ? categories[key].url
                  : (dataset && (dataset.edge_url || dataset.url)) || null;
  }
  if (details.dataset_id && details.dataset_id.indexOf('|') !== -1) {
    // deprecated formatting of additional hard-coded arguments
    const [dsid, ...positionalArgs] = details.dataset_id.split('|');
    details.id = dsid;
    details.positionalArgs = positionalArgs;
  }
  const ds_edge_default_props = dataset.edge_properties || {};
  const ds_edge_props = ds_edge_default_props[`${fromCat}-${toCat}`] || ds_edge_default_props[`${toCat}-${fromCat}`] || {};
  const default_props = {...ds_edge_default_props, ...ds_edge_props};
  details.title = resolvePropertyValue(details.title || default_props.title,
                                  {...default_props, ...details}) || "";
  details.description = resolvePropertyValue(details.description || default_props.description,
                                        {...default_props, ...details}) || "";
  if (details.url) {
    details.url = resolvePropertyValue(details.url, {positionalArgs: [], ...default_props, ...details});
  }
  return details;
};
export const interpolateEntityFields = (
  templates,
  entity,
  dataset = {},
) => {
  const dsCats = dataset.categories;
  const dsCat = (dsCats && dsCats[entity.category]) || {};
  const dsBaseCat = (dsCats && dsCats[entity.baseCategory]) || {};
  const dsCustomProps = {
    ...((dsBaseCat.templates && dsBaseCat.templates[TEMPLATE_CONSTANTS.PROPERTIES]) || {}),
    ...((dsCat.templates && dsCat.templates[TEMPLATE_CONSTANTS.PROPERTIES]) || {}),
  };
  // Evaluate custom dataset properties, then custom category properties
  // property "label" may depend on the title
  const templateProperties = deriveTemplate(templates, TEMPLATE_CONSTANTS.PROPERTIES, entity.category,
                                            entity.baseCategory, dsCustomProps, {});
  const priorityPropNames = [
    ...Object.keys(dsCustomProps).filter(x => x.startsWith("_")),
    ...Object.keys(templateProperties).filter(x => x.startsWith("_")),
    ...Object.keys(dsCustomProps).filter(x => !x.startsWith("_")),
    'title', 'description', 'label'
  ];
  // Never replace these names
  const fixedPropertyNames = new Set(["id", "category", "dataset", "inchi", "inchikey"]);
  // Populate template properties
  // Template definitions should override existing properties (required when indexed data is incorrect or obsolete)
  const merged = {...entity, ...templateProperties};
  priorityPropNames.forEach(name => merged[name] = resolvePropertyValue(merged[name], merged));
  Object.keys(merged)
    .filter(p => !new Set(priorityPropNames).has(p))
    .filter(p => !fixedPropertyNames.has(p))
    .forEach(name => merged[name] = resolvePropertyValue(merged[name], merged));
  return merged;
};
