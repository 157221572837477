import {MultiUndirectedGraph} from "graphology";
import subgraph from "graphology-operators/subgraph"
import union from "graphology-operators/union"
import {useCallback, useEffect, useMemo, useState} from "react";
import {SigmaContainer, useCamera, useSigmaContext} from "@react-sigma/core";
import {SigmaEdgeGraphLoader, useEventHandlers} from "./SigmaEdgeGraphLoader";
import useSelectionRectangle from "./graph/sigma/SelectionRectangle";

import {useElementSize} from "../hooks/useElementSize";

// How much space for non-selected nodes to use at top/bottom of column
const ZOOM_MIN = 0.125;
const UPSCALE = 1000;

export const NODE_SIZE_DEFAULT = 4;

const NODE_COLOR_DEFAULT = "#e4762f";
const NODE_COLOR_SELECTED = "#e54028";
const NODE_COLOR_HOVER = "#f7be33";
const NODE_COLOR_FADE = "#F0E0E0";
const NODE_COLOR = {
  compound: "#00A000",
  target: "#0000FF",
  disease: "#FF0000",
  publication: "#808080",
  patent: "#C0C0C0",
  geneset: "#C000C0",
  moa: "#FFD020",
  org: "#404040",
};
const fadeColor = (c) => {
  const hex = (x) => `${x < 16 ? '0' : ''}${x.toString(16)}`;
  const bump = (x) => hex(Math.floor((x + 2 * 0xFF) / 3));
  return `#${[1, 3, 5].map(x => bump(parseInt(c.slice(x, x + 2), 16))).join("")}`;
};

const EDGE_COLOR_DEFAULT = "#C0C0D0";
const EDGE_COLOR_SELECTED = "#882014";
const EDGE_COLOR_HOVER = "#C09055";
const EDGE_COLOR_FADE = "#E0E0F0";
const EDGE_SIZE_HOVER = 6;
const EDGE_SIZE_SELECTED = 4;

// drag > hover > select
// drag - no highlights, fade select, hide other
// hover - node => connected highlights, edge => segment highlight, fade select, hide other
// select - node => connected highlights, edge => segment highlight, fade other

// TODO: slow zoom/unzoom
// TODO: placeholder on empty graph/results
// TODO: server-side fetch edges provided all edge endpoints
// TODO:
//   navigation: direct to graph => query cat => heuristic first category or allow user to choose
//   navigation: echains => graph of query cat => target cat
// TODO: display neighborhood as graph or group
// TODO: unclip/position labels, use shorter labels/fix labels/label select from options
// TODO: d3/cytoscape - css, please! especially for labels

export const SigmaEdgeGraph = ({
                                 nodes = [],
                                 adjacency = [],
                                 columnCount = 0,
                                 onEdgeSelect = null,
                                 similarityColumn = -1, intermediateColumn = -1,
                                 showSimilarity = true, showIntermediate = true,
                                 sortBySimilarity = false,
                               }) => {

  // Maintain graph user interactivity state
  // NOTE: sigma won't update its callbacks, so we have to update dependencies within `state`
  // and then explicitly call refresh() when dependencies change
  const state = useMemo(() => ({
    showSimilarity,
    showIntermediate,
    sortBySimilarity,
    similarityColumn,
    intermediateColumn,
    columnCount,
  }), []);
  const {sigma, container} = useSigmaContext();
  const graph = sigma.getGraph();
  const camera = useCamera();
  const [minSimilarity, setMinSimilarity] = useState(1);
  const [maxSimilarity, setMaxSimilarity] = useState(0);
  const containerSize = useElementSize(container);
  const [zoomRanges, setZoomRanges] = useState(Array.apply(undefined, {length: columnCount}))

  const width = containerSize.width;
  const height = containerSize.height;

  const onSelectionChange = useCallback(({nodes: selectedNodeIDs}) => {
    state.selectedNodes = selectedNodeIDs && selectedNodeIDs.length ? new Set(selectedNodeIDs) : undefined;
    state.selectedEdges = undefined;
    sigma.refresh();
  }, [sigma]);

  const onSelectionEnd = useCallback(({nodes: selectedNodeIDs}) => {
    if (selectedNodeIDs && selectedNodeIDs.length) {
      state.selectedNodes = new Set(selectedNodeIDs);
      const selectedNodes = nodes.filter(n => state.selectedNodes.has(n.id));
      const columns = selectedNodes.reduce((result, node) => {
        result.add(node.column);
        return result;
      }, new Set());
      setZoomRanges(zoomRanges
        .map((_, col) => {
          if (!columns.has(col)) {
            return undefined;
          }
          const min = selectedNodes.filter(node => node.column === col)
            .reduce((min, node) => Math.min(min, getOrder(node)), 1);
          const max = selectedNodes.filter(node => node.column === col)
            .reduce((max, node) => Math.max(max, getOrder(node)), 0);
          return [min, max];
        }));
    }
    else {
      state.selectedNodes = state.selectedEdges = state.hoverNodes = state.hoverEdges = undefined;
    }
    sigma.refresh();
  }, [sigma]);

  const applyZoom = useCallback((column, rank) => {
    if (zoomRanges[column]) {
      const [min, max] = zoomRanges[column];
      if (rank < min) {
        rank = rank * ZOOM_MIN;
      }
      else if (rank > max) {
        rank = (1 - ZOOM_MIN) + (rank - max) * ZOOM_MIN;
      }
      else {
        rank = ZOOM_MIN + (rank - min) / (max - min) * (1 - 2 * ZOOM_MIN);
      }
    }
    return rank;
  }, [zoomRanges]);

  const selectionState = useSelectionRectangle(sigma, container, {onSelectionChange, onSelectionEnd});

  useEffect(() => {
    state.selectedEdges = state.selectedNodes = state.hoverEdges = state.hoverNodes = undefined;
    sigma.refresh();
  }, [nodes, adjacency]);

  useEffect(() => {
    //console.debug(`sim=${showSimilarity} int=${showIntermediate} cols=${columns}`);
    state.showSimilarity = showSimilarity;
    state.showIntermediate = showIntermediate;
    state.similarityColumn = similarityColumn;
    state.intermediateColumn = intermediateColumn;
    sigma.refresh();
  }, [showSimilarity, showIntermediate, similarityColumn, intermediateColumn, columnCount]);


  const columnHidden = (column) => {
    return column === state.similarityColumn && !state.showSimilarity
           || column === state.intermediateColumn && !state.showIntermediate;
  };

  const expandFromNode = (key, expandRight) => {
    const column = graph.getNodeAttribute(key, "column");
    return Array.from(graph.reduceNeighbors(key, (nodes, nbrKey) => {
      const nbrColumn = graph.getNodeAttribute(nbrKey, "column");
      if (!columnHidden(nbrColumn)) {
        if (expandRight ? nbrColumn > column : nbrColumn < column) {
          nodes.add(nbrKey);
          if ((expandRight && nbrColumn < columnCount - 1) || (!expandRight && nbrColumn > 0)) {
            expandFromNode(nbrKey, expandRight).forEach(nn => nodes.add(nn));
          }
        }
      }
      return nodes;
    }, new Set()));
  };

  const isolateChains = (node) => {
    const leftNodes = [node, ...expandFromNode(node, false)];
    const left = subgraph(graph, leftNodes);
    const rightNodes = [node, ...expandFromNode(node, true)];
    const right = subgraph(graph, rightNodes);
    return union(left, right);
  };

  const handleNodeEnter = useCallback(({node}) => {
    if (selectionState.isActive) {
      return;
    }
    const g = isolateChains(node);
    state.hoverNodes = new Set(g.nodes());
    state.hoverEdges = new Set(g.edges());
    updateEdges();
    sigma.refresh()
  }, [sigma, graph]);

  const handleNodeLeave = useCallback(({node}) => {
    if (selectionState.isActive) {
      return;
    }
    state.hoverNodes = state.hoverEdges = undefined;
    updateEdges();
    sigma.refresh();
  }, [sigma, graph]);

  const updateEdges = () => {
    if (state.hoverEdges?.size === 1) {
      handleEdgeSelect(Array.from(state.hoverEdges)[0]);
    }
    else if (state.hoverEdges) {
      handleEdgeSelect();
    }
    else {
      if (state.selectedEdges?.size === 1) {
        handleEdgeSelect(Array.from(state.selectedEdges)[0]);
      }
      else if (state.selectedEdges) {
        handleEdgeSelect();
      }
      else {
        handleEdgeSelect();
      }
    }
  }

  const handleNodeClick = useCallback(({node}) => {
    const g = isolateChains(node);
    state.selectedNodes = new Set(g.nodes());
    state.selectedEdges = new Set(g.edges());
    state.hoverNodes = state.hoverEdges = undefined;
    updateEdges();
    sigma.refresh();
  }, [sigma, graph]);

  const handleEdgeEnter = useCallback(({edge}) => {
    if (selectionState.isActive) {
      return;
    }
    state.hoverEdges = new Set([edge]);
    state.hoverNodes = new Set([graph.source(edge), graph.target(edge)]);
    updateEdges();
    sigma.refresh();
  }, [sigma, graph]);

  const handleEdgeLeave = useCallback(({edge}) => {
    if (selectionState.isActive) {
      return;
    }
    state.hoverEdges = state.hoverNodes = undefined;
    updateEdges();
    sigma.refresh();
  }, [sigma]);

  const handleEdgeClick = useCallback(({edge}) => {
    state.selectedNodes = new Set([graph.source(edge), graph.target(edge)]);
    state.selectedEdges = new Set([edge]);
    updateEdges();
    sigma.refresh();
  }, [sigma, graph]);

  const handleStageClick = ({event}) => {
    // NOTE: this handler will not be called if there was a drag
    // https://github.com/jacomyal/sigma.js/blob/89a1be9953dd3488546fabc880dac262d5019c67/packages/sigma/src/core/captors/mouse.ts#L127
    const graphPos = sigma.viewportToGraph(event);
    const column = Math.round(graphPos.x / xMultiplier(width, height) * columnCount);
    if (column < columnCount) {
      setZoomRanges(zoomRanges.map((el, col) => {
        if (el && column === col && (graphPos.y < ZOOM_MIN || graphPos.y > 1 - ZOOM_MIN)) {
          el = undefined;
        }
        return el;
      }));
    }
    state.hoverEdges = state.hoverNodes = state.selectedEdges = state.selectedNodes = undefined;
    updateEdges();
    sigma.refresh();
  };

  const orderEdgeDetailNodes = (id1, id2) => {
    const col1 = graph.getNodeAttribute(id1, "column");
    const col2 = graph.getNodeAttribute(id2, "column");
    return col1 < col2 ? [id1, id2] : [id2, id1];
  }

  const handleEdgeSelect = edge => {
    if (onEdgeSelect) {
      if (edge) {
        onEdgeSelect(...orderEdgeDetailNodes(graph.source(edge), graph.target(edge)), graph.getEdgeAttributes(edge));
      }
      else {
        onEdgeSelect();
      }
    }
  };

  const handleDownNode = ({node}) => {
    sigma.camera.disable();
    state.draggedNode = node;
    state.hoverNodes = state.hoverEdges = undefined;
    if (!state.selectedNodes?.has(node)) {
      state.selectedNodes = state.selectedEdges = undefined;
    }
    updateEdges();
    sigma.refresh();
  };
  const handleMouseUp = (e) => {
    if (state.draggedNode) {
      state.draggedNode = null;
      sigma.refresh();
    }
    sigma.camera.enable();
  };
  const handleMouseMove = e => {
    if (state.draggedNode) {
      const pos = sigma.viewportToGraph(e);
      // TODO: limit x/y position based on column/rank, or auto-apply layout on drop
      graph.setNodeAttribute(state.draggedNode, "x", pos.x);
      graph.setNodeAttribute(state.draggedNode, "y", pos.y);
      e.preventSigmaDefault();
      e.original.preventDefault();
      e.original.stopPropagation();
      sigma.refresh();
    }
  };
  const handleUpdated = e => {
    camera.reset();
  };

  useEventHandlers({
                     downNode: handleDownNode,
                     mouseup: handleMouseUp,
                     mousemove: handleMouseMove,
                     clickNode: handleNodeClick,
                     enterNode: handleNodeEnter,
                     leaveNode: handleNodeLeave,
                     clickEdge: handleEdgeClick,
                     enterEdge: handleEdgeEnter,
                     leaveEdge: handleEdgeLeave,
                     clickStage: handleStageClick,
                     updated: handleUpdated,
                   });

  sigma.setSetting("nodeReducer", (id, data) => {
    const props = {...data};
    const dragged = state.draggedNode === id;
    const selected = state.selectedNodes?.has(id);
    const hover = state.hoverNodes?.has(id);
    const fade = !selected && !hover && !dragged && (state.selectedNodes || state.hoverNodes || state.draggedNode);
    // TODO: also hide node if no connected edges are visible, i.e. no neighbors are visible
    const hide = columnHidden(props.column);
    props.size = Math.min(48, props.size || NODE_SIZE_DEFAULT / 2);
    if (hide) {
      props.hidden = true;
    }
    else if (dragged) {
      props.highlighted = true;
    }
    else if (hover) {
      props.highlighted = true;
      props.color = NODE_COLOR_HOVER;
    }
    else if (selected) {
      props.highlighted = true;
      props.color = NODE_COLOR_SELECTED;
    }
    if (fade) {
      props.label = "";
      if (props.color) {
        props.color = fadeColor(props.color);
      }
    }
    return props;
  });
  sigma.setSetting("edgeReducer", (id, data) => {
    const props = {...data};
    const selected = state.selectedEdges && state.selectedEdges.has(id);
    const hover = state.hoverEdges && state.hoverEdges.has(id);
    const fade = state.draggedNode || (!selected && !hover && state.selectedEdges && state.hoverEdges);
    const hide = !selected && !hover && state.selectedEdges || selectionState.isActive;
    if (hover) {
      props.highlighted = true;
      props.size = EDGE_SIZE_HOVER;
      props.color = selected ? EDGE_COLOR_HOVER : EDGE_COLOR_HOVER;
    }
    else if (selected) {
      props.highlighted = true;
      props.color = EDGE_COLOR_SELECTED;
      props.size = EDGE_SIZE_SELECTED;
    }
    if (fade) {
      props.label = "";
      props.color = EDGE_COLOR_FADE;
    }
    if (hide) {
      props.hidden = true;
    }
    return props;
  });
  const getOrder = useCallback(d => (sortBySimilarity && d.column === similarityColumn
                                    ? (d.score - minSimilarity) / (maxSimilarity - minSimilarity)
                                    : d.rank),
                              [sortBySimilarity, similarityColumn, minSimilarity, maxSimilarity]);
  const xMultiplier = (w, h) => UPSCALE * (w < h ? 1 : w / (h || 1));
  const deriveX = useCallback(d => {
    const multiplier = xMultiplier(width, height);
    return d.column / columnCount * multiplier;
  }, [columnCount, width, height]);
  const deriveY = useCallback(d => {
    const multiplier = UPSCALE * (height < width ? 1 : height / (width || 1));
    return applyZoom(d.column, getOrder(d)) * multiplier;
  }, [width, height, applyZoom, getOrder]);
  return (
    <SigmaEdgeGraphLoader
      nodes={nodes}
      adjacency={adjacency}
      controlsPosition={"bottom-right"}
      width={width}
      height={height}
      deriveX={deriveX}
      deriveY={deriveY}
      setMinSimilarity={setMinSimilarity}
      setMaxSimilarity={setMaxSimilarity}
    />
  );
}

export const EdgeGraph = ({
                            queryIDs = [],
                            nodesList = [],
                            adjacencyList = [],
                            columnCount = 1,
                            datasets = {},
                            queryCategory,
                            targetCategory,
                            sortBySimilarity = false,
                            ...childProps
                           }) => {

  const [intermediateColumn, setIntermediateColumn] = useState(-1);
  const [similarityColumn, setSimilarityColumn] = useState(-1);

  const nodes = useMemo(() => {
    const qids = new Set(queryIDs || []);
    const nodeColumn = (id, category) => {
      if (qids.has(id)) {
        return 0;
      }
      if (category === queryCategory) {
        setSimilarityColumn(1);
        return 1;
      }
      if (category === targetCategory) {
        return columnCount - 1;
      }
      setIntermediateColumn(columnCount - 2);
      return columnCount - 2;
    };
    const nl = nodesList.map(([rank, id, category, label, ...rest]) => ({
      id, label, rank, category,
      column: nodeColumn(id, category),
      color: NODE_COLOR[category] || NODE_COLOR_DEFAULT,
    }))
    nl.push({
              id: "--spacer--",
              label: "",
              column: columnCount,
              x: 1, y: 0,
              hidden: true,
            });
    return nl;
  }, [nodesList, queryIDs, targetCategory, columnCount]);

  const detailsScore = nbr => {
    if (!(nbr instanceof Array)) {
      return 0.5;
    }
    const [id, details] = nbr;
    const dsCount = new Set(details.map(d => d.dataset)).size;
    return Math.log10(details.length) * (1 + dsCount / 10);
  };
  const maxDetailsScore = adjacencyList
    .reduce((max, neighbors) => Math.max(max, neighbors
              .reduce((nmax, nbr) => Math.max(nmax, detailsScore(nbr)),
                      0)),
            0.0001);
  const edgeColor = nbr => {
    const n = Math.round((maxDetailsScore - detailsScore(nbr)) / maxDetailsScore * 0xE0);
    const hex = `${n < 16 ? "0" : ""}${n.toString(16)}`;
    return `#${hex}${hex}${hex}`;
  }
  const adjacency = useMemo(() => adjacencyList.map(neighbors => neighbors.map(nbr => {
    // Neighbor may be a single node index, or a pair of (ID, edge details array)
    const [id, details] = nbr instanceof Array ? nbr : [nbr, []];
    return [id, {
      label: Array.from(new Set(details.map(d => d.dataset))).map(ds => datasets[ds]?.name || "<ds.name?>").join(", "),
      color: edgeColor(nbr),
      details,
    }];
  })), [adjacencyList, targetCategory]);

  return (
    <SigmaContainer className="graph-container" graph={MultiUndirectedGraph} settings={{
      enableEdgeHoverEvents: true,
      enableEdgeClickEvents: true,
      renderEdgeLabels: targetCategory !== "all",
      // Affects zoom, also camera.disable()
      minCameraRatio: 0.1,
      maxCameraRatio: 10,
    }} >
      <SigmaEdgeGraph
        nodes={nodes}
        adjacency={adjacency}
        columnCount={targetCategory === "all" ? 0 : columnCount}
        similarityColumn={similarityColumn}
        intermediateColumn={intermediateColumn}
        sortBySimilarity={sortBySimilarity}
        {...childProps}
      />
    </SigmaContainer>
  );
};

export default EdgeGraph;
