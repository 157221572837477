import React from "react";
import { compiler } from "markdown-to-jsx";
import { docs } from "./md_docs";
import { categories_yml_sample } from "./categories_yml_sample";
import { parser_doc } from "./parser_doc";
import "./styles.css";
import {datasets_yml_sample} from "./datasets_yml_sample";
import {markdown_cheat_sheet} from "./markdown-cheat-sheet";

export const MarkdownDocumentation = () => (
  <div className="docs-container">{compiler(docs)}</div>
);

export const MarkdownCheatSheet = () => (
    <div className="docs-container">{compiler(markdown_cheat_sheet)}</div>
);

export const CategoriesYMLDocumentation = () => (
  <div className="docs-container">{compiler(categories_yml_sample)}</div>
);

export const DatasetsYMLDocumentation = () => (
  <div className="docs-container">{compiler(datasets_yml_sample)}</div>
);

export const ParserDocumentation = () => (
  <div className="docs-container">{compiler(parser_doc)}</div>
);

