import { MDSearchResultHeader } from "../../components/results-grid/grid-row/SearchResultHeader";
import {
  MDCollapsibleText,
  MDCollapsibleList
} from "../../components/results-grid/grid-row/MDCollapsibleContent";
import { MDExternalLinks } from "../../components/results-grid/grid-row/ExternalLinks";

export const getOverrides = (props = {}) => {
  return {
    Header: {
      component: MDSearchResultHeader,
      props,
    },
    Text: {
      component: MDCollapsibleText,
    },
    List: {
      component: MDCollapsibleList,
    },
    Links: {
      component: MDExternalLinks,
    },
  };
};

export const overrideMapSample = getOverrides();
