import React from "react";
import ErrorPage from "../pages/ErrorPage";


// NOTE: there is no "componentDidCatch" equivalent for functional components
class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Component error", error);
    this.setState({
      location: window.location,
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <ErrorPage
            location={this.state.location}
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;
