import _ from "lodash";

// Alter template structure to allow lookup by type.category instead of category.type
export const extractTemplates = (metadata = null) => {
  if (metadata) {
    return _(metadata)
      .entries()
      .map(([key, info]) => ({
        category: key === '_default' ? 'default' : key,
        templates: info.templates
      }))
      .remove(x => x !== null)
      .flatMap(x => _.entries(x.templates).map(y => [x.category, ...y]))
      .map(([cat, type, template]) => [type, { [cat]: template }])
      .reduce((acc, x) => {
        const [type, templateByCategory] = x;
        acc[type] = { ...acc[type], ...templateByCategory };
        return acc;
      }, {});
  }
  return null;
};
