import React from 'react'
import PropTypes from 'prop-types'
import EChainMarkdownFactory from '../../markdown/EChainMarkdownFactory'
import {interpolateEdgeDetails} from "../../markdown/interpolation";

const SingleChain = ({
      path = [],
      scaleFactor,
      actions,
      datasets,
      highlightedDatasets
  }) => {

  const chain = [...path];
  for (let idx=1; idx + 1 < chain.length; idx += 2) {
      const endPoints = [chain[idx-1].entity, chain[idx+1].entity];
      if (endPoints[0].baseCategory === endPoints[1].baseCategory) {
        chain[idx-1].similarNeighbor = endPoints[1];
        chain[idx+1].similarNeighbor = endPoints[0];
      }
      chain[idx] = {
          ...path[idx],
          content: path[idx].content.map(edge => ({
            ...edge,
            dataPoints: edge.dataPoints
              .map(dp => interpolateEdgeDetails(dp, datasets[edge.dataset] || {},
                                                endPoints[0].baseCategory,
                                                endPoints[1].baseCategory)),
          })),
      };
  }

  return (
    <div className="ev-ch-wrapper">
        {
          chain.map((el, idx) =>
              <EChainMarkdownFactory
                  key={idx}
                  highlightedDatasets={highlightedDatasets}
                  data={el}
                  scaleFactor={scaleFactor}
                  actions={actions}/>
          )
        }
    </div>
  )
}

SingleChain.propTypes = {
  path: PropTypes.arrayOf(PropTypes.object).isRequired,
  scaleFactor: PropTypes.string,
  highlightedDatasets: PropTypes.arrayOf(PropTypes.string)
}

export default SingleChain;
