import React, {Component, useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import FormLayout from "../FormLayout";
import ChangePassword from "./ChangePassword";
import { validateFormExt } from "../validation";
import {
  getMessageObject,
  MessageType,
  MessageObject
} from "../FormLayout/MessageFactory";
import { inject, observer } from "mobx-react";

const _ERRORS = {
  PasswordReused: "That password has been recently used",
  PasswordValidationError: "The new password could not be validated, please try again later",
  NoResponse: "The server is currently unreachable"
};

const lookupError = name => {
  return _ERRORS[name] || name;
};

const ACTION_REQUEST = "request-link";
const ACTION_CHANGE = "change-password";

// This container implements both change password and password reset functionality
// In the former case, the old password is required; in the latter, a verification code
// ACTION_RESET: send access link
// ACTION_CHANGE: change password, with either old password or access code
const PasswordModificationContainer = (props) => {

  const {userStore} = props;

  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const input = {...props, ...location.state};

  const {
    action = params.get('confirmation_code') ? ACTION_CHANGE : params.get('action') || ACTION_CHANGE,
    rememberMe = false,
    verificationCode = params.get('confirmation_code'),
  } = input;

  const [username, setUsername] = useState(input.username || params.get("email") || "");
  const [accessLinkSent, setAccessLinkSent] = useState(input.accessLinkSent || verificationCode != null);
  const [oldPassword, setOldPassword] = useState(input.password || "");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const [validating, setValidating] = useState(false);
  const [messageObject, setMessageObject] = useState(input.message || null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(
    {
      username: username !== "",
      verificationCode: false,
      oldPassword: false,
      newPassword: false,
      newPasswordCheck: false
    }
  );
  const [newPasswordInvalid, setNewPasswordInvalid] = useState(null);

  const requestAccessLink = requestedBefore => evt => {
    userStore
      .sendVerificationCode(username)
      .then(result => {
        setFormSubmitted(false);
        setAccessLinkSent(true);
        setMessageObject(requestedBefore
                     ? getMessageObject(MessageType.VerificationCodeResent, username)
                     : getMessageObject(MessageType.CopyVerificationCode, username),);
        setFieldErrors({
                          username: false,
                          verificationCode: false,
                          newPassword: false,
                          newPasswordCheck: false
                        });
      })
      .catch(error => {
        setAccessLinkSent(false);
        setMessageObject(null);
        // "InvalidParameterException" is raised when user is in "unconfirmed" state
        if (error.name === "InvalidParameterException") {
          navigate("/signin", {
            replace: true,
            state: {message: getMessageObject(MessageType.UserNotConfirmedException, username)}
          });
          return;
        } else if (error.name === "UserNotFoundException") {
          setMessageObject(getMessageObject(
            MessageType.UserNotFoundException,
            username
          ));
        } else if (error.name === "LimitExceededException") {
          setAccessLinkSent(false);
          setMessageObject(getMessageObject(error));
        }
        setFormSubmitted(false);
        setFieldErrors({
                          username: false,
                          verificationCode: false,
                          oldPassword: false,
                          newPassword: false,
                          newPasswordCheck: false
                        });
      });
  };

  const changePassword = () => {
    userStore
      .changePassword(username, oldPassword, newPassword)
      .then(result => {
        navigate("/", {
          replace: true,
          state: {
            username: username,
            password: newPassword,
            message: MessageObject("Password was changed", "info", "info")
          }
        });
      })
      .catch(error => {
        // e.g. 400: name="LimitExceededException"
        setFormSubmitted(false);
        setMessageObject(MessageObject(error.message, "error", error.name));
      });
  };

  const confirmNewPassword = async () => {
    try {
      await userStore.confirmNewPassword(
        username,
        verificationCode,
        newPassword
      );

      try {
        await userStore.signIn(username, newPassword, rememberMe, navigate);
        navigate("/", {replace: true});
      } catch (signInError) {
        navigate("/signin", {
          replace: true,
          state: {
            username: username,
            password: newPassword,
            message: MessageObject(signInError.message, "error", signInError.name)
          }});
      }
    } catch (error) {
      // LimitExceededException
      // ExpiredCodeException
      setFormSubmitted(false);
      setMessageObject(MessageObject(error.message, "error", error.name));
    }
  };

  const handleChange = field => evt => {
    const value = evt.target.value;
    setMessageObject(null);
    if (field === "username") {
      setUsername(value.trim());
    }
    else if (field === "oldPassword") {
      setOldPassword(value);
    }
    else if (field === "newPasswordCheck") {
      setNewPasswordCheck(value);
    }
    else if (field === "newPassword") {
      setNewPassword(value);
      setValidating(true);
      userStore
        .runServerPasswordValidation(
          username,
          value,
          action === ACTION_REQUEST
        )
        .then(() => {
          setNewPasswordInvalid(false);
        })
        .catch(error => {
          setNewPasswordInvalid(lookupError(error.content === "PasswordReused"
                                            ? error.content
                                            : "PasswordValidationError"));
        })
        .finally(() => {
          setValidating(false);
        })
    }
  };

  // Install this as an onFocus handler if you want any error messages to be cleared
  // when the user is using the field
  const handleFocus = (field, focused) => evt => {
    setFieldErrors({...fieldErrors, [field]: !focused });
  };

  const errors = validateFormExt(
    username,
    verificationCode,
    oldPassword,
    newPassword,
    newPasswordCheck,
    newPasswordInvalid,
    validating,
  );
  useEffect(() => {
    let errorMsg = "";
    if (fieldErrors.username && errors.username) {
      errorMsg = errors.username;
    }
    if (fieldErrors.verificationCode && errors.verificationCode) {
      errorMsg += (errorMsg !== "" ? "\n" : "") + errors.verificationCode;
    }
    if (fieldErrors.oldPassword && errors.oldPassword) {
      errorMsg += (errorMsg !== "" ? "\n" : "") + errors.oldPassword;
    }
    if (fieldErrors.newPassword && errors.newPassword) {
      errorMsg += (errorMsg !== "" ? "\n" : "") + errors.newPassword;
    }
    if (newPasswordInvalid) {
      errorMsg += (errorMsg !== "" ? "\n" : "") + newPasswordInvalid;
    }
    if (fieldErrors.newPasswordCheck && errors.newPasswordCheck) {
      errorMsg += (errorMsg !== "" ? "\n" : "") + errors.newPasswordCheck;
    }
    if (errorMsg !== "") {
      setMessageObject(MessageObject(errorMsg, "error"));
    }
  }, [errors, fieldErrors]);

  return (
    <FormLayout message={messageObject}>
      <ChangePassword
        action={action}
        formSubmitted={formSubmitted}
        username={username}
        accessLinkHasBeenSent={accessLinkSent}
        verificationCode={verificationCode}
        oldPassword={oldPassword}
        newPassword={newPassword}
        validating={validating}
        newPasswordCheck={newPasswordCheck}
        errors={errors}
        onRequestAccessLink={requestAccessLink}
        onChange={handleChange}
        onFocus={handleFocus}
        onSubmit={() => {
          setFormSubmitted(true);
          if (verificationCode == null) {
            changePassword();
          }
          else {
            confirmNewPassword();
          }
        }}
        onCancel={() => {
          navigate(action === ACTION_CHANGE ? -1 : "/signin", {replace: true});
        }}
      />
    </FormLayout>
  );
};

const RequestAccessLink = inject("userStore")(
  observer(props => (
    <PasswordModificationContainer {...props} action={ACTION_REQUEST} />
  ))
);

const PasswordChange = inject("userStore")(
  observer(PasswordModificationContainer)
);

export { ACTION_REQUEST, ACTION_CHANGE, PasswordChange, RequestAccessLink };
