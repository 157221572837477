import {Fragment, useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import Checkbox from "./controls/Checkbox";
import {naturalSort} from "../lib/utils";
import _ from "lodash";

const DropdownFilter = (props) => {
  const identity = (x) => x;
  const {
    items = [],
    // Initial selection state; determines whether "apply" will be active
    currentFilterValue = null,
    onFilterApply = null,
    onFilterClear = null,
    getKey = identity,
    getLabel = identity,
    getTitle = identity,
    disabled = false,
  } = props;
  const filterValue = [...(currentFilterValue || items)];
  const filterValueKeys = new Set(filterValue.map(getKey));
  //console.log(`Filter is ${JSON.stringify((currentFilterValue || []).map(getKey))}`);
  const [selectedItemKeys, setSelectedItemKeys] = useState(filterValueKeys);

  const noFilter = filterValue.length === items.length;
  const allSelected = selectedItemKeys.size === items.length;
  const selectionChanged = !_.isEqual(selectedItemKeys, filterValueKeys);
  const canApply = onFilterApply && selectionChanged && selectedItemKeys.size > 0;
  const canClear = onFilterClear && !noFilter;

  const toggleSelection = (key, modifier = false) => {
    if (key === "all") {
      setSelectedItemKeys(new Set(items.map(getKey)));
    }
    else if (key === "clear") {
      setSelectedItemKeys(new Set());
    }
    else if (selectedItemKeys.has(key)) {
      selectedItemKeys.delete(key);
      setSelectedItemKeys(new Set(selectedItemKeys));
    }
    else {
      selectedItemKeys.add(key);
      setSelectedItemKeys(new Set(selectedItemKeys));
    }
  };
  return (
    <Fragment>
      <Dropdown.Item
        key={"apply"}
        title={canApply ? "Apply selected items as a filter" : "Select a subset of filterable items"}
        disabled={disabled || !canApply}
        onClick={() => onFilterApply(items.filter(el => selectedItemKeys.has(getKey(el))))}
        className={"apply-filter"}
      >
        Apply filter
      </Dropdown.Item>
      <Dropdown.Item
        key={"clear"}
        title={canClear ? "Clear currently applied filter" : "No filter to clear"}
        disabled={disabled || !canClear}
        onClick={() => onFilterClear()}
        className={"clear-filter"}
      >
        Clear filter
      </Dropdown.Item>
      <Dropdown.Item
        key={"all"}
        title={allSelected ? "Clear selection" : "Select all items"}
        className={"select-all"}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          toggleSelection(allSelected ? "clear" : "all")
        }}
      >
        {allSelected ? "Clear All" : "Select All"}
      </Dropdown.Item>
      <Dropdown.Divider />
      {items
        .sort((a, b) => naturalSort(getLabel(a), getLabel(b)))
        .map((item) => (
          <Dropdown.Item
            key={getKey(item)}
            title={getTitle(item)}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              toggleSelection(getKey(item))
            }}
          >
            <Checkbox
              checked={selectedItemKeys.has(getKey(item))}
              title={getTitle(item)}
              onChange={e=>toggleSelection(getKey(item))}
              disabled={disabled}
              label={getLabel(item)}
            />
          </Dropdown.Item>))
      }
    </Fragment>
  );
};

export default DropdownFilter;
