import React, {useCallback, useState} from "react";
import {renderMarkdown} from "../../markdown/markdown-factory";
import {toast} from "react-toastify";

import {Button, Col, Row} from "react-bootstrap";
import ObjectEditor from "./ObjectEditor";
import TemplateEditor from "./TemplateEditor";
import ExampleSelector from "./ExamplesPanel";

import "./styles.css";
import {markMatchingText} from "../../lib/utils";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {escapeReferenceLinkText} from "../../markdown/interpolation";

const Sandbox = ({
  key = "",
  samples = [],
  overrides = {},
  additionalSamples = [],
}) => {
  return ({fetchEntities}) => {
    const interpolate= key !== "";
    const defaultTemplate = samples.length > 0 ? samples[0].template : "";
    const defaultEntity = samples.length > 0 ? samples[0].dataObject : {};
    const [entity, setEntity] = useState(interpolate ? JSON.parse(localStorage[`${key}-entity`] || JSON.stringify(defaultEntity)) : null);
    const [template, setTemplate] = useState(localStorage[`${key}-template`] || defaultTemplate);
    const [markedText, setMarkedText] = useState("");
    const [entityID, setEntityID] = useState(interpolate ? localStorage[`${key}-entity-id`] || "" : "");

    const fetchEntity = (id) => {
      fetchEntities([id]).then(list => {
        if (list && list[0]) {
          setEntity(list[0]);
        }
      })
        .catch(error => toast.error(`Could not fetch data for ${id} (${error})`));
    };

    const reset = () => {
      setEntity(defaultEntity);
      setTemplate(defaultTemplate);
      setMarkedText("");
      delete localStorage[`${key}-template`];
      delete localStorage[`${key}-entity`];
      delete localStorage[`${key}-entity-id`];
    };
    const markText = useCallback((input, asString = false) => {
      return markMatchingText(input, markedText, asString);
    }, [markedText]);
    const parametersChanged = selectedExample => {
      setEntity(selectedExample.dataObject);
      setTemplate(selectedExample.template);
      setMarkedText("");
    };
    const rendered = renderMarkdown({
                                      markdown: escapeReferenceLinkText(template),
                                      entity,
                                      markText: markedText !== "" ? markText : null,
                                      overrides
    });

    return (
      <div className={"sandbox"}>
        <Row className="clear-margin">
          <Col md={key ? 4 : 6}>
            <TemplateEditor
              initText={template}
              onTemplateChange={(x) => {
                setTemplate(x);
                localStorage[`${key}-template`] = x;
              }
            }
            />
          </Col>
          {interpolate && (<Col md={key ? 4 : 6}>
            <ObjectEditor
              key={template}
              data={entity}
              onChange={(editorContent) => {
                console.log("onChange", editorContent);
                //const data = editorContent.text ? JSON.parse(editorContent.text) : editorContent.json;
                // FIXME don't update entity on every edit
                //setEntity(data);
                localStorage[`${key}-entity`] = editorContent.text || JSON.stringify(editorContent.json);
              }}
            />
          </Col>)}
          <Col md={key ? 4 : 6}>
            <h4>Result</h4>
              <div className="result-wrapper result-output">{rendered}</div>
          </Col>
        </Row>
        <Row className="clear-margin">
          <Col md={12}>
            <div className="footer">
              <span className="example-title">Loaded Example:</span>
              <ExampleSelector
                exampleList={samples}
                additionalList={additionalSamples}
                onSelectionChange={parametersChanged}
              />
              <label className="example-title">
                Mark text <input type="text" value={markedText} className="input-element" onChange={(e) => setMarkedText(e.target.value)}/>
              </label>
              <label>
                <button className="btn btn-primary" disabled={entityID.indexOf(":") === -1} onClick={() => fetchEntity(entityID)}>Load by ID</button>
                <input type={"text"} value={entityID} onChange={(e) => {
                  setEntityID(e.target.value);
                  localStorage[`${key}-entity-id`] = e.target.value;
                }} className={"input-element"} />
              </label>
              <CopyToClipboardButton className={"float-right btn btn-primary"} getText={() => template}/>
              <Button className="float-right" onClick={reset}>Reset</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default Sandbox;
