import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import SearchResultFooter from "./SearchResultFooter";
import {flattenEntity} from "../../../markdown/utils";
import Logo from "../../Logo";
import {getBestName, safeDOMID} from "../../../lib/utils";
import {renderMarkdownWithInterpolation} from "../../../markdown/markdown-factory";
import {TEMPLATE_CONSTANTS} from "../../../markdown";
import {SR_Default} from "../../../markdown/search-results/templates";
import MoleculeImage from "../../MoleculeImage";
import {MDSearchResultHeader} from "./SearchResultHeader";
import {MDCollapsibleList, MDCollapsibleText} from "./MDCollapsibleContent";
import {MDExternalLinks} from "./ExternalLinks";

const USE_LOGOS = true;

const GridRow = ({
    item,
    total,
    isQueryTerm,
    onShowEvidence,
    onFilterSelection,
    currentCategory,
    templates,
    markText
  }) => {
  const rowRef = useRef();
  const [firstMarked, setFirstMarked] = useState(null);
  const showEvidence = () => onShowEvidence(item.id);
  const {entity, rank, ordinal} = item;
  const encoding = entity.smiles || entity.inchi;
  const logo = USE_LOGOS && entity.category === 'org';

  useEffect(() => {
    const marked = rowRef.current?.querySelector('mark');
    setFirstMarked(markText ? marked : null);
  });

  return (
      <div ref={rowRef} className="grid-view-row">
        {encoding && (
            <div className="srch-rst-img">
              <MoleculeImage encoding={encoding} ordinal={item.ordinal} popover={true} className="search-result-image" />
            </div>
        )}
        {logo && (
          <Logo title={getBestName(item)} className="search-result-image" />
        )}
        <div className="srch-rst-row" key={item.id}
        >
          {renderMarkdownWithInterpolation(TEMPLATE_CONSTANTS.SEARCH_RESULT,
                                           templates,
                                           {
                            Header: {
                              component: MDSearchResultHeader,
                              props: {
                                rank,
                                ordinal,
                                total,
                                onShowEvidence: showEvidence,
                                showMatch: isQueryTerm,
                              },
                            },
                            Text: {
                              component: MDCollapsibleText,
                            },
                            List: {
                              component: MDCollapsibleList,
                              props: { openIfContains: firstMarked },
                            },
                            Links: {
                              component: MDExternalLinks,
                            },
                            props: {markText},
                          },
                                           entity,
                                           SR_Default)}
          <SearchResultFooter
            item={flattenEntity(item)}
            currentCategory={currentCategory}
            onFilterSelection={onFilterSelection}
          />
        </div>
      </div>
    );
};


GridRow.propTypes = {
  isQueryTerm: PropTypes.bool,
  onEvidenceChainClick: PropTypes.func,
  onFilterSelection: PropTypes.func.isRequired,
  markText: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default GridRow;
