import {base64Helper} from "./utils";
import {getBestName, isTruthy, naturalSort} from "../lib/utils";
import MoleculeImage from "../components/MoleculeImage";
import {MDCollapsibleList, MDCollapsibleText} from "../components/results-grid/grid-row/MDCollapsibleContent";
import LabeledEntity from "../components/LabeledEntity";
import {substituteComponentProp} from "./interpolation";

const REDUNDANT_PREFIXES = [
  "Ensembl:",
  "HGNC:",
];

const STARS = "★★★★★";
const STARS_OUTLINE = "☆☆☆☆☆";


export const applyFilter = (obj, filter = "", filterArg = null,
                            uniqueValues, templateOverrides = {},
                            interpolatedProps = {}, fieldName = null,
                            markText = null, quote = x => x) => {
  const entity = interpolatedProps['entity'];
  // `context` indicates any property values already interpolated for support of the `unique` filter
  if (obj instanceof Array && filter !== "sort" && filter !== "clist" && filter !== "ctext") {
    return obj.map(el => applyFilter(el, filter, filterArg, uniqueValues, templateOverrides, interpolatedProps, fieldName,
                                     markText, quote));
  }
  const NOVALUE = "";
  const svalue = obj.toString();
  if (typeof(filter) != "string") {
    filter = ""
  }
  try {
    // Make filters with or without dashes equivalent
    filter = filter.replaceAll("-", "");
  }
  catch(error) {
    filter = "";
  }
  let size = "";
  switch (filter) {
    case "base64":
      return base64Helper().encode(svalue);
    case "best":
      return getBestName(obj);
    case "brace":
    case "braces":
      return `{${svalue}}`;
    case "bracket":
    case "brackets":
      return `[${svalue}]`;
    case "category":
      return `<span class="category">${quote(svalue)}</span>`;
    case "class":
      if (svalue.startsWith("<")) {
        if (svalue.indexOf("className") !== -1) {
          return svalue.replace(/className=['"]([^'"]*)['"]/, `className="$1 ${filterArg}"`);
        }
        if (svalue.indexOf("/>") !== -1) {
          return svalue.replace("/>", ` className="${filterArg}" />`);
        }
        if (svalue.indexOf(">") !== -1) {
          return svalue.replace(/^([^>]+)>/, `$1 className="${filterArg}">`);
        }
      }
      return `<span class="${filterArg}">${quote(svalue)}</span>`;
    case "clist":
      templateOverrides['MDCollapsibleList'] = {component: MDCollapsibleList};
      const listTitle = filterArg ? ` title="${filterArg}"` : "";
      return `<MDCollapsibleList ${substituteComponentProp("list", obj, interpolatedProps)}${listTitle}/>`;
    case "cpdimage":
    case "molimage":
      templateOverrides['MoleculeImage'] = {component: MoleculeImage};
      const dimensions = filterArg ? ` width="${filterArg}" height="${filterArg}"` : "";
      return `<MoleculeImage encoding="${svalue}"${dimensions}/>`;
    case "ctext":
      templateOverrides['MDCollapsibleText'] = {component: MDCollapsibleText};
      const textTitle = filterArg ? ` title="${filterArg}"` : "";
      return `<MDCollapsibleText ${substituteComponentProp("text", svalue, interpolatedProps)}${textTitle}/>`;
    case "default":
      return svalue !== "" ? svalue : filterArg;
    case "degrees":
      return svalue !== "" ? `${svalue}°` : "";
    case "dumps":
      return JSON.stringify(obj);
    case "fixed":
      const number = parseFloat((svalue || "0").replace(",", "."));
      const digits = parseInt(filterArg, 10) || 2;
      return number.toFixed(digits);
    case "flag":
      const label = isTruthy(svalue) ? filterArg || svalue : NOVALUE;
      return `<div class="srch-rst-desc">${markText ? markText(label, true) : label}</div>`;
    case "entity":
      templateOverrides['Entity'] = {component: LabeledEntity};
      return `<Entity ${substituteComponentProp("entity", obj, interpolatedProps)}/>`;
    case "fold":
      // Convert log2-fold to simple-fold
      return Math.round(Math.pow(2, Math.abs(obj)));
    case "int":
      return svalue.replace(/^(\d+).*/, '$1');
    case "label":
      const conj = svalue[0] === '>' ? "" : "= "
      switch(fieldName) {
        case "ec50":
          return `EC50 ${conj}${svalue}nM`;
        case "ic50":
          return `IC50 ${conj}${svalue}nM`;
        case "kd":
          return `Kd ${conj}${svalue}nM`;
        case "ki":
          return `Ki ${conj}${svalue}nM`;
        case "kh":
          return `KH ${conj}${svalue}`;
        case "kl":
          return `kL ${conj}${svalue}`;
        case "kon":
          return `kon ${conj}${svalue}`;
        case "koff":
          return `koff ${conj}${svalue}`;
        case "ph":
          return `pH ${svalue}`;
        default:
          return svalue;
      }
    case "list":
      return [svalue];
    case "longtext":
      return `<span class="long-text">${quote(svalue)}</span>`;
    case "lower":
      return svalue.toLowerCase();
    case "markterm":
      // User term that matched
      const matchTerm = entity['match-term'];
      // Actual text that matched; may be different from the match term
      const matchText = entity['match-text'];
      // Original full field value which matched
      const matchValue = entity['match-value'];
      // Which field matched
      const matchField = entity['match-field'];
      const matchType = entity['match-type'];
      if (!matchTerm || matchType === "query") {
        return svalue;
      }
      const idx = svalue.indexOf(matchText);
      if (idx !== -1) {
        const end = idx + matchText.length;
        return `${quote(svalue.substring(0, idx))}<mark>${quote(svalue.substring(idx, end))}</mark>${quote(svalue.substring(end))}`;
      }
      if (matchField === "smiles" || matchField === "inchi" || matchField === "inchikey" || matchField === "id") {
        return `${svalue} (via ${matchField})`;
      }
      const marked = matchValue.replace(matchText, `<mark>${matchText}</mark>`);
      const title = entity['title'];
      if (matchField === "aliases") {
        return `${quote(marked)}${title && marked.indexOf(title) === -1 ? ' (' + quote(title) + ')' : ''}`;
      }
      return marked;
    case "nodataset":
      REDUNDANT_PREFIXES.forEach(prefix => {
        if (obj.startsWith(prefix)) {
          obj = obj.substring(prefix.length);
        }
      });
      return quote(obj.replace(/^[-a-z]+:([-a-z]+:)?/, ''));
    case "noprefix":
      const symb = filterArg || ":";
      const split = svalue.split(symb)
      return split[split.length-1];
    case "nosmiles":
      return svalue.startsWith("smiles:") ? NOVALUE : svalue;
    case "patent":
      return svalue.replace(/^([A-Z]+)([0-9]+)(A-Z]+\d*)?/, "$1-$2-$3").replace(/-$/, "");
    case "paren":
    case "parens":
      return `(${svalue})`;
    case "prefix":
      return (filterArg || "") + svalue;
    case "query":
      return svalue.replace(/\|\$.*\$\|/, '|$...$|');
    case "sort":
      return ([...obj] || []).sort(naturalSort);
    case "similarity":
      const n = parseFloat((svalue || "0").replace(",", "."));
      const stype = entity['sim-type'] ? ` (${entity['sim-type']})` : '';
      return `${(n >= 2 ? n - 2 : n).toFixed(2)}${stype}`;
    case "size":
      size = ` width="${filterArg}" height="${filterArg}" `;
      if (svalue.indexOf("/>") !== -1) {
        return svalue.replace("/>", size + "/>");
      }
      return svalue.replace(/^([^>]+)>/, `$1 ${size}>`);
    case "suffix":
      return svalue + (filterArg || "");
    case "starrating":
      const starCount = parseInt(svalue || "1");
      return `${STARS.substring(0, starCount)}${STARS_OUTLINE.substring(0, 5 - starCount)}`;
    case "sentence":
      return svalue && svalue.length ? `${svalue.charAt(0).toUpperCase()}${svalue.slice(1)}.` : "";
    case "title":
      return `<div class="srch-rst-desc"><span class="block-title">${filterArg}</span>${quote(svalue)}</div>`;
    case "tooltip":
      if (svalue.indexOf("/>" !== -1)) {
        return svalue.replace("/>", filterArg);
      }
      if (svalue.indexOf(">") !== -1) {
        return svalue.replace(/^([^>]+)>/, `$1 ${filterArg}>`);
      }
      return `<span title="${filterArg}">${quote(svalue)}</span>`;
    case "truncate":
      const trktFactor = parseInt(filterArg, 10);
      return svalue.length + 3 > filterArg ? `${svalue.substring(0, trktFactor)}...` : svalue;
    case "unique":
      const current = svalue.toLowerCase();
      if (uniqueValues.has(current)) {
        return NOVALUE;
      }
      // Check for prefix matches (partial title matches, usually)
      for (const value of uniqueValues.values()) {
        if (value.toLowerCase().startsWith(current)) {
          return NOVALUE;
        }
      }
      return svalue;
    case "upper":
      return svalue.toUpperCase();
    case "xof":
      return svalue.indexOf("gain") !== -1 ? "GOF" : svalue.indexOf("loss") !== -1 ? "LOF" : svalue;
    case "year":
      try {
        const dateFromObj = new Date(obj);
        if (dateFromObj.toString() !== "Invalid Date") {
          return dateFromObj.getFullYear();
        }
      } catch (err) {
      }
      return NOVALUE;
    default:
      return quote(svalue);
  }
};
