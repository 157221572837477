import {TEMPLATE_CONSTANTS} from "../index";

import {
  CS_AS_Group_Default,
  CS_AS_Group_Pager_Default,
  CS_AS_Item_Default,
  CS_AS_Select_Group_Default,
  CS_AS_Top_Default
} from "./templates";

export const FALLBACKS = {
  [TEMPLATE_CONSTANTS.AUTO_SUGGEST.TOP]: CS_AS_Top_Default,
  [TEMPLATE_CONSTANTS.AUTO_SUGGEST.ITEM]: CS_AS_Item_Default,
  [TEMPLATE_CONSTANTS.AUTO_SUGGEST.GROUP_HEADER]: CS_AS_Group_Default,
  [TEMPLATE_CONSTANTS.AUTO_SUGGEST.GROUP_PAGER]: CS_AS_Group_Pager_Default,
  [TEMPLATE_CONSTANTS.AUTO_SUGGEST.SELECT_GROUP]: CS_AS_Select_Group_Default,
};

