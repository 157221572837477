import React from 'react'
import { validateUsername } from "../validation"
import { ACTION_CHANGE, ACTION_REQUEST } from "./index"
import SpinnerAnimation from '../../../components/Spinner'

const SendVerificationButton = ({username, onRequestAccessLink, asLink = false,
                                again = false, formSubmitted = false}) => (
    <button
        type = { asLink ? "button" : "submit" }
        autoFocus = { username && !again }
        className = { asLink ? "signup-btn send-vcode-btn" : "login-submit" }
        onClick   = { onRequestAccessLink(again) }
        disabled  = { (validateUsername(username) || formSubmitted) ? "disabled" : "" }
        title     = { username !== "" ? "" : "enter username" }
    >
        { again ? "Re-send access link" : "Send access link" }
        <SpinnerAnimation style={{ float: "right", display: formSubmitted ? "" : "none" }}/>
    </button>
);

// Accommodates access link request and password change
const ChangePassword = ({
    action,
    formSubmitted,
    username,
    accessLinkHasBeenSent,
    onRequestAccessLink,
    verificationCode,
    oldPassword,
    newPassword,
    validating,
    newPasswordCheck,
    onChange,
    onFocus,
    onSubmit,
    onCancel,
    errors
}) => (
    <div>
        {
            // request rendered as link
            action === ACTION_CHANGE
            && verificationCode != null
            && (<SendVerificationButton
                     username={username}
                     onRequestAccessLink={onRequestAccessLink}
                     asLink={true}
                     again={accessLinkHasBeenSent}
                     formSubmitted={formSubmitted} />)
        }
        {
            action === ACTION_REQUEST &&
            <p>
                <input
                  className    = "login-control-username"
                  type         = "text"
                  autoComplete = "username"
                  placeholder  = "Username/Email"
                  value        = { username }
                  onChange     = { onChange('username') }
                  onFocus      = { onFocus('username', true) }
                  onBlur       = { onFocus('username', false) }
                  disabled     = { formSubmitted ? "disabled" : ""}
                />
            </p>
        }
        {
            // request rendered as button
            action === ACTION_REQUEST &&
            <p style={{ textAlign: "center" }}>
                {(<SendVerificationButton
                    username={username}
                    onRequestAccessLink={onRequestAccessLink}
                    asLink={false}
                    again={accessLinkHasBeenSent}
                    formSubmitted={formSubmitted} />)}
            </p>
        }
        { action === ACTION_CHANGE &&
            <div>
                <p>
                {
                  <input
                    className={verificationCode != null ? "login-control-username" : "login-control-pass"}
                    type={verificationCode != null ? 'text' : 'password'}
                    autoComplete="off"
                    placeholder={verificationCode != null ? 'Verification Code' : 'Old Password'}
                    value={verificationCode != null ? verificationCode : oldPassword}
                    onChange={onChange(verificationCode != null ? 'verificationCode' : 'oldPassword')}
                    onBlur={onFocus(verificationCode != null ? 'verificationCode' : 'oldPassword', false)}
                    disabled     = { formSubmitted ? "disabled" : ""}
                  />
                }
                </p>
                <p>
                  <input
                    type         = "password"
                    className    = "login-control-pass"
                    autoComplete = "current-password"
                    placeholder  = "New Password"
                    value        = { newPassword }
                    onChange     = { onChange('newPassword') }
                    onBlur       = { onFocus('newPassword', false) }
                    disabled     = { formSubmitted ? "disabled" : ""}
                  />
                </p>
                <SpinnerAnimation style={{ height: "0px", float: "right", position: "relative", top: "-65px", display: validating || "none" }}/>
                <p>
                    <input
                        type         = "password"
                        className    = "login-control-pass"
                        autoComplete = "current-password"
                        placeholder  = "Confirm Password"
                        value        = { newPasswordCheck }
                        onChange     = { onChange('newPasswordCheck') }
                        onBlur       = { onFocus('newPasswordCheck', false) }
                        disabled     = { formSubmitted ? "disabled" : ""}
                    />
                </p>
                <p>
                    <button autoFocus
                        className = "login-submit"
                          onClick = { onSubmit }
                        disabled  = { (errors.submit || formSubmitted) ? "disabled" : "" }
                    >
                        Change Password
                      <SpinnerAnimation style={{ float: "right", display: formSubmitted ? "" : "none" }}/>
                    </button>
                </p>
            </div>
        }
        <p className="signup-block">
            <button
                type        = "button"
                className   = "signup-btn"
                onMouseDown = { onCancel }
                disabled    = { formSubmitted ? "disabled" : ""}
            >
                {action === ACTION_CHANGE ? "Cancel" : "Back to sign-in"}
            </button>
        </p>
    </div>
)

export default ChangePassword;
