import React, {useState, useEffect} from "react";
import { getConfig } from "../lib/configMgr";
import {Ball} from "./Entity";
import compound_default from "../img/compound_default.png";
import {OverlayTrigger, Popover} from "react-bootstrap";
import * as PropTypes from "prop-types";

const { ExternalRESTAPI, MAX_URL_LENGTH } = getConfig();

const substructureImage = (mol, query) => `${ExternalRESTAPI.MoleculeMatch}?mol=${encodeURIComponent(mol)}&query=${encodeURIComponent(query)}`;


const MoleculeImage = ({ encoding, width = null, height = null,
                         alt = null, className = null,
                         popover = false, ordinal = null,
                         entity = {}, title = "", scale = "lg",
                         similarNeighbor = null}) => {
  const baseURL = `${ExternalRESTAPI.ImageServer}/smiles`;
  // TODO: display similarity image
  //const url = similarNeighbor
  //            ? substructureImage(encoding || entity.smiles, similarNeighbor.smiles)
  //            : `${baseURL}/${encodeURIComponent(encoding)}`;
  const url = `${baseURL}/${encodeURIComponent(encoding)}`;
  const method = url.length > MAX_URL_LENGTH ? "POST" : "GET";
  const [rotation, setRotation] = useState(0);

  const [dataFetched, setDataFetched] = useState(method === "GET");

  const dimensions = {};
  if (width) {
    dimensions.width = width;
  }
  if (height) {
    dimensions.height = height;
  }

  useEffect(() => {
    if (dataFetched) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    const _fetchData = async () => {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')
      const params = { method, headers, signal };
      if (method === 'POST') {
        params.body = JSON.stringify(encoding);
      }
      return await fetch(method === "POST" ? baseURL : url, params)
        .then(response => {
          return response.blob();
        }).then(blob => {
          return URL.createObjectURL(blob);
        }).catch(e => {
          if (e.name === "AbortError") {
            console.warn("Image load canceled");
            return null;
          }
          console.warn("Use default image due to ", e);
          return "error-use-default-image";
        });
    };
    _fetchData().then(data => {
      setDataFetched(data != null);
    });
    return () => controller.abort();
  }, [url]);
  const imageElement = (className) => encoding ? (
    <img
      className={`${className ? className + " " : ""}compound-structure-image`}
      src={url || compound_default}
      {...dimensions}
      alt={alt}
      style={{transform: `rotate(${rotation}deg)`}}
      onClick={e => setRotation((rotation + (e.shiftKey ? 5 : 90)) % 360)}
      onDoubleClick={e => setRotation(0)}
      onError={e => (e.target.src = compound_default)}
    />
  ) : (
    <Ball
      title={title || entity.categoryName}
      scale={scale}
      className={`category${className ? " " + className : ""}`}
    />
  );
  if (popover) {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Popover id={`popup-compound-${ordinal}`} className="image-hover">
            {imageElement(`${className}-popover`)}
          </Popover>
        }
        defaultShow={false}>
        <div className="with-hover">
          {imageElement(className)}
        </div>
      </OverlayTrigger>
    );
  }
  return imageElement(className);
};

MoleculeImage.propTypes = {
  encoding: PropTypes.string,
};

export default MoleculeImage;
