import {useEffect, useRef} from "react";

export const useInterval = (callback, delay) => {
  // A Ref is used to avoid clearing the interval unnecessarily,
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
