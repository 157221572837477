import React from "react";

const SpinnerAnimation = ({ style }) => (
  <div className="" style={style}>
    <div className="lds-spin">
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
    </div>
  </div>
);

export default SpinnerAnimation;
