import React from "react";
import {checkOfflineState} from "../../OfflineDetect";

const SearchResultHeader = ({
  rank,
  total,
  globalRank = null,
  ordinal = 0,
  title = "<missing title>",
  showMatch = false,
  markText = null,
  onClick = () => {}
}) => {
  if (title.indexOf("<mark") !== -1) {
    title = title.replace(/<mark[^>]*>/g, '').replace(/<\/mark>/g, '');
  }
  const children = (
    <span className="srch-rst-title">
      <span className={showMatch ? "srch-rst-link-disabled" : ""}
            title={showMatch ? "Query term" : "Click to see supporting data"}
            onClick={showMatch ? () => {} : checkOfflineState(onClick)}>
        {markText ? markText(title) : title}
      </span>{" "}
      {showMatch && (
        <span title="Item is a query term" className="glyphicon glyphicon-ok"/>
      )}
    </span>
  );

  const grank = globalRank ? ` (${globalRank})` : '';
  const hover = `Rank ${ordinal} of ${total}${grank}`
  return (
    <div className="srch-rst-header">
      <span title={hover} className={"srch-rst-header"}>
        {" "}
        {rank}{" "}
      </span>
      {children}
    </div>
  );
};

export const MDSearchResultHeader = ({
                                       rank,
                                       ordinal,
                                       total,
                                       entity = {},
                                       title,
                                       showMatch = false,
                                       markText = null,
                                       onClick = () => {}
                                     }) => {
  const globalRank = entity.global_rank;
  return (
    <SearchResultHeader
      rank={rank}
      total={total}
      globalRank={globalRank}
      ordinal={ordinal}
      title={title}
      showMatch={showMatch}
      markText={markText}
      onClick={onClick}
    />
  );
}


export default SearchResultHeader;
