import React from "react";
import logo from "../../../img/official_logo.png";
import {getMessageObject, Message} from "./MessageFactory";

const FormLayout = ({ message = null, children = []}) => (
  <form
    id="loginForm"
    autoComplete="on"
    action="#"
    onSubmit={e => e.preventDefault()}
  >
    <div className="login-form">
      <div className="login-body">
        <button className={"admin help glyphicon glyphicon-question-sign"}/>
        <div className="login-img-wrp">
          <img src={logo} alt="Plex Research"/>
        </div>
        <div className={"client-logo"}></div>
        <div className="login-fields">
          <Message message={message}/>
          {children}
        </div>
      </div>
    </div>
  </form>
);

export default FormLayout;
