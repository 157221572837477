import {runInAction, makeAutoObservable, toJS} from "mobx";
import AWSCognitoProvider from "../lib/AWSCognitoProvider";
import {
  fetchPasswordValidation,
  registerPasswordChange
} from "../queries/authentication";
import { getConfig } from "../lib/configMgr";
import Cookies from "universal-cookie";
import {RequestBuilder} from "../queries/request";
import {isTruthy, wait} from "../lib/utils";
import * as Sentry from "@sentry/react";
import {ServerOfflineError} from "../queries/errors";
import {useLocation, useNavigate} from "react-router";
import {toast} from "react-toastify";


const { ExternalRESTAPI, env } = getConfig();
const API_KEY = 'custom:api-key'

const retryAfterSignin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  navigate("/signin", {replace: true, state: {from: location}});
}

class UserStore {
  _authProvider = null;
  _cognitoUser = null;
  _cognitoUserSession = null;
  _tokenExpired = true;
  _apiKey = null;
  _restoring = false;
  _ready = false;
  _initializingProvider = false;
  _appStatusStore = null;
  _checkingAuthorization = false;
  _isAuthenticated = null;
  _isAuthorized = null;
  _showAdmin = false;

  constructor(appStatusStore) {
    this._appStatusStore = appStatusStore;
    this._showAdmin = isTruthy(localStorage['plex-show-admin']);
    makeAutoObservable(this);
    this.initProvider()
      .then((session)=>{
        runInAction(() => this.ready = true);
        return session;
      });
  }

  initProvider = async () => {
    if (this._initializingProvider) {
      return this._initializingProvider;
    }
    return this._initializingProvider = this._appStatusStore.fetchMetadata()
      .then(data => {
        this.authProvider = new AWSCognitoProvider(data.region, data.userPoolID, data.clientID);
        runInAction(() => this._initializingProvider = false);
        return this.restoreSession();
      })
      .catch(error => {
        runInAction(() => {
          this.session = false;
          this.ready = false;
        });
        if (error instanceof ServerOfflineError) {
          this._appStatusStore.serverOffline = true;
          console.warn("Server unavailable, retry in 5m");
        }
        return wait(300000).then(this.initProvider);
      });
  };

  signOut = () => {
    if (this.user) {
      this.user.signOut();
    }
    // remove any persistent session
    localStorage.clear();
    sessionStorage.clear();
    this.authProvider = null;
    this.session = null;
    this.user = null;
    this.tokenExpired = true;
    this.apiKey = null;
    Sentry.configureScope(scope => scope.setUser(null));
  };

  signIn = (username, password, rememberMe, navigate) => {
    if (!this.authProvider) {
      return this.initProvider().then(()=> {
        return this.signIn(username, password, rememberMe, navigate);
      });
    }
    return this.authProvider.signIn(username, password, rememberMe, navigate)
      .then(({session: newSession, user: newUser}) => {
        runInAction(() => {
          this.session = newSession;
          this.user = newUser;
          this.apiKey = newUser.attributes[API_KEY];
        });
        Sentry.configureScope(scope => scope.setUser({email: this.userName}));
        return {session: newSession, user: newUser};
      });
  };

  checkAuthorization = async () => {
    if (this.checkingAuthorization) {
      return this.checkingAuthorization;
    }
    if (this.isAuthorized != null) {
      return this.isAuthorized;
    }
    return this.checkingAuthorization = this._appStatusStore.fetchDatasetMetadata(this.authData)
      .then(() => {
        runInAction(() => this.isAuthorized = true);
        return true;
      })
      .catch(error => {
        runInAction(() => this.isAuthorized = false);
        return false;
      })
      .finally(() => {
        runInAction(() => this.checkingAuthorization = false);
      })
  };

  restoreSession = async (refresh = false) => {
    if (this.session && !refresh) {
      return this.session;
    }
    if (!this.authProvider) {
      return this.initProvider();
    }
    if (this.restoring) {
      return wait(500).then(() => this.restoreSession());
    }
    this.restoring = true;
    this.tokenExpired = true;
    return this.authProvider.retrieveUserSession()
      .then(({session, user}) => {
        runInAction(() => {
          this.restoring = false;
          this.session = session;
          this.user = user;
          if (session && user) {
            this.apiKey = user.attributes[API_KEY];
            this.tokenExpired = false;
            Sentry.configureScope(scope => scope.setUser({email: this.userName}))
            //console.debug(`Obtained new auth ${this.jwtToken}`);
          } else {
            this.tokenExpired = true;
          }
        });
        return session;
      })
      .catch((error) => {
        runInAction(() => this.restoring = false);
        // FIXME if "NotAuthorizedException: Refresh Token has expired", redirect to signin
        console.warn(`Session restore failed (${error})`);
        throw error;
      });
  };

  register = (username, password) => {
    return this.authProvider.register(username, password);
  };

  resendConfirmation = username => {
    return this.authProvider.resendConfirmation(username);
  };

  sendVerificationCode = username => {
    return this.authProvider.sendVerificationCode(username);
  };

  changePassword = (username, oldPassword, newPassword, navigate) => {
    return this.authProvider.changePassword(username, oldPassword, newPassword, navigate)
      .then(({session, user}) => {
        runInAction(() => {
          this.session = session;
          this.user = user;
        });
        Sentry.configureScope(scope => scope.setUser({email: this.userName}))
        return registerPasswordChange(this.authData, newPassword)
          .then(() => {
            toast.success("Password updated");
          });
      })
      .catch(error => {
        // e.g. 400: name="LimitExceededException"
        throw error;
      });
    };

  runServerPasswordValidation = (username, password, reset) => {
    return fetchPasswordValidation(username, password, reset);
  };

  confirmNewPassword = (username, verificationCode, newPassword) => {
    return this.authProvider.confirmNewPassword(username, verificationCode, newPassword)
      .catch(error => {
        throw error;
      });
  };

  generateAPIKey = () => {
    const req = new RequestBuilder(ExternalRESTAPI.APIKey);
    return req
      .asPOST()
      .withAuthorization(this.authData)
      .fetch()
      .then(rsp => rsp.json())
      .then(apiKey => {
        runInAction(() => this.apiKey = this.user.attributes[API_KEY] = apiKey);
        return apiKey;
      });
  };

  get ready() {
    return this._ready;
  }

  set ready(r) {
    this._ready = r;
  }

  get checkingAuthorization() {
    return this._checkingAuthorization;
  }

  set checkingAuthorization(checking) {
    this._checkingAuthorization = checking;
  }

  get restoring() {
    return this._restoring;
  }

  set restoring(r) {
    this._restoring = r;
  }

  createAuthData = (session) => ({
      refresh: async () => this.restoreSession(true)
        .then(session => this.createAuthData(session))
        .catch(error => retryAfterSignin()),
      jwtToken: this.jwtToken
    });

  get authData() {
    return this.jwtToken && this.createAuthData(this.session);
  }

  get isAdmin() {
    return this.userName && this.userName.endsWith("@plexresearch.com");
  }

  get showAdmin() {
    return this.isAdmin && this._showAdmin;
  }

  set showAdmin(show) {
    this._showAdmin = localStorage['plex-show-admin'] = show;
  }

  get userName() {
    return this.idToken ? toJS(this.idToken.payload.email) : null;
  }

  get apiKey() {
    return toJS(this._apiKey);
  }

  set apiKey(key) {
    this._apiKey = key;
  }

  get userID() {
    return this.idToken ? toJS(this.idToken.payload.sub) : null;
  }

  get tenantID() {
    return this.idToken ? toJS(this.idToken.payload.tenant_id) : null;
  }

  get jwtToken() {
    return this.idToken ? toJS(this.idToken.jwtToken) : null;
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  set isAuthenticated(auth) {
    this._isAuthenticated = auth;
  }

  get isAuthorized() {
    return this.isAuthenticated && this._isAuthorized;
  }

  set isAuthorized(auth) {
    this._isAuthorized = auth;
  }

  get tokenExpired() {
    return toJS(this._tokenExpired);
  }

  set tokenExpired(expired) {
    this._tokenExpired = expired;
  }

  get session() {
    return toJS(this._cognitoUserSession);
  }

  set session(s) {
    this._cognitoUserSession = s;
    this.isAuthenticated = !!s;
    if (s) {
      this.checkAuthorization().then((auth) => {
        runInAction(() => this.isAuthorized = auth);
      });
    }
    else {
      this.isAuthorized = null;
    }
  }

  get user() {
    return toJS(this._cognitoUser);
  }

  set user(user) {
    this._cognitoUser = user;
  }

  get authProvider() {
    return toJS(this._authProvider);
  }

  set authProvider(p) {
    this._authProvider = p;
  }

  get idToken() {
    return this.session ? toJS(this.session.idToken) : null;
  }

  get expiredAt() {
    return this.idToken ? toJS(this.idToken.payload.exp) : null;
  }

  get isTokenExpired() {
    return toJS(this._tokenExpired);
  }

  get initializing() {
    return this.authProvider === null;
  }
}

export default UserStore;
