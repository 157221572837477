import { getConfig } from "../lib/configMgr";
import { toJSON, RequestBuilder } from "./request";

const { ExternalRESTAPI } = getConfig();

export const fetchPasswordValidation = (username, password, reset) => {
  const req = new RequestBuilder(ExternalRESTAPI.PasswordValidate);

  req
    .asPOST(
      JSON.stringify({
        username,
        password,
        reset
      })
    )
    .withJSONContentType();

  return req
    .fetch()
    .then(toJSON)
    .catch(error => {
      if (error.status === 400) {
        return error.json()
          .then(rsp => {
            throw rsp;
          });
      }
      throw error;
    });
};

export const registerPasswordChange = (auth, password) => {
  const req = new RequestBuilder(ExternalRESTAPI.PasswordChanged);

  req
    .asPOST(JSON.stringify(password))
    .withJSONContentType()
    .withAuthorization(auth);

  return req
    .fetch()
    .then(toJSON);
};
