export const QD_Default = "#### {id}";
export const QD_Target = `
**{label title id} {organism | bracket}**
<Entity title='{title}' className="category" text='{categoryName}'/>
<List list='{aliases}' title='Aliases' />
`;

export const QD_Compound = `
**{label title id} {source_dataset_id | bracket}**
{smiles | cpd-img | size=200px}
<List list='{aliases}' title='Aliases' />`;

export const QD_Ext_Entity = `<Entity title='{title}' className="category" text="{categoryName}"/>`;
export const QD_Ext_CollList = `<List items='{aliases}'/>`;
